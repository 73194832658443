import { useQueryClient } from '@tanstack/react-query';
import { managerTeamAchievementsQueryKeys } from 'entities/managerTeamAchievements/consts';
import { useCreateManagerTeamAchievementMutation } from 'entities/managerTeamAchievements/mutations';
import { TAchievementModalValue } from 'feature/achievement-modal/types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'react-use';
import { notify } from 'shared/components/Notification';
import { toNumber } from 'shared/lib/toNumber';

export const useCreateManagerTeamAchievement = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { managerId = '' } = useParams<{
    managerId: string;
  }>();

  const [isCreateModalOpen, setIsCreateModalOpen] = useBoolean(false);

  const openCreateAchievementModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => setIsCreateModalOpen(false);

  const { mutate: createManagerTeamAchievementMutation, isLoading } =
    useCreateManagerTeamAchievementMutation();

  const createManagerTeamAchievement = ({
    year,
    translations,
  }: TAchievementModalValue) => {
    createManagerTeamAchievementMutation(
      {
        translations,
        year: toNumber(year),
        managerId: toNumber(managerId),
      },
      {
        onError: () =>
          notify(t('content.manager.notifyNotCreateTeamAchievements'), {
            type: 'error',
          }),
        onSuccess: () => {
          closeCreateModal();
          return queryClient.resetQueries([
            managerTeamAchievementsQueryKeys.managerTeamAchievements,
          ]);
        },
      },
    );
  };

  return {
    openCreateAchievementModal,
    createTeamAchievementModalProps: {
      title: t('content.createTeamAchievements'),
      closeModal: closeCreateModal,
      actionSubmit: createManagerTeamAchievement,
      isOpened: isCreateModalOpen,
      isLoading,
    },
  };
};
