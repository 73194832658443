import { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { setApi } from 'shared/api/axios';
import { TAPIName } from 'shared/api/types';
import { isProduction, LocalStorageKeys } from 'shared/constants';

export const useApiSelection = () => {
  const [selectedApi, setSelectedApi] = useLocalStorage<TAPIName>(
    LocalStorageKeys.backendSandbox,
    isProduction ? undefined : 'sandbox4',
    { raw: true },
  );
  useEffect(() => {
    if (selectedApi && !isProduction) {
      setApi(selectedApi);
    }
  }, [selectedApi]);

  return {
    selectedApi,
    setSelectedApi,
  };
};
