import { TEditor } from 'shared/components/Editor/types';
import { createEditorParagraphElement } from 'shared/components/Editor/utils/paragraph/createEditorParagraphElement';
import { TLangType } from 'shared/types/common';
import { Editor, Element, Node, Path, Range, Transforms } from 'slate';

export const withCorrectVoidBehavior = (
  editor: TEditor,
  defaultLocale: TLangType,
) => {
  const { deleteBackward, insertBreak } = editor;

  editor.insertBreak = () => {
    if (!editor.selection || !Range.isCollapsed(editor.selection)) {
      return insertBreak();
    }

    const selectedNodePath = Path.parent(editor.selection.anchor.path);
    const selectedNode = Node.get(editor, selectedNodePath);
    if (Element.isElement(selectedNode) && Editor.isVoid(editor, selectedNode)) {
      Editor.insertNode(editor, createEditorParagraphElement('', defaultLocale));
      return;
    }

    insertBreak();
  };

  editor.deleteBackward = (unit) => {
    if (
      !editor.selection ||
      !Range.isCollapsed(editor.selection) ||
      editor.selection.anchor.offset !== 0
    ) {
      return deleteBackward(unit);
    }

    const parentPath = Path.parent(editor.selection.anchor.path);
    const parentNode = Node.get(editor, parentPath);
    const parentIsEmpty = Node.string(parentNode).length === 0;

    if (parentIsEmpty && Path.hasPrevious(parentPath)) {
      const prevNodePath = Path.previous(parentPath);
      const prevNode = Node.get(editor, prevNodePath);
      if (Element.isElement(prevNode) && Editor.isVoid(editor, prevNode)) {
        return Transforms.removeNodes(editor);
      }
    }

    deleteBackward(unit);
  };

  return editor;
};
