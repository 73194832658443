import { EditorElementTypeEnum } from 'shared/components/Editor/types';
import { TWorkspaceParagraphElement } from 'shared/components/Workspace/types';
import { TLangType } from 'shared/types/common';

export const createWorkspaceParagraphElement = (
  text: string,
  defaultLocale: TLangType,
): TWorkspaceParagraphElement => ({
  type: EditorElementTypeEnum.Paragraph,
  translations: {
    [defaultLocale]: { text },
  },
});
