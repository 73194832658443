import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { TLangType } from 'shared/types/common';

import styles from './PreviewImage.module.css';

type TProps = {
  selectedLocale: TLangType;
  previewImage: string;
  name: string;
  onSettingsClick: () => void;
};

export const PreviewImage = ({
  selectedLocale,
  previewImage,
  name,
  onSettingsClick,
}: TProps) => {
  const { defaultLocale } = useLocales();

  return (
    <div className={styles.previewImageWrapper}>
      <img src={previewImage} />
      <div
        role="button"
        className={cn(styles.settings, {
          [styles.settingsDisabled]: selectedLocale !== defaultLocale,
        })}
        onClick={onSettingsClick}
      >
        <Icon kind="settings-02" size="s" />
      </div>
      {name.length > 0 && (
        <Text className={styles.storyTitle} size={10} weight="SemiBold">
          {name}
        </Text>
      )}
    </div>
  );
};
