import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useEffect } from 'react';
import { TPaginatedResponse } from 'shared/api/pagination';
import { TPaginatedParameters, usePagination } from 'shared/hooks/usePagination';
import { toNumber } from 'shared/lib/toNumber';

export const usePaginationQuery = <
  TQueryFnData extends TPaginatedResponse<unknown>,
  TError,
  TData extends TPaginatedResponse<unknown> = TQueryFnData,
>(
  callback: (
    pagination: Record<string, string>,
  ) => UseQueryOptions<TQueryFnData, TError, TData>,
  defaultPagination?: TPaginatedParameters,
) => {
  const { pagination, changePage } = usePagination(defaultPagination);

  const { data, ...queryResponse } = useQuery(callback(pagination));

  useEffect(() => {
    const isEmptyData = data && data.data.length === 0;
    const isHaveEntities = data && data.total > 0;

    if (isEmptyData && isHaveEntities) {
      changePage(toNumber(pagination['page[number]']) - 1);
    }
  }, [data]);

  return { ...queryResponse, data, changePage };
};
