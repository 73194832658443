import { AuthRedirect } from 'feature/auth/ui/AuthRedirect/AuthRedirect';
import { AuthPage } from 'pages/auth/AuthPage/AuthPage';
import { Navigate, RouteObject } from 'react-router-dom';
import { routes } from 'shared/routes';
import { RootLayout } from 'shared/ui/layouts/root-layout/RootLayout';

export const AuthRoutes: Record<string, RouteObject> = {
  Redirect: {
    path: routes.auth,
    element: <Navigate replace={true} to={routes.root} />,
  },
  Routes: {
    path: '/',
    element: <RootLayout />,
    errorElement: <AuthRedirect />,
    children: [
      { index: true, element: <Navigate replace to={routes.auth} /> },
      { path: routes.auth, element: <AuthPage /> },
    ],
  },
};
