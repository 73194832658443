import { TPhotoGalleryCategory } from 'entities/photoGalleryCategories/types';
import { getPhotoGalleryCategoriesColumnsConfig } from 'pages/settings/categories/PhotoGalleryCategoriesPage/columnsConfig';
import React from 'react';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './PhotoGalleryCategoriesPage.module.css';

type TProps = {
  categories: TPhotoGalleryCategory[];
};

export const PhotoGalleryCategoriesPageTable = ({ categories }: TProps) => {
  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getPhotoGalleryCategoriesColumnsConfig,
  });

  return (
    <CommonListTable
      activeItemId={activeItemId}
      columns={columnsConfig}
      data={categories}
      rowClassName={styles.rowGridTemplate}
    />
  );
};
