import { TAchievementModalValue } from 'feature/achievement-modal/types';
import { useDeletePlayerTeamAchievement } from 'pages/player/team-achievements/PlayerTeamAchievementsPage/hooks/useDeletePlayerTeamAchievement';
import { useEditPlayerTeamAchievement } from 'pages/player/team-achievements/PlayerTeamAchievementsPage/hooks/useEditPlayerTeamAchievement';
import { TPlayerTeamAchievementsActions } from 'pages/player/team-achievements/PlayerTeamAchievementsPage/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import { toNumber } from 'shared/lib/toNumber';
import { toString } from 'shared/lib/toString';
import { TGroupAction } from 'shared/types/common';

export const usePlayerTeamAchievementsActions = ({
  playerTeamAchievement,
  activePlayerTeamAchievementId,
  setActivePlayerTeamAchievementId,
}: TPlayerTeamAchievementsActions) => {
  const { t } = useTranslation();
  const { id, playerId, year, translations } = playerTeamAchievement;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const [isEditModalOpen, setIsEditModalOpen] = useBoolean(false);

  const isActive = id === activePlayerTeamAchievementId;
  const { deletePlayerTeamAchievement, isDeletingPlayerTeamAchievement } =
    useDeletePlayerTeamAchievement();
  const { isEditingPlayerTeamAchievement, editPlayerTeamAchievement } =
    useEditPlayerTeamAchievement();

  const actions: TGroupAction[] = useMemo(
    () => [
      {
        title: t('content.edit'),
        icon: 'edit-02',
        action: () => setIsEditModalOpen(true),
      },
      {
        title: t('content.delete'),
        icon: 'trash',
        action: () => setIsDeleteModalOpen(true),
      },
    ],
    [],
  );

  const handleActionsButtonClick = () => {
    setActivePlayerTeamAchievementId(isActive ? null : id);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setActivePlayerTeamAchievementId(null);
  };

  const handleConfirmDeletion = () => {
    handleCloseDeleteModal();
    deletePlayerTeamAchievement(id);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setActivePlayerTeamAchievementId(null);
  };

  const handleConfirmEditing = ({ translations, year }: TAchievementModalValue) => {
    editPlayerTeamAchievement(
      {
        ...playerTeamAchievement,
        playerId: toNumber(playerId),
        year: toNumber(year),
        translations,
      },
      handleCloseEditModal,
    );
  };

  return {
    actionsProps: {
      actions,
      activeItemId: activePlayerTeamAchievementId,
      isLoading: isDeletingPlayerTeamAchievement,
      isOpen: isActive,
      onIconClick: handleActionsButtonClick,
    },
    deleteModalProps: {
      onClose: handleCloseDeleteModal,
      onConfirm: handleConfirmDeletion,
      isOpened: isDeleteModalOpen,
      description: t('content.deleteWarning'),
      title: t('content.deleteAchievement'),
    },
    editModalProps: {
      initialValue: {
        year: toString(year),
        translations,
      },
      title: t('content.editTeamAchievements'),
      closeModal: handleCloseEditModal,
      actionSubmit: handleConfirmEditing,
      isOpened: isEditModalOpen,
      isLoading: isEditingPlayerTeamAchievement,
    },
  };
};
