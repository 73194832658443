import { prepareCreateNewsPostParagraphsDTO } from 'entities/news/mapper/prepareCreateNewsPostParagraphsDTO';
import { TCreateNewsPostDTO } from 'entities/news/types';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { clearMetaDataFromBase64Url } from 'shared/lib/clearMetaDataFromBase64Url';
import { getFileType } from 'shared/lib/getFileType';
import { toNumber } from 'shared/lib/toNumber';
import { TLangType } from 'shared/types/common';
import { prepareEntitiesIds } from 'shared/utils/entityIds';

export const prepareCreateNewsDTO = (
  data: TEditNewsPostFields,
  defaultLocale: TLangType,
): TCreateNewsPostDTO => {
  const preview = data!.preview;
  const slider = data!.slider;

  const paragraphs = prepareCreateNewsPostParagraphsDTO(data, defaultLocale);

  return {
    availableLocales: data.locale.created,
    importance: toNumber(data.important),
    pictures: {
      baseImage: {
        filename: 'base_image.' + getFileType(preview!.file!.name),
        data: clearMetaDataFromBase64Url(preview!.data),
      },
      sliderImage: data.important
        ? {
            filename: 'slider_image.' + getFileType(slider!.file!.name),
            data: clearMetaDataFromBase64Url(slider!.data),
          }
        : null,
    },
    published: data.publishDate ? data.publishDate.toISOString() : null,
    relationships: {
      categories: prepareEntitiesIds(data.categories),
      labels: prepareEntitiesIds(data.labels),
      tournaments: data.tournament ? [toNumber(data.tournament)] : [],
      seasons: data.season ? [toNumber(data.season)] : [],
      matches: data.match ? [toNumber(data.match)] : [],
      players: prepareEntitiesIds(data.players),
      managers: prepareEntitiesIds(data.managers),
    },
    paragraphs,
  };
};
