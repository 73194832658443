import { TOpponentPlayerModalFields } from 'feature/opponent-player-modal/types';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { Field } from 'shared/components/Form';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { InputDatePicker } from 'shared/components/InputDatePicker/InputDatePicker';
import { TODAY } from 'shared/constants';

import styles from './OpponnentPlayerModal.module.css';

type TProps = {
  control: Control<TOpponentPlayerModalFields>;
};

const FROM_DATE = new Date(1970, 0);

const discardedStatusLabels: Record<string, string> = {
  false: 'В команде',
  true: 'Покинул команду',
};

export const OpponentPlayerModalDiscarded = ({ control }: TProps) => {
  const { field } = useController({
    control,
    name: 'discarded',
  });

  return (
    <>
      <Field label="Принадлежность к команде">
        <div className={styles.status}>
          {Object.keys(discardedStatusLabels).map((discardedStatus) => (
            <RadioButton
              key={discardedStatus}
              checked={field.value === discardedStatus}
              label={discardedStatusLabels[discardedStatus]}
              name={field.name}
              size="s"
              value={discardedStatus}
              onChange={() => {
                field.onChange(discardedStatus);
              }}
            />
          ))}
        </div>
      </Field>
      <InputDatePicker
        control={control}
        disabled={field.value === 'false'}
        fieldName="discardedAt"
        fromDate={FROM_DATE}
        label="Дата увольнения игрока из команды"
        placeholder="Выберите дату"
        required
        toDate={TODAY}
      />
    </>
  );
};
