import cn from 'classnames';
import { format, getYear, setYear } from 'date-fns';
import ru from 'date-fns/locale/ru';
import * as React from 'react';
import { CaptionProps, useNavigation } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { CustomSelect } from 'shared/components/Form/SimpleSelect/CustomSelect';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Text } from 'shared/components/Text/Text';
import { toString } from 'shared/lib/toString';

import styles from './MatchDatePickerCaption.module.css';

type TProps = {
  years: number[];
  maxDate: Date;
};

const yearLabelResolver = (option: number) => toString(option);
const yearValueResolver = (option: number) => option;

export const MatchDatePickerCaption = ({
  displayMonth,
  maxDate,
  years,
}: CaptionProps & TProps) => {
  const { t } = useTranslation();
  const { goToMonth, previousMonth, nextMonth } = useNavigation();
  const year = getYear(displayMonth);

  const goToNextMonth = () => {
    nextMonth && goToMonth(nextMonth);
  };

  const goToPrevMonth = () => {
    previousMonth && goToMonth(previousMonth);
  };

  const onChangeYear = (year: number) => {
    const nextDate = setYear(displayMonth, year);

    if (nextDate > maxDate) {
      goToMonth(maxDate);
      return;
    }

    goToMonth(nextDate);
  };

  return (
    <div>
      <div className={styles.header}>
        <Text size={16} weight="SemiBold">
          {t('content.chooseMatch')}
        </Text>
        <CustomSelect
          controlClassName={styles.selectControl}
          labelResolver={yearLabelResolver}
          options={years}
          placeholder=""
          value={year}
          valueResolver={yearValueResolver}
          onChange={onChangeYear}
        />
      </div>
      <div className={styles.monthChange}>
        <IconButton
          icon="chevron-left"
          className={cn({
            [styles.hideArrow]: !previousMonth,
          })}
          onClick={goToPrevMonth}
        />
        <Text className={styles.month} size={18} weight="SemiBold">
          {format(displayMonth, 'LLLL', { locale: ru })} {year}
        </Text>
        <IconButton
          icon="chevron-right"
          className={cn({
            [styles.hideArrow]: !nextMonth,
          })}
          onClick={goToNextMonth}
        />
      </div>
    </div>
  );
};
