import { TLabel } from 'entities/labels/types';
import { getLabelsColumnsConfig } from 'pages/settings/labels/LabelsPage/columnsConfig';
import styles from 'pages/settings/labels/LabelsPage/LabelsPage.module.css';
import React from 'react';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

type TProps = {
  data: TLabel[];
};

export const LabelsPageTable = ({ data }: TProps) => {
  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getLabelsColumnsConfig,
  });

  return (
    <>
      <CommonListTable
        activeItemId={activeItemId}
        columns={columnsConfig}
        data={data}
        rowClassName={styles.rowGridTemplate}
      />
    </>
  );
};
