import { zodResolver } from '@hookform/resolvers/zod';
import { TSocialNetworkModalValue } from 'feature/social-network-modal/types';
import { SocialNetworkSelect } from 'feature/social-network-modal/ui/SocialNetworkSelect/SocialNetworkSelect';
import { addSocialNetworkValidationSchema } from 'feature/social-network-modal/validationSchema';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { FormInput } from 'shared/components/Form';
import { Text } from 'shared/components/Text/Text';

import styles from './SocialNetworkModal.module.css';

const defaultInitialValue = {} as TSocialNetworkModalValue;

type TProps = {
  title: string;
  closeModal: () => void;
  actionSubmit: (data: TSocialNetworkModalValue) => void;
  isOpened: boolean;
  isLoading: boolean;
  initialValue?: TSocialNetworkModalValue;
};

export const SocialNetworkModal = ({
  title,
  isOpened,
  closeModal,
  isLoading,
  initialValue = defaultInitialValue,
  actionSubmit,
}: TProps) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
  } = useForm({
    defaultValues: initialValue,
    mode: 'onChange',
    resolver: zodResolver(addSocialNetworkValidationSchema),
  });

  const submit = handleSubmit((data) => {
    actionSubmit(data);
  });

  const onCloseModal = () => {
    closeModal();
    reset();
  };

  return (
    <ActionModal
      contentClassName={styles.content}
      opened={isOpened}
      title={title}
      width={542}
      confirmButtonProps={{
        isDisabled: !isValid || !isDirty || isLoading,
        onClick: submit,
        isLoading,
      }}
      onClose={onCloseModal}
    >
      <FormInput
        control={control}
        fieldName="url"
        inputClassName={styles.inputForm}
        label={t('shared.Workspace.addUrl')}
        maxLength={4096}
        placeholder={t('shared.addLink')}
        required
        size="s"
        type="text"
      />
      <Text color="var(--dark-blue-250)" size={12}>
        {t('content.startLinks')}
      </Text>
      <SocialNetworkSelect control={control} fieldName="socialNetworkId" />
    </ActionModal>
  );
};
