import { useState } from 'react';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { AddNewsPostPreviewContent } from 'shared/components/NewsPostForm/components/AddNewsPostPreview/AddNewsPostPreviewContent';
import { OverlayModal } from 'shared/components/OverlayModal/OverlayModal';

import styles from './AddNewsPostPreview.module.css';

export const AddNewsPostPreview = () => {
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);

  const openPreviewModal = () => {
    setIsOpenPreviewModal(true);
  };

  const closePreviewModal = () => {
    setIsOpenPreviewModal(false);
  };

  return (
    <>
      <IconButton className={styles.button} icon="play" onClick={openPreviewModal} />
      <OverlayModal
        innerClassName={styles.modalInner}
        opened={isOpenPreviewModal}
        onClose={closePreviewModal}
      >
        <IconButton
          className={styles.closeButton}
          icon="x"
          iconSize="m"
          onClick={closePreviewModal}
        />
        <AddNewsPostPreviewContent />
      </OverlayModal>
    </>
  );
};
