import { EManagerStatus } from 'entities/managers/model/types';
import i18n from 'i18next';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Field } from 'shared/components/Form';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { TManagerFormFields } from 'widgets/manager-form/types';

import styles from '../ManagerForm/ManagerForm.module.css';

type TProps = {
  control: Control<TManagerFormFields>;
};

const getManagerStatusLabels = () => ({
  [EManagerStatus.PartOfTeam]: i18n.t('content.manager.trainerTeam'),
  [EManagerStatus.Outstaff]: i18n.t('content.manager.invitedTrainer'),
});

export const ManagerFormStatus = ({ control }: TProps) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name="status"
      render={({ field }) => (
        <Field label={t('content.status')} required>
          <div className={styles.status}>
            {Object.values(EManagerStatus).map((status) => (
              <RadioButton
                key={status}
                checked={field.value === status}
                label={getManagerStatusLabels()[status]}
                name={field.name}
                size="s"
                value={status}
                onChange={(n, value) => {
                  field.onChange(value);
                }}
              />
            ))}
          </div>
        </Field>
      )}
    />
  );
};
