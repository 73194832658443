// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputDatePicker-module__placeholderWithValue--_vtUJ {\n  color: var(--gray-500);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/InputDatePicker/InputDatePicker.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB","sourcesContent":[".placeholderWithValue {\n  color: var(--gray-500);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholderWithValue": "InputDatePicker-module__placeholderWithValue--_vtUJ"
};
export default ___CSS_LOADER_EXPORT___;
