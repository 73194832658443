import { TVideoPost } from 'entities/video/types';
import { TVideoFilter } from 'feature/filters/types';
import { getVideoColumnsConfig } from 'pages/video/VideoPage/columnsConfig';
import { TVideoTableSortParams } from 'pages/video/VideoPage/types';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { TSortOptions } from 'widgets/elements-list-table/types';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './VideoTable.module.css';

type TProps = {
  videoPosts: TVideoPost[];
  filter?: TVideoFilter | null;
  sortOptions: TSortOptions<TVideoTableSortParams>;
  onFilter?: (filterOptions: TVideoFilter) => void;
  onSort: (sortOptions: TSortOptions<TVideoTableSortParams>) => void;
};

export const VideoTable = ({
  videoPosts,
  filter,
  sortOptions,
  onFilter,
  onSort,
}: TProps) => {
  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getVideoColumnsConfig,
  });

  return (
    <CommonListTable
      activeItemId={activeItemId}
      columns={columnsConfig}
      data={videoPosts}
      filter={filter}
      rowClassName={styles.rowGridTemplate}
      sortOptions={sortOptions}
      onFilter={onFilter}
      onSort={onSort}
    />
  );
};
