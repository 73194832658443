// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconButton-module__root--zcmCF {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0;\n  border: none;\n  background: none;\n  color: var(--icon-button-default);\n  cursor: pointer;\n  outline: none;\n  transition: color var(--duration-interaction) ease;\n}\n\n  .IconButton-module__root--zcmCF:hover {\n    color: var(--icon-button-hover);\n  }\n\n  .IconButton-module__root--zcmCF:focus {\n    color: var(--icon-button-focus);\n  }\n\n  .IconButton-module__root--zcmCF:disabled {\n    opacity: var(--disabled-opacity);\n    cursor: not-allowed;\n  }\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/IconButton/IconButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,gBAAgB;EAChB,iCAAiC;EACjC,eAAe;EACf,aAAa;EACb,kDAAkD;AAcpD;;EAZE;IACE,+BAA+B;EACjC;;EAEA;IACE,+BAA+B;EACjC;;EAEA;IACE,gCAAgC;IAChC,mBAAmB;EACrB","sourcesContent":[".root {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0;\n  border: none;\n  background: none;\n  color: var(--icon-button-default);\n  cursor: pointer;\n  outline: none;\n  transition: color var(--duration-interaction) ease;\n\n  &:hover {\n    color: var(--icon-button-hover);\n  }\n\n  &:focus {\n    color: var(--icon-button-focus);\n  }\n\n  &:disabled {\n    opacity: var(--disabled-opacity);\n    cursor: not-allowed;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IconButton-module__root--zcmCF"
};
export default ___CSS_LOADER_EXPORT___;
