import { TSessionCredentials } from 'entities/session/model/types';
import { useApiSelection } from 'feature/auth/lib/useApiSelection';
import { useAuthorize } from 'feature/auth/model/useAuthorize';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { API_LIST } from 'shared/api/constants';
import { TAPIName } from 'shared/api/types';
import { Button } from 'shared/components/Button/Button';
import { Form, FormInput } from 'shared/components/Form';
import { CustomSelect as SimpleSelect } from 'shared/components/Form/SimpleSelect/CustomSelect';
import {
  getDefaultLoginRules,
  getDefaultRequiredRules,
} from 'shared/components/Form/validations';
import { Text } from 'shared/components/Text/Text';
import { isProduction } from 'shared/constants';

import styles from './AuthForm.module.css';

const labelResolver = (option: { label: string; value: TAPIName }) => option.label;
const valueResolver = (option: { label: string; value: TAPIName }) => option.value;

export const AuthForm = () => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<TSessionCredentials>({
    reValidateMode: 'onBlur',
  });
  const { selectedApi, setSelectedApi } = useApiSelection();
  const { authorize, isLoading } = useAuthorize();

  const submitForm = (data: TSessionCredentials) => {
    authorize(data);
  };
  const onSubmit = useMemo(() => handleSubmit(submitForm), [submitForm]);

  return (
    <div className={styles.root}>
      <Text className={styles.title} size={24} tag="h1" weight="SemiBold">
        {t('content.welcome', { mark: '!' })}
      </Text>
      <Text className={styles.subtitle} size={18}>
        {t('content.loginTitle')}
      </Text>
      <Form action={onSubmit} className={styles.form}>
        <FormInput
          control={control}
          fieldName="login"
          placeholder={t('content.userName')}
          rules={getDefaultLoginRules()}
        />
        <FormInput
          control={control}
          fieldName="password"
          placeholder={t('content.password')}
          rules={getDefaultRequiredRules()}
          type="password"
        />
        <>
          {!isProduction && (
            <SimpleSelect
              className={styles.apiSelect}
              labelResolver={labelResolver}
              options={API_LIST}
              placeholder="Select API"
              value={selectedApi}
              valueResolver={valueResolver}
              onChange={setSelectedApi}
            />
          )}
        </>
        <Button isDisabled={isLoading} onClick={onSubmit}>
          {t('content.loginButton')}
        </Button>
      </Form>
    </div>
  );
};
