import { useLocales } from 'entities/config/lib/useLocales';
import { EditorElementTypeEnum } from 'shared/components/Editor/types';
import { Text } from 'shared/components/Text/Text';
import { TWorkspaceElement } from 'shared/components/Workspace/types';
import { TLangType } from 'shared/types/common';

import styles from '../AddNewsPostPreview.module.css';

export const AddNewsPostPreviewParagraphElement = ({
  element,
  lang,
}: {
  element: TWorkspaceElement;
  lang: TLangType;
}) => {
  const { defaultLocale } = useLocales();

  if (element.type !== EditorElementTypeEnum.Paragraph) {
    return null;
  }
  const defaultLangText = element.translations?.[defaultLocale]?.text ?? '';
  const text = element.translations?.[lang]?.text ?? defaultLangText;

  return (
    <Text
      className={styles.text}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dangerouslySetInnerHTML={{ __html: text }}
      size={14}
      tag="div"
    />
  );
};
