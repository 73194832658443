import { useFetchManagerCareersQuery } from 'entities/managerCareers/api/queries';
import { ManagerCareerModal } from 'feature/manager-career-modal/ui/ManagerCareerModal/ManagerCareerModal';
import { useCreateManagerCareer } from 'pages/manager/careers/ManagerCareersPage/hooks/useCreateManagerCareer';
import { getManagerCareersColumnsConfig } from 'pages/manager/careers/ManagerCareersPage/ManagerCareersTable/columnsConfig';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './ManagerCareersTable.module.css';

export const ManagerCareersTable = () => {
  const { t } = useTranslation();
  const { managerId = '' } = useParams<{ managerId: string }>();

  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getManagerCareersColumnsConfig,
  });

  const { openCreateModal, createCareerModalProps } = useCreateManagerCareer();

  const {
    data: { data: managerCareers = [], page = 1, total = 0 } = {},
    isLoading,
    error,
    changePage,
  } = useFetchManagerCareersQuery({
    filter: managerId ? { 'filter[manager_id]': managerId } : {},
  });

  return (
    <div className={styles.root}>
      <CommonListWrapper
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.career')}
        createButtonProps={{
          content: t('content.manager.createCareer'),
          onClick: openCreateModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: page,
          totalItems: total,
          onPageChange: changePage,
        }}
      >
        <CommonListTable
          activeItemId={activeItemId}
          columns={columnsConfig}
          data={managerCareers}
          rowClassName={styles.rowGridTemplate}
        />
      </CommonListWrapper>
      <ManagerCareerModal {...createCareerModalProps} />
    </div>
  );
};
