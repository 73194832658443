import cn from 'classnames';
import React from 'react';
import { ECardType, TCardInformation } from 'shared/components/Card/types';
import { Text } from 'shared/components/Text/Text';

import styles from './Information.module.css';

export const TeamInformation = ({ type, entity }: TCardInformation) => {
  if (type !== ECardType.Team) {
    return null;
  }

  const { name } = entity;

  return (
    <div className={cn(styles.wrapper, styles.wrapperTeam)}>
      <Text className={styles.text} size={20} tag="p" weight="Bold">
        {name}
      </Text>
    </div>
  );
};
