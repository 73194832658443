// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BlurredImage-module__root--M3EGo {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n}\n\n.BlurredImage-module__background--m1FcM {\n  width: 100%;\n  height: 100%;\n  pointer-events: none;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n  filter: blur(18px);\n  z-index: 0;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-size: 100% 100%;\n  background-repeat: no-repeat;\n  background-color: lightgray;\n  background-position: center;\n}\n\n.BlurredImage-module__image--qmB4T {\n  -o-object-fit: contain;\n     object-fit: contain;\n  z-index: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/BlurredImage/BlurredImage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,0BAA0B;EAC1B,4BAA4B;EAC5B,2BAA2B;EAC3B,2BAA2B;AAC7B;;AAEA;EACE,sBAAmB;KAAnB,mBAAmB;EACnB,UAAU;AACZ","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n}\n\n.background {\n  width: 100%;\n  height: 100%;\n  pointer-events: none;\n  user-select: none;\n  filter: blur(18px);\n  z-index: 0;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-size: 100% 100%;\n  background-repeat: no-repeat;\n  background-color: lightgray;\n  background-position: center;\n}\n\n.image {\n  object-fit: contain;\n  z-index: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "BlurredImage-module__root--M3EGo",
	"background": "BlurredImage-module__background--m1FcM",
	"image": "BlurredImage-module__image--qmB4T"
};
export default ___CSS_LOADER_EXPORT___;
