import { seasonsApiKeys } from 'entities/seasons/consts';
import { TSeason } from 'entities/seasons/types';
import { get } from 'shared/api/axios';
import { TEntityId } from 'shared/types/common';

export const fetchSeasons = () =>
  get<TSeason[]>(seasonsApiKeys.getSeasons).then((response) => response.data.data);

export const fetchSeason = (id: TEntityId) =>
  get<TSeason>(seasonsApiKeys.getSeason(id)).then((response) => response.data.data);
