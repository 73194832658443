import { AchievementModal } from 'feature/achievement-modal/AchievementModal';
import styles from 'pages/uikit/UIKit/UIKit.module.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { notify } from 'shared/components/Notification';

export const AchievementModalExample = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.root}>
      <Button onClick={() => setOpen(true)}>
        {t('content.player.openTeamAchievement')}
      </Button>
      <AchievementModal
        actionSubmit={(values) => notify(JSON.stringify(values, undefined, 2))}
        closeModal={() => setOpen(false)}
        isLoading={false}
        isOpened={open}
        title={t('content.player.createTeamAchievement')}
      />
    </div>
  );
};
