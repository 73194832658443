// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorPage-module__root--PKas7 {\n  height: 100%;\n  background-color: var(--bg-white);\n  display: flex;\n  flex-direction: column;\n}\n\n.ErrorPage-module__content--SzaTZ {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  justify-content: center;\n  align-items: center;\n}\n\n.ErrorPage-module__errorStatus--VtXPO {\n  font-size: 180px;\n  line-height: 210px;\n  color: var(--dark-blue-350);\n}\n\n.ErrorPage-module__errorTitle--Q0SY5 {\n  font-size: 44px;\n  line-height: 52px;\n}\n\n.ErrorPage-module__refreshButton--qIrVR {\n  margin-top: 24px;\n  width: unset;\n  padding-left: 40px;\n  padding-right: 40px;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/error-page/ErrorPage.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iCAAiC;EACjC,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".root {\n  height: 100%;\n  background-color: var(--bg-white);\n  display: flex;\n  flex-direction: column;\n}\n\n.content {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  justify-content: center;\n  align-items: center;\n}\n\n.errorStatus {\n  font-size: 180px;\n  line-height: 210px;\n  color: var(--dark-blue-350);\n}\n\n.errorTitle {\n  font-size: 44px;\n  line-height: 52px;\n}\n\n.refreshButton {\n  margin-top: 24px;\n  width: unset;\n  padding-left: 40px;\n  padding-right: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ErrorPage-module__root--PKas7",
	"content": "ErrorPage-module__content--SzaTZ",
	"errorStatus": "ErrorPage-module__errorStatus--VtXPO",
	"errorTitle": "ErrorPage-module__errorTitle--Q0SY5",
	"refreshButton": "ErrorPage-module__refreshButton--qIrVR"
};
export default ___CSS_LOADER_EXPORT___;
