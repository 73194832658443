// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dropdown-module__root--rdKDk {\n  height: auto;\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  background-color: var(--bg-white);\n  border: 1px solid var(--gray-200);\n  border-radius: var(--border-radius-default);\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n  outline: none;\n  z-index: var(--popup-index);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Dropdown/Dropdown.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,iCAAiC;EACjC,iCAAiC;EACjC,2CAA2C;EAC3C,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,aAAa;EACb,2BAA2B;AAC7B","sourcesContent":[".root {\n  height: auto;\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  background-color: var(--bg-white);\n  border: 1px solid var(--gray-200);\n  border-radius: var(--border-radius-default);\n  user-select: none;\n  outline: none;\n  z-index: var(--popup-index);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Dropdown-module__root--rdKDk"
};
export default ___CSS_LOADER_EXPORT___;
