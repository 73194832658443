// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditorVideoElement-module__videoWrapper--cjFh0 {\n  position: relative;\n  aspect-ratio: 18 / 9;\n}\n\n.EditorVideoElement-module__buttonWrapper--HrpcV {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.EditorVideoElement-module__button--YtD7I {\n  padding: 8px;\n  background-color: var(--gray-50);\n  border-radius: var(--border-radius-max);\n  color: var(--gray-450);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Editor/elements/EditorVideoElement/EditorVideoElement.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,gCAAgC;EAChC,uCAAuC;EACvC,sBAAsB;AACxB","sourcesContent":[".videoWrapper {\n  position: relative;\n  aspect-ratio: 18 / 9;\n}\n\n.buttonWrapper {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.button {\n  padding: 8px;\n  background-color: var(--gray-50);\n  border-radius: var(--border-radius-max);\n  color: var(--gray-450);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoWrapper": "EditorVideoElement-module__videoWrapper--cjFh0",
	"buttonWrapper": "EditorVideoElement-module__buttonWrapper--HrpcV",
	"button": "EditorVideoElement-module__button--YtD7I"
};
export default ___CSS_LOADER_EXPORT___;
