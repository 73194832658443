import { Control, useController } from 'react-hook-form';
import { TVideoPostFields } from 'widgets/video-form/types';

export const useGetCreatedLocales = (control: Control<TVideoPostFields>) => {
  const {
    field: { value: createdLocales, onChange: changeCreatedLocales },
  } = useController({ control, name: 'locale.created' });

  return { createdLocales, changeCreatedLocales };
};
