import { sessionApiKeys } from 'entities/session/model/consts';
import { TSessionCredentials, TSessionResponse } from 'entities/session/model/types';
import { del, post } from 'shared/api/axios';

export const startSession = (credentials: TSessionCredentials) =>
  post<TSessionResponse>(sessionApiKeys.auth, {
    auth: credentials,
  }).then((response) => response.data);

export const endSession = () =>
  del(sessionApiKeys.auth).then((response) => response.data);
