import { FloatingPortal } from '@floating-ui/react';
import cn from 'classnames';
import { DROPDOWN_OFFSET } from 'shared/components/constants';
import { Dropdown } from 'shared/components/Dropdown/Dropdown';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Loader } from 'shared/components/Loader/Loader';
import { useFloat } from 'shared/hooks/useFloat';
import { TWithChildren } from 'shared/types/common';

import styles from './Actions.module.css';

export type TActionsWrapperProps = {
  isOpen: boolean;
  isLoading?: boolean;
  onIconClick: () => void;
  className?: string;
} & TWithChildren;

export const ActionsWrapper = ({
  isOpen,
  children,
  isLoading,
  onIconClick,
  className,
}: TActionsWrapperProps) => {
  const {
    trigger: { triggerRef, ...trigger },
    floating,
  } = useFloat({
    isOpen,
    onChange: onIconClick,
    offset: DROPDOWN_OFFSET,
    placement: 'bottom-end',
  });

  if (isLoading) {
    return <Loader size="xs" />;
  }

  return (
    <>
      <IconButton
        className={cn({ [styles.active]: isOpen })}
        icon="dots-vertical"
        iconSize="s"
        ref={triggerRef}
        {...trigger}
      />
      {isOpen && (
        <FloatingPortal>
          <Dropdown {...floating} className={className}>
            {children}
          </Dropdown>
        </FloatingPortal>
      )}
    </>
  );
};
