import { teamsApiKeys } from 'entities/teams/consts';
import { TCreateTeamDTO, TEditTeamDTO, TTeam } from 'entities/teams/types';
import { del, get, patch, post } from 'shared/api/axios';
import { mapPaginatedResponse } from 'shared/api/pagination';
import { TFetchParams } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchTeams = ({ params }: TFetchParams) =>
  get<TTeam[], true>(teamsApiKeys.getTeams, {
    params,
  }).then((response) => mapPaginatedResponse(response.data));

export const fetchTeam = (id: TEntityId) =>
  get<TTeam>(teamsApiKeys.getTeam(id)).then((response) => response.data.data);

export const createTeam = (teamDTO: TCreateTeamDTO) =>
  post(teamsApiKeys.createTeam, teamDTO);

export const editTeam = ({ teamId, ...teamDTO }: TEditTeamDTO) =>
  patch(teamsApiKeys.editTeam(teamId), teamDTO);

export const deleteTeam = (teamId: TEntityId) =>
  del(teamsApiKeys.deleteTeam(teamId));
