import { useRightPanelImportance } from 'feature/right-panel/hooks/useRightPanelImportance';
import { TDefaultRightPanelProps } from 'feature/right-panel/types';
import { CommonImportance } from 'feature/right-panel/ui/CommonImportance/CommonImportance';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getImageSettings } from 'shared/constants';
import { TVideoPostFields } from 'widgets/video-form/types';

export const VideoImportance = (commonProps: TDefaultRightPanelProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TVideoPostFields>();
  const { field: sliderImage, fieldState } = useController({
    control,
    name: 'slider.file',
  });
  const { field: sliderImageData } = useController({
    control,
    name: 'slider.data',
  });

  const { field: important } = useController({
    control,
    name: 'important',
  });

  useRightPanelImportance({
    sliderImage: sliderImage.value,
    onChange: sliderImageData.onChange,
  });

  return (
    <CommonImportance
      aspectRatio={getImageSettings().video.importance.aspectRatio}
      control={control}
      description={t('content.video.videoImportance.description')}
      fieldState={fieldState}
      sliderImage={sliderImage.value}
      title={t('content.video.videoImportance.title')}
      cover={{
        imageUrl: sliderImageData.value,
        fieldName: 'slider.file',
        important: important.value,
        tip: t('content.video.videoImportance.tip'),
      }}
      switchField={{
        fieldName: 'important',
        description: t('content.video.videoImportance.switcherDescription'),
      }}
      onChange={sliderImageData.onChange}
      {...commonProps}
    />
  );
};
