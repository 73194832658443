import React from 'react';
import { Actions } from 'shared/components/Actions/Actions';
import styles from 'shared/components/Actions/Actions.module.css';
import { TEntityId, TGroupAction } from 'shared/types/common';

type TProps = {
  id: TEntityId;
  activeItemId: TEntityId | null;
  setActiveItemId: (id: TEntityId | null) => void;
  actions: TGroupAction[];
  isLoading?: boolean;
};

export const CategoriesActions = ({
  id,
  activeItemId,
  setActiveItemId,
  actions,
  isLoading,
}: TProps) => {
  const isActive = id === activeItemId;

  const handleClick = () => {
    setActiveItemId(isActive ? null : id);
  };

  return (
    <Actions
      actions={actions}
      activeItemId={activeItemId}
      className={styles.dropdown}
      isLoading={isLoading}
      isOpen={isActive}
      onIconClick={handleClick}
    />
  );
};
