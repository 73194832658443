// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Editor-module__editor--qYB1P {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n  .Editor-module__editor--qYB1P:hover,\n  .Editor-module__editor--qYB1P:focus {\n    outline: none;\n  }\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Editor/Editor.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AAMV;;EAJE;;IAEE,aAAa;EACf","sourcesContent":[".editor {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n\n  &:hover,\n  &:focus {\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": "Editor-module__editor--qYB1P"
};
export default ___CSS_LOADER_EXPORT___;
