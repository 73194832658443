// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WorkspacePanelHeadingSelect-module__headingTwoOption--SaW_T {\n  font-size: 16px;\n  line-height: 24px;\n}\n\n.WorkspacePanelHeadingSelect-module__select--WdvPO {\n  width: 120px;\n  padding: 7px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Workspace/components/WorkspacePanel/WorkspacePanelHeadingSelect/WorkspacePanelHeadingSelect.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd","sourcesContent":[".headingTwoOption {\n  font-size: 16px;\n  line-height: 24px;\n}\n\n.select {\n  width: 120px;\n  padding: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headingTwoOption": "WorkspacePanelHeadingSelect-module__headingTwoOption--SaW_T",
	"select": "WorkspacePanelHeadingSelect-module__select--WdvPO"
};
export default ___CSS_LOADER_EXPORT___;
