import { useCreatePlayerTeamAchievementMutation } from 'entities/playerTeamAchievements/mutations';
import { TAchievementModalValue } from 'feature/achievement-modal/types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'react-use';
import { notify } from 'shared/components/Notification';
import { toNumber } from 'shared/lib/toNumber';

export const useCreatePlayerTeamAchievement = () => {
  const { t } = useTranslation();
  const { playerId = '' } = useParams<{
    teamId: string;
    playerId: string;
  }>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useBoolean(false);

  const openCreateAchievementModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => setIsCreateModalOpen(false);

  const { mutate: createPlayerTeamAchievementMutation, isLoading } =
    useCreatePlayerTeamAchievementMutation();

  const createPlayerTeamAchievement = ({
    year,
    translations,
  }: TAchievementModalValue) => {
    createPlayerTeamAchievementMutation(
      {
        translations,
        year: toNumber(year),
        playerId: toNumber(playerId),
      },
      {
        onError: () =>
          notify(t('content.player.notifyNotCreateTeamAchievements'), {
            type: 'error',
          }),
        onSuccess: closeCreateModal,
      },
    );
  };

  return {
    openCreateAchievementModal,
    createTeamAchievementModalProps: {
      title: t('content.createTeamAchievements'),
      closeModal: closeCreateModal,
      actionSubmit: createPlayerTeamAchievement,
      isOpened: isCreateModalOpen,
      isLoading,
    },
  };
};
