import cn from 'classnames';
import { Identifier } from 'dnd-core';
import { forwardRef, MouseEventHandler } from 'react';
import { BlurredImage } from 'shared/components/BlurredImage/BlurredImage';
import { Icon } from 'shared/components/Icon/Icon';
import { getImageSettings } from 'shared/constants';

import styles from './Story.module.css';

type TProps = {
  handlerId: Identifier | null;
  index: number;
  hasErrors: boolean;
  isSelected: boolean;
  isDragging: boolean;
  disabled: boolean;
  onClick: MouseEventHandler;
  image: string;
  moveStory: (dragIndex: number, hoverIndex: number) => void;
};

export const Story = forwardRef<HTMLDivElement, TProps>(
  (
    {
      handlerId,
      disabled,
      hasErrors,
      isSelected,
      isDragging,
      onClick,
      image,
      index,
    },
    forwardedRef,
  ) => {
    const dragZoneDisabled = disabled || hasErrors;

    const dragZoneClassName = cn(styles.dragZone, {
      [styles.disabled]: dragZoneDisabled,
      [styles.isSelected]: isSelected,
      [styles.isDragging]: isDragging,
    });
    const indexClassName = cn(styles.index, { [styles.isSelected]: isSelected });
    const innerClassName = cn(styles.inner, { [styles.imageSelected]: isSelected });
    const wrapperClassName = cn(styles.imageWrapper, {
      [styles.isDragging]: isDragging,
      [styles.disabled]: hasErrors,
    });

    return (
      <div
        className={wrapperClassName}
        style={{
          aspectRatio: getImageSettings().stories.background.aspectRatio,
        }}
        onClick={onClick}
      >
        <BlurredImage alt="Story image" imageUrl={image} />
        <div
          aria-disabled={dragZoneDisabled}
          className={dragZoneClassName}
          data-handlerid={dragZoneDisabled ? undefined : handlerId}
          ref={forwardedRef}
        >
          <Icon kind="dots-grag" size="s" />
        </div>
        <div className={indexClassName}>{index + 1}</div>
        <div className={innerClassName} />
      </div>
    );
  },
);
