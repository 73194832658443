import { TPlayerCareer } from 'entities/playerCareers/types';
import { getPlayerCareersColumnsConfig } from 'pages/player/careers/PlayerCareersPage/columnsConfig';
import React from 'react';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './PlayerCareersTable.module.css';

type TProps = {
  careers: TPlayerCareer[];
};

export const PlayerCareersTable = ({ careers }: TProps) => {
  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getPlayerCareersColumnsConfig,
  });

  return (
    <>
      <CommonListTable
        activeItemId={activeItemId}
        columns={columnsConfig}
        data={careers}
        rowClassName={styles.rowGridTemplate}
      />
    </>
  );
};
