// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WorkspaceTitle-module__input--USorN {\n  background-color: transparent;\n  border: none;\n  width: 100%;\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 600;\n  resize: none;\n  padding-right: 50px;\n}\n\n  .WorkspaceTitle-module__input--USorN:focus {\n    outline: none;\n  }\n\n.WorkspaceTitle-module__input--USorN::-moz-placeholder {\n  color: var(--dark-blue-250);\n  opacity: 1;\n}\n\n.WorkspaceTitle-module__input--USorN::placeholder {\n  color: var(--dark-blue-250);\n  opacity: 1;\n}\n\n.WorkspaceTitle-module__field--vDm3i {\n  position: relative;\n}\n\n.WorkspaceTitle-module__counter--_MFxO {\n  position: absolute;\n  right: 0;\n  top: 25px;\n  color: var(--gray-250);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Workspace/components/WorkspaceTitle/WorkspaceTitle.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;AAKrB;;EAHE;IACE,aAAa;EACf;;AAGF;EACE,2BAA2B;EAC3B,UAAU;AACZ;;AAHA;EACE,2BAA2B;EAC3B,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,sBAAsB;AACxB","sourcesContent":[".input {\n  background-color: transparent;\n  border: none;\n  width: 100%;\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 600;\n  resize: none;\n  padding-right: 50px;\n\n  &:focus {\n    outline: none;\n  }\n}\n\n.input::placeholder {\n  color: var(--dark-blue-250);\n  opacity: 1;\n}\n\n.field {\n  position: relative;\n}\n\n.counter {\n  position: absolute;\n  right: 0;\n  top: 25px;\n  color: var(--gray-250);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "WorkspaceTitle-module__input--USorN",
	"field": "WorkspaceTitle-module__field--vDm3i",
	"counter": "WorkspaceTitle-module__counter--_MFxO"
};
export default ___CSS_LOADER_EXPORT___;
