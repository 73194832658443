import { ManagerCareerModal } from 'feature/manager-career-modal/ui/ManagerCareerModal/ManagerCareerModal';
import { useManagerCareersActions } from 'pages/manager/careers/ManagerCareersPage/hooks/useManagerCareersActions';
import { TManagerCareersActions } from 'pages/manager/careers/ManagerCareersPage/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';

import styles from './ManagerCareersActions.module.css';

export const ManagerCareersActions = (props: TManagerCareersActions) => {
  const { t } = useTranslation();
  const {
    actions,
    isActive,
    handleActionsButtonClick,
    isDeletingManagerCareer,
    isDeleteModalOpen,
    handleConfirmDeleteCareer,
    handleCloseDeleteModal,
    editCareerModalProps,
  } = useManagerCareersActions(props);

  return (
    <>
      <Actions
        actions={actions}
        activeItemId={props.activeCareerId}
        className={styles.dropdown}
        isLoading={isDeletingManagerCareer}
        isOpen={isActive}
        onIconClick={handleActionsButtonClick}
      />
      <ConfirmationModal
        description={t('content.deleteWarning')}
        isOpened={isDeleteModalOpen}
        title={t('content.deleteCareer')}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteCareer}
      />
      <ManagerCareerModal {...editCareerModalProps} />
    </>
  );
};
