import cn from 'classnames';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { Field } from 'shared/components/Form';
import { DatePickerBaseSelect } from 'shared/components/InputDatePicker/components/DatePickerBaseSelect';
import { toBoolean } from 'shared/lib/toBoolean';

import styles from './InputDatePicker.module.css';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  label: string;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  fromDate?: Date;
  toDate?: Date;
  inputClassName?: string;
};

export const InputDatePicker = <T extends FieldValues>({
  control,
  fieldName,
  label,
  placeholder,
  fromDate,
  toDate,
  required = false,
  inputClassName,
  disabled = false,
}: TProps<T>) => {
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const { field, fieldState } = useController({
    control,
    name: fieldName,
  });
  const [isSpaceData, setSpaceData] = useState(true);

  const generatePlaceholder = useMemo(() => {
    if (field.value) {
      setSpaceData(false);
      return format(field.value, 'dd.MM.yyyy');
    }

    setSpaceData(true);
    return placeholder;
  }, [field.value, placeholder]);

  const onDateChange = (newDate: Date) => {
    setIsOpenDatePicker(false);
    field.onChange(newDate);
  };

  return (
    <>
      <Field
        disabled={disabled}
        error={fieldState.error}
        label={label}
        required={required}
      >
        <DatePickerBaseSelect
          icon="calendar"
          inputClassName={inputClassName}
          isSpaceData={isSpaceData}
          placeholder={generatePlaceholder}
          type="datepicker"
          placeholderClassName={cn({
            [styles.placeholderWithValue]: toBoolean(field.value),
          })}
          onChange={field.onChange}
          onClick={() => setIsOpenDatePicker(true)}
        />
      </Field>
      <DatePicker
        date={field.value}
        fromDate={fromDate}
        isOpened={isOpenDatePicker}
        isShowTimePicker={false}
        toDate={toDate}
        onClose={() => setIsOpenDatePicker(false)}
        onDateChange={onDateChange}
      />
    </>
  );
};
