import { FloatingPortal } from '@floating-ui/react';
import cn from 'classnames';
import { PREVIEW_SIZES } from 'pages/stories/edit/EditStory/constants';
import { useStoryEditorDispatchContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorDispatchContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { Button } from 'shared/components/Button/Button';
import { Dropdown } from 'shared/components/Dropdown/Dropdown';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { Icon } from 'shared/components/Icon/Icon';
import { useFloat } from 'shared/hooks/useFloat';
import { toNumber } from 'shared/lib/toNumber';
import { toString } from 'shared/lib/toString';

import styles from './PreviewSelect.module.css';

export const PreviewSelect = () => {
  const { t } = useTranslation();
  const [previewSizeId, setPreviewSizeId] = useState(1);
  const [isOpen, toggleOpened] = useToggle(false);

  const {
    trigger: { triggerRef, ...trigger },
    floating,
  } = useFloat({
    isOpen,
    offset: 8,
    placement: 'bottom',
    onChange: toggleOpened,
  });

  const dispatch = useStoryEditorDispatchContext();

  const showPreview = () => {
    const previewSize = PREVIEW_SIZES.find((size) => size.id === previewSizeId);
    if (previewSize) {
      dispatch({
        type: 'selectPreviewSize',
        payload: { width: previewSize.width, height: previewSize.height },
      });
      toggleOpened(false);
    }
  };

  const handleSelect = (_name: string, value: string | number) => {
    setPreviewSizeId(toNumber(value));
  };

  return (
    <>
      <div
        className={cn(styles.control, { [styles.active]: isOpen })}
        ref={triggerRef}
        {...trigger}
      >
        <Icon kind="play" size="m" />
        <Icon kind={isOpen ? 'chevron-up' : 'chevron-down'} size="s" />
      </div>
      {isOpen && (
        <FloatingPortal>
          <Dropdown className={styles.dropdown} {...floating}>
            {PREVIEW_SIZES.map((size, index) => (
              <div key={index} className={styles.option}>
                <RadioButton
                  checked={size.id === previewSizeId}
                  label={size.label}
                  name="previewSize"
                  value={toString(size.id)}
                  onChange={handleSelect}
                />
                <div>{`${size.width}x${size.height}`}</div>
              </div>
            ))}
            <Button className={styles.button} onClick={showPreview}>
              {t('shared.confirm')}
            </Button>
          </Dropdown>
        </FloatingPortal>
      )}
    </>
  );
};
