import { useTeam } from 'pages/team/hooks/useTeam';
import { SwitcherTeams } from 'pages/team/switcher/SwitcherTeams';
import { ManagerTab } from 'pages/team/team-page/TeamPage/ManagerTab';
import { PlayerTab } from 'pages/team/team-page/TeamPage/PlayerTab';
import { ETeamTabType, TTeamTab } from 'pages/team/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import image03 from 'shared/assets/icons/image-03.svg';
import { Button } from 'shared/components/Button/Button';
import { Centered } from 'shared/components/Centered/Centered';
import { IconFactory } from 'shared/components/Icon/IconFactory';
import { Loader } from 'shared/components/Loader/Loader';
import { Text } from 'shared/components/Text/Text';
import { routes } from 'shared/routes';

import styles from './TeamPage.module.css';

const ImageNotFoundIcon = IconFactory(image03);

export const TeamPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { teamId } = useParams<{
    teamId: string;
  }>();

  const [selectedTab, setSelectedTab] = useState<ETeamTabType>(ETeamTabType.Player);

  const tabs: TTeamTab[] = [
    {
      text: t('content.teams.mainCast'),
      onClick: () => setSelectedTab(ETeamTabType.Player),
      tabName: ETeamTabType.Player,
    },
    {
      text: t('content.teams.coachingStaff'),
      onClick: () => setSelectedTab(ETeamTabType.Manager),
      tabName: ETeamTabType.Manager,
    },
  ];

  const { data: team, isLoading } = useTeam(teamId);

  if (isLoading) {
    return (
      <div className={styles.root}>
        <Centered>
          <Loader showLabel />
        </Centered>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <div className={styles.imageWrapper}>
            {team?.picture?.x1 ? (
              <img
                alt="Story image"
                className={styles.image}
                loading="lazy"
                src={team?.picture?.x1}
              />
            ) : (
              <ImageNotFoundIcon className={styles.image} />
            )}
          </div>
          <Text size={24} tag="h1" weight="Bold">
            {t('content.teams.mainCast')}
          </Text>
        </div>
        <SwitcherTeams selected={selectedTab} tabs={tabs} />
        <Button className={styles.button} onClick={() => navigate(routes.teams)}>
          {t('shared.confirm')}
        </Button>
      </div>

      {selectedTab === ETeamTabType.Player ? <PlayerTab /> : <ManagerTab />}
    </div>
  );
};
