import { TVideoActionsListModal } from 'entities/video/video-actions/types';
import { useCallback, useState } from 'react';

export const useActiveConfirmationModal = () => {
  const [activeConfirmationModal, setActiveConfirmationModal] =
    useState<TVideoActionsListModal | null>(null);

  const closeConfirmationModal = useCallback(() => {
    setActiveConfirmationModal(null);
  }, []);

  return {
    activeConfirmationModal,
    setActiveConfirmationModal,
    closeConfirmationModal,
  };
};
