import { useRightPanel } from 'feature/right-panel/hooks/useRightPanel';
import { RightPanel } from 'feature/right-panel/ui/RightPanel';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { toBoolean } from 'shared/lib/toBoolean';
import { TVideoPostFields } from 'widgets/video-form/types';
import { VideoConnections } from 'widgets/video-form/ui/VideoRightPanel/VideoConnections/VideoConnections';
import { VideoImportance } from 'widgets/video-form/ui/VideoRightPanel/VideoImportance/VideoImportance';
import { VideoLabel } from 'widgets/video-form/ui/VideoRightPanel/VideoLabel/VideoLabel';
import { VideoPreview } from 'widgets/video-form/ui/VideoRightPanel/VideoPreview/VideoPreview';
import { VideoReview } from 'widgets/video-form/ui/VideoRightPanel/VideoReview/VideoReview';

export const VideoRightPanel = () => {
  const { control, trigger } = useFormContext<TVideoPostFields>();
  const { field } = useController({
    control,
    name: 'preview.file',
  });

  const { Preview, Label, Connections, Review, Importance } = useRightPanel({
    hasPreview: toBoolean(field.value),
    trigger,
    preview: {
      Component: VideoPreview,
      fieldName: 'preview',
    },
    label: {
      Component: VideoLabel,
    },
    connections: {
      Component: VideoConnections,
    },
    review: {
      Component: VideoReview,
    },
    importance: {
      Component: VideoImportance,
    },
  });

  return (
    <RightPanel components={[Preview, Label, Connections, Review, Importance]} />
  );
};
