import { useDeleteManagerCareer } from 'pages/manager/careers/ManagerCareersPage/hooks/useDeleteManagerCareer';
import { useEditManagerCareer } from 'pages/manager/careers/ManagerCareersPage/hooks/useEditManagerCareer';
import { TManagerCareersActions } from 'pages/manager/careers/ManagerCareersPage/types';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import { TGroupAction } from 'shared/types/common';

export const useManagerCareersActions = ({
  activeCareerId,
  setActiveCareerId,
  career,
}: TManagerCareersActions) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const { isDeletingManagerCareer, deleteManagerCareer } = useDeleteManagerCareer();
  const isActive = career.id === activeCareerId;
  const { openEditModal, editCareerModalProps } = useEditManagerCareer(career);

  const actions: TGroupAction[] = [
    {
      title: t('content.edit'),
      icon: 'edit-02',
      action: openEditModal,
    },
    {
      title: t('content.delete'),
      icon: 'trash',
      action: () => {
        setIsDeleteModalOpen(true);
      },
    },
  ];

  const handleActionsButtonClick = () => {
    setActiveCareerId(isActive ? null : career.id);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setActiveCareerId(null);
  };

  const handleConfirmDeleteCareer = () => {
    handleCloseDeleteModal();
    deleteManagerCareer(career.id);
  };

  return {
    isDeletingManagerCareer,
    isDeleteModalOpen,
    actions,
    isActive,
    editCareerModalProps,
    handleActionsButtonClick,
    handleConfirmDeleteCareer,
    handleCloseDeleteModal,
  };
};
