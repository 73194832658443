import { getSuccessStoryPublicationNotify } from 'entities/stories/helpers';
import { useUpdateStoryGroupMutation } from 'entities/stories/mutations';
import isEmpty from 'lodash/isEmpty';
import { PreviewSelect } from 'pages/stories/edit/EditStory/components/PreviewSelect';
import { SaveStoryModal } from 'pages/stories/edit/EditStory/components/SaveStoryModal/SaveStoryModal';
import { StoryHeaderLocalesControl } from 'pages/stories/edit/EditStory/components/StoryHeaderPanel/components/StoryHeaderLocalesControl';
import { ModalContext } from 'pages/stories/edit/EditStory/context';
import { useStoryEditorContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorContext';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/components/Button/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { routes } from 'shared/routes';
import { TLangType } from 'shared/types/common';

import styles from './StoryHeaderPanel.module.css';

export type TFormValues = {
  publicationTime?: Date;
};

type TProps = {
  selectedStoryGroupId: string;
  selectedLocale: TLangType;
  createdLocales: TLangType[];
};

export const StoryHeaderPanel = ({
  selectedStoryGroupId,
  selectedLocale,
  createdLocales,
}: TProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { elementsWithErrors } = useStoryEditorContext();
  const { openedModal, openModal } = useContext(ModalContext);
  const { mutateAsync: publishStoryGroup } = useUpdateStoryGroupMutation();

  const [isClosing, setIsClosing] = useState(false);
  const formMethods = useForm<TFormValues>({
    mode: 'onChange',
  });

  const handleBackButtonClick = () => {
    setIsClosing(true);
  };

  const handleBackButtonClose = () => {
    setIsClosing(false);
  };

  const handleSaveStory = () => {
    openModal?.('saveStory');
  };

  const handleCloseModal = () => {
    openModal?.(null);
  };

  const handlePublish = (date?: Date) => {
    if (date) {
      publishStoryGroup({
        id: selectedStoryGroupId,
        availableLocales: createdLocales,
        published: date.toString(),
      }).then((result) => {
        getSuccessStoryPublicationNotify(result);
        navigate(routes.stories);
      });
    }
    handleCloseModal();
  };

  return (
    <FormProvider {...formMethods}>
      <div className={styles.root}>
        <IconButton
          className={styles.backButton}
          icon="chevron-left"
          onClick={handleBackButtonClick}
        />
        <StoryHeaderLocalesControl selectedLocale={selectedLocale} />
        <div className={styles.buttons}>
          <PreviewSelect />
          <Button
            isDisabled={!isEmpty(elementsWithErrors)}
            onClick={handleSaveStory}
          >
            {t('shared.confirm')}
          </Button>
        </div>
        <SaveStoryModal
          createdLocales={createdLocales}
          isOpened={openedModal === 'saveStory'}
          selectedStoryGroupId={selectedStoryGroupId}
          onClose={handleCloseModal}
          onPublishNow={handlePublish}
        />
        <ConfirmationModal
          isOpened={isClosing}
          description={t('content.story.storyHeaderPanel.modalDescription', {
            mark: '.',
          })}
          title={t('shared.backToRoot.confirmationModal.titleWithMark', {
            mark: '?',
          })}
          onClose={handleBackButtonClose}
          onConfirm={() => navigate(routes.stories)}
        />
        <DatePicker
          isOpened={openedModal === 'datePicker'}
          onClose={handleSaveStory}
          onDateChange={handlePublish}
        />
      </div>
    </FormProvider>
  );
};
