// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SecondLayoutNavigationLink-module__link--LKiu1 {\n  display: block;\n  width: 100%;\n  padding: 8px 12px;\n  text-decoration: none;\n  color: var(--dark-blue-100);\n  border-radius: var(--border-radius-l);\n}\n\n  .SecondLayoutNavigationLink-module__link--LKiu1:hover {\n    background: var(--gray-100);\n  }\n\n.SecondLayoutNavigationLink-module__isActive--BKMqc {\n  background: var(--gray-150);\n}\n\n.SecondLayoutNavigationLink-module__disabled--SlI_b {\n  pointer-events: none;\n  opacity: var(--disabled-opacity);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/ui/layouts/second-layout/SecondLayoutNavigationLink/SecondLayoutNavigationLink.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,iBAAiB;EACjB,qBAAqB;EACrB,2BAA2B;EAC3B,qCAAqC;AAKvC;;EAHE;IACE,2BAA2B;EAC7B;;AAGF;EACE,2BAA2B;AAC7B;;AAEA;EACE,oBAAoB;EACpB,gCAAgC;AAClC","sourcesContent":[".link {\n  display: block;\n  width: 100%;\n  padding: 8px 12px;\n  text-decoration: none;\n  color: var(--dark-blue-100);\n  border-radius: var(--border-radius-l);\n\n  &:hover {\n    background: var(--gray-100);\n  }\n}\n\n.isActive {\n  background: var(--gray-150);\n}\n\n.disabled {\n  pointer-events: none;\n  opacity: var(--disabled-opacity);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "SecondLayoutNavigationLink-module__link--LKiu1",
	"isActive": "SecondLayoutNavigationLink-module__isActive--BKMqc",
	"disabled": "SecondLayoutNavigationLink-module__disabled--SlI_b"
};
export default ___CSS_LOADER_EXPORT___;
