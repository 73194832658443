// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageModal-module__previewImage--zdhA_ {\n  max-width: 900px;\n  max-height: 700px;\n}\n\n.ImageModal-module__overlay--dWdQW.ImageModal-module__overlay--dWdQW {\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/ImageModal/ImageModal.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".previewImage {\n  max-width: 900px;\n  max-height: 700px;\n}\n\n.overlay.overlay {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"previewImage": "ImageModal-module__previewImage--zdhA_",
	"overlay": "ImageModal-module__overlay--dWdQW"
};
export default ___CSS_LOADER_EXPORT___;
