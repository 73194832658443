import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import { CountrySelect } from 'feature/country-select/CountrySelect';
import { OpponentPlayerModalDiscarded } from 'feature/opponent-player-modal/OpponentPlayerModalDiscarded';
import { TOpponentPlayerModalFields } from 'feature/opponent-player-modal/types';
import {
  MAX_OPPONENT_PLAYER_FIRST_NAME_LENGTH,
  MAX_OPPONENT_PLAYER_LAST_NAME_LENGTH,
  MAX_OPPONENT_PLAYER_NICKNAME_LENGTH,
  opponnentPlayerFormValidationSchema,
} from 'feature/opponent-player-modal/validationSchema';
import { UploadImage } from 'feature/right-panel/ui/UploadImage/UploadImage';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { FormInput } from 'shared/components/Form';
import { Text } from 'shared/components/Text/Text';
import { getImageSettings } from 'shared/constants';
import { getLangNameMap } from 'shared/consts';
import { TLangType } from 'shared/types/common';

import styles from './OpponnentPlayerModal.module.css';

type TProps = {
  title: string;
  isOpened: boolean;
  closeModal: () => void;
  isLoading?: boolean;
  initialValues?: TOpponentPlayerModalFields;
  actionSubmit: (fields: TOpponentPlayerModalFields) => void;
};

const getDefaultValues = (locale: TLangType): TOpponentPlayerModalFields => ({
  number: '',
  countryId: '',
  nickname: '',
  discarded: 'false',
  translations: {
    [locale]: {
      firstName: '',
      lastName: '',
    },
  },
});

export const OpponentPlayerModal = ({
  title,
  isOpened,
  closeModal,
  actionSubmit,
  initialValues,
  isLoading,
}: TProps) => {
  const { locales, defaultLocale } = useLocales();
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<TOpponentPlayerModalFields>({
    values: initialValues ?? getDefaultValues(defaultLocale),
    resolver: zodResolver(opponnentPlayerFormValidationSchema(locales)),
    mode: 'onBlur',
  });

  const submit = handleSubmit((data) => {
    actionSubmit(data);
  });

  const langNames = getLangNameMap();

  return (
    <ActionModal
      contentClassName={styles.root}
      opened={isOpened}
      title={title}
      width={542}
      confirmButtonProps={{
        isLoading,
        onClick: submit,
        isDisabled: !isValid,
      }}
      onClose={closeModal}
    >
      <Text size={14} tag="h3" weight="SemiBold">
        Имя
      </Text>
      <div className={styles.localesInput}>
        {locales?.map((locale) => {
          return (
            <FormInput
              key={locale}
              control={control}
              fieldName={`translations.${locale}.firstName`}
              label={langNames[locale]}
              maxLength={MAX_OPPONENT_PLAYER_FIRST_NAME_LENGTH}
              placeholder="Введите имя"
              required
              size="s"
            />
          );
        })}
      </div>
      <Text size={14} tag="h3" weight="SemiBold">
        Фамилия
      </Text>
      <div className={styles.localesInput}>
        {locales?.map((locale) => {
          return (
            <FormInput
              key={locale}
              control={control}
              fieldName={`translations.${locale}.lastName`}
              label={langNames[locale]}
              maxLength={MAX_OPPONENT_PLAYER_LAST_NAME_LENGTH}
              placeholder="Введите фамилию"
              required
              size="s"
            />
          );
        })}
      </div>
      <Text size={14} tag="h3" weight="SemiBold">
        Никнейм
      </Text>
      <FormInput
        control={control}
        fieldName="nickname"
        maxLength={MAX_OPPONENT_PLAYER_NICKNAME_LENGTH}
        placeholder="Введите никнейм"
        size="s"
      />
      <CountrySelect
        className={styles.field}
        control={control}
        fieldName="countryId"
        labelClassName={styles.label}
      />
      <FormInput
        className={styles.field}
        control={control}
        fieldName="number"
        label="Номер"
        labelClassName={styles.label}
        placeholder="Введите номер игрока"
        required
        size="s"
      />
      <OpponentPlayerModalDiscarded control={control} />
      <UploadImage
        aspectRatio={getImageSettings().opponentPlayer.picture.aspectRatio}
        control={control}
        fieldName="picture"
        hint={getImageSettings().opponentPlayer.picture.hint}
        label="Изображение игрока"
        labelClassName={styles.label}
        modalTitle="Настройка изображения"
        showIcon={false}
      />
    </ActionModal>
  );
};
