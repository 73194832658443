// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VideoFormBackButton-module__backButton--Rdlwf {\n  padding: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/4-widgets/video-form/ui/VideoFormHeaderPanel/VideoFormBackButton/VideoFormBackButton.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd","sourcesContent":[".backButton {\n  padding: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": "VideoFormBackButton-module__backButton--Rdlwf"
};
export default ___CSS_LOADER_EXPORT___;
