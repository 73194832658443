import { useLocales } from 'entities/config/lib/useLocales';
import { EditorElementTypeEnum, TEditorVideo } from 'shared/components/Editor/types';
import { VideoPlayer } from 'shared/components/VideoPlayer/VideoPlayer';
import { TWorkspaceElement } from 'shared/components/Workspace/types';
import { TLangType } from 'shared/types/common';

export const AddNewPostPreviewVideoElement = ({
  element,
  lang,
}: {
  element: TWorkspaceElement;
  lang: TLangType;
}) => {
  const { defaultLocale } = useLocales();
  if (element.type !== EditorElementTypeEnum.Video) {
    return null;
  }

  const videoDefaultLang = element.translations[defaultLocale] as TEditorVideo;
  const videoCurrentLang = element.translations[lang] ?? videoDefaultLang;

  return <VideoPlayer {...videoCurrentLang} />;
};
