// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SocialNetworkLabel-module__optionLabel--XMJBG {\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n  gap: 4px;\n}\n\n.SocialNetworkLabel-module__icon--fbE1q {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n  width: 12px;\n  aspect-ratio: 1 / 1;\n  border-radius: var(--border-radius-max);\n}\n\n.SocialNetworkLabel-module__text--u1XBy {\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/5-feature/social-network-modal/ui/SocialNetworkLabel/SocialNetworkLabel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,WAAW;EACX,mBAAmB;EACnB,uCAAuC;AACzC;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".optionLabel {\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n  gap: 4px;\n}\n\n.icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n  width: 12px;\n  aspect-ratio: 1 / 1;\n  border-radius: var(--border-radius-max);\n}\n\n.text {\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionLabel": "SocialNetworkLabel-module__optionLabel--XMJBG",
	"icon": "SocialNetworkLabel-module__icon--fbE1q",
	"text": "SocialNetworkLabel-module__text--u1XBy"
};
export default ___CSS_LOADER_EXPORT___;
