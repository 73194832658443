import { isNil } from 'lodash';
import { toNumber } from 'shared/lib/toNumber';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';

export function compareIds(
  id1: TEntityId | null | undefined,
  id2: TEntityId | null | undefined,
) {
  if (isNil(id1) || isNil(id2)) {
    return false;
  }

  return toString(id1) === toString(id2);
}

export const mapIdsToNumbers = (array: TEntityId[]) => array.map(toNumber);

export const convertIdToNumberArray = (id: TEntityId) => [toNumber(id)];

export const prepareEntitiesIds = (ids: TEntityId[]) =>
  ids.length ? mapIdsToNumbers(ids) : [];
