// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToolsPanel-module__root--qixKh {\n  background-color: var(--bg-white);\n  min-width: 260px;\n  max-width: 260px;\n  display: flex;\n  flex-direction: column;\n  border-left: 1px solid var(--gray-200);\n  height: 100%;\n  overflow: auto;\n}\n\n.ToolsPanel-module__tabs--Nloav {\n  flex: 1 1;\n  display: flex;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/stories/edit/EditStory/components/ToolsPanel/ToolsPanel.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,sCAAsC;EACtC,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".root {\n  background-color: var(--bg-white);\n  min-width: 260px;\n  max-width: 260px;\n  display: flex;\n  flex-direction: column;\n  border-left: 1px solid var(--gray-200);\n  height: 100%;\n  overflow: auto;\n}\n\n.tabs {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ToolsPanel-module__root--qixKh",
	"tabs": "ToolsPanel-module__tabs--Nloav"
};
export default ___CSS_LOADER_EXPORT___;
