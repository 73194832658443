// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LocalizationModal-module__form--ePHDj {\n  margin-top: 8px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/LocalizationModal/LocalizationModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".form {\n  margin-top: 8px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "LocalizationModal-module__form--ePHDj"
};
export default ___CSS_LOADER_EXPORT___;
