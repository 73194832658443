import { useFetchVideosQuery } from 'entities/video/queries';
import { TVideoFilter } from 'feature/filters/types';
import { TVideoTableSortParams } from 'pages/video/VideoPage/types';
import {
  calculateSelectedPage,
  calculateTotalItems,
  createFilterQuery,
  createSortQuery,
} from 'pages/video/VideoPage/utils';
import { VideoTable } from 'pages/video/VideoPage/VideoTable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'shared/routes';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';
import { useCommonListTableSort } from 'widgets/elements-list-table/hooks/useCommonListTableSort';

import styles from './VideoPage.module.css';

export const DEFAULT_VIDEO_SORT_OPTIONS = {
  direction: 'desc',
  sortBy: 'created_at',
} as const;

export const VideoPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sortOptions, handleSort } = useCommonListTableSort<TVideoTableSortParams>(
    DEFAULT_VIDEO_SORT_OPTIONS,
  );
  const [filter, setFilter] = useState<TVideoFilter | null>(null);

  const {
    data: { data: videoPosts = [], page = 1, total = 0 } = {},
    isLoading,
    error,
    changePage,
  } = useFetchVideosQuery({
    sort: createSortQuery(sortOptions),
    filter: createFilterQuery(filter),
  });

  const handleFilter = (filter: TVideoFilter) => {
    setFilter(filter);
    changePage(1);
  };

  return (
    <div className={styles.root}>
      <CommonListWrapper
        emptyComponentDescription={t('content.video.createFirstVideo')}
        error={error}
        isEmpty={!filter && (!videoPosts || (total === 0 && !isLoading))}
        isLoading={isLoading}
        title={t('content.video')}
        createButtonProps={{
          content: t('content.video.createVideo'),
          onClick: () => navigate(routes.createVideo),
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(videoPosts, page),
          totalItems: calculateTotalItems(videoPosts, total),
          onPageChange: changePage,
        }}
      >
        {videoPosts && sortOptions && (
          <VideoTable
            filter={filter}
            sortOptions={sortOptions}
            videoPosts={videoPosts}
            onFilter={handleFilter}
            onSort={handleSort}
          />
        )}
      </CommonListWrapper>
    </div>
  );
};
