import { useDeleteVideoPostMutation } from 'entities/video/mutations';
import { EVideoPostStatus, TVideoPost } from 'entities/video/types';
import {
  TPostponedVideoActions,
  TVideoActionsListModal,
} from 'entities/video/video-actions/types';
import { getVideoActions } from 'entities/video/video-actions/utils';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useVideoPostActions = (
  videoPost: TVideoPost,
  setActiveModal: Dispatch<SetStateAction<TVideoActionsListModal | null>>,
) => {
  const { t } = useTranslation();
  const { mutateAsync: deleteVideoPostMutation, isLoading: isVideoDeleting } =
    useDeleteVideoPostMutation();

  const deleteVideoPost = useCallback((id: TEntityId) => {
    deleteVideoPostMutation(id).catch(() =>
      notify(t('content.validation.notifyDeleteVideo')),
    );
  }, []);

  const unpublishVideoPost =
    videoPost.status === EVideoPostStatus.Published
      ? () => setActiveModal('confirmUnpublish')
      : null;

  const postponedActions: TPostponedVideoActions | null = useMemo(() => {
    if (videoPost.status === EVideoPostStatus.Postponed) {
      return {
        publishNow: {
          title: t('shared.NewsPostForm.AddNewsPostPublishModal.confirmText', {
            count: 0,
          }),
          icon: 'plus',
          action: () => setActiveModal('confirmPublishNow'),
        },
        changePublishDate: {
          title: t('content.changeTimePublic'),
          icon: 'clock',
          action: () => setActiveModal('changePublishDate'),
        },
      };
    }
    return null;
  }, []);

  return {
    actions: getVideoActions(deleteVideoPost, unpublishVideoPost, postponedActions),
    isVideoDeleting,
  };
};
