// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommonListWrapper-module__root--JSCCc {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: var(--pad);\n  min-height: 100%;\n  width: 100%;\n}\n\n.CommonListWrapper-module__loading--VsX5N {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.CommonListWrapper-module__header--t8GWy {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: var(--dark-blue-400);\n}\n\n.CommonListWrapper-module__createButton--c7lRi {\n  max-width: -moz-max-content;\n  max-width: max-content;\n  padding: 7px 22px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.CommonListWrapper-module__pagination--zKrP4 {\n  margin-top: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/4-widgets/elements-list/ui/CommonListWrapper/CommonListWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,2BAAsB;EAAtB,sBAAsB;EACtB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: var(--pad);\n  min-height: 100%;\n  width: 100%;\n}\n\n.loading {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: var(--dark-blue-400);\n}\n\n.createButton {\n  max-width: max-content;\n  padding: 7px 22px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.pagination {\n  margin-top: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CommonListWrapper-module__root--JSCCc",
	"loading": "CommonListWrapper-module__loading--VsX5N",
	"header": "CommonListWrapper-module__header--t8GWy",
	"createButton": "CommonListWrapper-module__createButton--c7lRi",
	"pagination": "CommonListWrapper-module__pagination--zKrP4"
};
export default ___CSS_LOADER_EXPORT___;
