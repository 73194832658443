// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomCaption-module__container--iQElW {\n  display: flex;\n  justify-content: space-between;\n  padding: 0 10px;\n}\n\n.CustomCaption-module__currentMonthButton--mxdXp i {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n.CustomCaption-module__select--uBBHi {\n  color: #272833;\n}\n\n.CustomCaption-module__select--uBBHi div:first-child {\n    border: none;\n    font-weight: 600;\n    font-size: 14px;\n  }\n\n.CustomCaption-module__select--uBBHi i {\n    margin-left: 8px;\n  }\n\n.CustomCaption-module__optionsContainer--yewaa {\n  margin-right: 8px;\n}\n\n.CustomCaption-module__optionsContainer--yewaa::-webkit-scrollbar-track {\n    margin: 8px;\n  }\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/DatePicker/components/CustomCaption/CustomCaption.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAGE;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;AAGF;EACE,cAAc;AAWhB;;AATE;IACE,YAAY;IACZ,gBAAgB;IAChB,eAAe;EACjB;;AAEA;IACE,gBAAgB;EAClB;;AAGF;EACE,iBAAiB;AAKnB;;AAHE;IACE,WAAW;EACb","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  padding: 0 10px;\n}\n\n.currentMonthButton {\n  i {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n}\n\n.select {\n  color: #272833;\n\n  div:first-child {\n    border: none;\n    font-weight: 600;\n    font-size: 14px;\n  }\n\n  i {\n    margin-left: 8px;\n  }\n}\n\n.optionsContainer {\n  margin-right: 8px;\n\n  &::-webkit-scrollbar-track {\n    margin: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CustomCaption-module__container--iQElW",
	"currentMonthButton": "CustomCaption-module__currentMonthButton--mxdXp",
	"select": "CustomCaption-module__select--uBBHi",
	"optionsContainer": "CustomCaption-module__optionsContainer--yewaa"
};
export default ___CSS_LOADER_EXPORT___;
