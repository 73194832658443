import { socialNetworksApiKeys } from 'entities/socialNetworks/consts';
import { TSocialNetwork } from 'entities/socialNetworks/types';
import { get } from 'shared/api/axios';
import { mapPaginatedResponse } from 'shared/api/pagination';
import { TFetchParams } from 'shared/api/types';

export const fetchSocialNetworks = ({ params }: TFetchParams) => {
  return get<TSocialNetwork[], true>(socialNetworksApiKeys.getSocialNetworks, {
    params,
  }).then((response) => mapPaginatedResponse(response.data));
};
