// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManagerCareerModal-module__modalContent--OJAxq {\n  height: 398px;\n  overflow-y: scroll;\n  margin-right: 3px;\n}\n\n  .ManagerCareerModal-module__modalContent--OJAxq::-webkit-scrollbar-track {\n    margin: 4px;\n  }\n\n.ManagerCareerModal-module__inputWrapper--OTExa {\n  margin-top: 8px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.ManagerCareerModal-module__section--ZS_Ty {\n  margin-top: 8px;\n}\n\n.ManagerCareerModal-module__inputForm--g74qO::-moz-placeholder {\n    color: var(--gray-500);\n  }\n\n.ManagerCareerModal-module__inputForm--g74qO::placeholder {\n    color: var(--gray-500);\n  }\n", "",{"version":3,"sources":["webpack://./src/5-feature/manager-career-modal/ui/ManagerCareerModal/ManagerCareerModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;AAKnB;;EAHE;IACE,WAAW;EACb;;AAGF;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAGE;IACE,sBAAsB;EACxB;;AAFA;IACE,sBAAsB;EACxB","sourcesContent":[".modalContent {\n  height: 398px;\n  overflow-y: scroll;\n  margin-right: 3px;\n\n  &::-webkit-scrollbar-track {\n    margin: 4px;\n  }\n}\n\n.inputWrapper {\n  margin-top: 8px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.section {\n  margin-top: 8px;\n}\n\n.inputForm {\n  &::placeholder {\n    color: var(--gray-500);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "ManagerCareerModal-module__modalContent--OJAxq",
	"inputWrapper": "ManagerCareerModal-module__inputWrapper--OTExa",
	"section": "ManagerCareerModal-module__section--ZS_Ty",
	"inputForm": "ManagerCareerModal-module__inputForm--g74qO"
};
export default ___CSS_LOADER_EXPORT___;
