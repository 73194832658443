// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TeamsPage-module__root--cbRCu {\n  min-height: calc(100vh - var(--nav-header-height));\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  gap: 20px;\n  padding: 20px 24px;\n}\n\n.TeamsPage-module__listWrapper--eZYrf {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  gap: 24px;\n}\n\n.TeamsPage-module__pagination--XGxU2 {\n  margin-top: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/team/TeamsPage/TeamsPage.module.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;EAClD,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,2BAA2B;EAC3B,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".root {\n  min-height: calc(100vh - var(--nav-header-height));\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  gap: 20px;\n  padding: 20px 24px;\n}\n\n.listWrapper {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  gap: 24px;\n}\n\n.pagination {\n  margin-top: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "TeamsPage-module__root--cbRCu",
	"listWrapper": "TeamsPage-module__listWrapper--eZYrf",
	"pagination": "TeamsPage-module__pagination--XGxU2"
};
export default ___CSS_LOADER_EXPORT___;
