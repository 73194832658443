import { SocialNetworkModal } from 'feature/social-network-modal/ui/SocialNetworkModal/SocialNetworkModal';
import { useManagerSocialNetworksActions } from 'pages/manager/social-networks/ManagerSocialNetworksPage/hooks/useManagerSocialNetworksActions';
import { TUseManagerSocialNetworksActions } from 'pages/manager/social-networks/ManagerSocialNetworksPage/types';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';

export const ManagerSocialNetworksActions = (
  props: TUseManagerSocialNetworksActions,
) => {
  const { actionsProps, deleteModalProps, editModalProps } =
    useManagerSocialNetworksActions(props);

  return (
    <>
      <Actions {...actionsProps} />
      {deleteModalProps.isOpened && <ConfirmationModal {...deleteModalProps} />}
      {editModalProps.isOpened && <SocialNetworkModal {...editModalProps} />}
    </>
  );
};
