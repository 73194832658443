import cn from 'classnames';
import { TFilterComponentProps, TVideoFilter } from 'feature/filters/types';
import { TMappedCategory } from 'feature/filters/ui/CategoryFilter/type';
import styles from 'feature/filters/ui/Filter.module.css';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { Checkbox } from 'shared/components/Form';
import { Loader } from 'shared/components/Loader/Loader';
import { Text } from 'shared/components/Text/Text';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';

type TValues = Record<string, boolean>;

type TProps = TFilterComponentProps<TVideoFilter> & {
  categories?: TMappedCategory[];
  isLoading?: boolean;
};

export const CategoryFilter = ({
  categories,
  isLoading,
  filter,
  setFilter,
}: TProps) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<TValues>({
    defaultValues: categories?.reduce<TValues>((acc, { id }) => {
      acc[id] = (filter.categories ?? []).includes(id);

      return acc;
    }, {}),
  });

  const submit = (values: TValues) => {
    const categoriesFilter: TEntityId[] = [];
    if (!categories) {
      return;
    }
    categories.forEach(({ id }) => {
      if (values[id]) {
        categoriesFilter.push(id);
      }
    });
    setFilter({ ...filter, categories: categoriesFilter });
  };

  const onSubmit = useMemo(() => handleSubmit(submit), [submit]);

  return (
    <form
      className={cn(styles.container, {
        [styles.loading]: isLoading,
      })}
      onSubmit={onSubmit}
    >
      <div
        className={cn(styles.categoriesWrapper, {
          [styles.isLoading]: isLoading,
        })}
      >
        {isLoading ? (
          <Loader showLabel size="s" />
        ) : (
          categories!.map(({ id, name }) => (
            <Checkbox
              key={id}
              className={styles.checkbox}
              control={control}
              fieldName={toString(id)}
              labelClassName={styles.labelContainer}
              checkboxLabel={
                <div className={styles.label}>
                  <Text className={styles.labelText} size={12}>
                    {name}
                  </Text>
                </div>
              }
            />
          ))
        )}
      </div>
      <Button htmlType="submit" isDisabled={isLoading} onClick={onSubmit}>
        {t('shared.confirm')}
      </Button>
    </form>
  );
};
