import { ManagerSocialNetworksActions } from 'pages/manager/social-networks/ManagerSocialNetworksPage/ManagerSocialNetworksActions/ManagerSocialNetworksActions';
import { TManagerSocialNetworkItem } from 'pages/manager/social-networks/ManagerSocialNetworksPage/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from './ManagerSocialNetworksPage.module.css';

export const getManagerSocialNetworksColumnsConfig: TConfigCreator<
  TManagerSocialNetworkItem
> = (defaultLocale, activeSocialLinkId, setActiveSocialLinkId) => {
  const { t } = useTranslation();

  return [
    {
      title: t('content.title'),
      cellClassName: styles.nameColumn,
      render: (item) => (
        <>
          <div className={styles.icon}>
            <img
              alt={`social network ${item.socialNetwork.translations[defaultLocale].name}`}
              src={item.socialNetwork.picture.x3}
            />
          </div>
          <Text className={styles.text} color="var(--gray-500)">
            {item.socialNetwork.translations[defaultLocale].name}
          </Text>
        </>
      ),
    },
    {
      title: '',
      render: (managerSocialNetwork) => (
        <ManagerSocialNetworksActions
          activeSocialLinkId={activeSocialLinkId}
          managerSocialNetwork={managerSocialNetwork}
          setActiveSocialLinkId={setActiveSocialLinkId}
        />
      ),
    },
  ];
};
