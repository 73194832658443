import cn from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { useCreateVideoPost } from 'widgets/video-form/hooks/useCreateVideoPost';
import { useIsEditVideo } from 'widgets/video-form/hooks/useIsEditVideo';
import { TVideoPostFields } from 'widgets/video-form/types';

import styles from './VideoFormDraftButton.module.css';

export const VideoFormDraftButton = () => {
  const { t } = useTranslation();
  const { formState, handleSubmit, control } = useFormContext<TVideoPostFields>();
  const { field } = useController({
    control,
    name: 'publishDate',
  });
  const isEdit = useIsEditVideo();
  const createVideoPost = useCreateVideoPost();

  if (isEdit) {
    return null;
  }

  const onDraftNewsPost = () => {
    field.onChange(undefined);
    handleSubmit(createVideoPost)();
  };

  return (
    <Button
      color="gray"
      isDisabled={formState.isSubmitting}
      kind="outlined"
      className={cn(styles.draftButton, {
        [styles.disabled]: !formState.isValid,
      })}
      onClick={onDraftNewsPost}
    >
      {t('shared.NewsPostForm.AddNewsPostDraftButton.button')}
    </Button>
  );
};
