import { playerAchievementsApiKeys } from 'entities/playerAchievements/consts';
import {
  TCreatePlayerAchievementDTO,
  TPlayerAchievement,
} from 'entities/playerAchievements/types';
import { del, get, patch, post } from 'shared/api/axios';
import { mapPaginatedResponse } from 'shared/api/pagination';
import { TFetchParams } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchPlayerAchievements = ({ params }: TFetchParams) =>
  get<TPlayerAchievement[], true>(playerAchievementsApiKeys.getPlayerAchievements, {
    params,
  }).then((response) => mapPaginatedResponse(response.data));

export const createPlayerAchievement = (achievement: TCreatePlayerAchievementDTO) =>
  post(playerAchievementsApiKeys.createPlayerAchievement, {
    achievement,
  }).then((response) => response.data.data);

export const deletePlayerAchievement = (achievementId: TEntityId) =>
  del(playerAchievementsApiKeys.deletePlayerAchievement(achievementId));

export const editPlayerAchievement = ({
  id,
  ...playerAchievement
}: TPlayerAchievement) =>
  patch(playerAchievementsApiKeys.editPlayerAchievement(id), playerAchievement).then(
    (response) => response.data.data,
  );
