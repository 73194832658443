import { useLocales } from 'entities/config/lib/useLocales';
import { mapAPIVideoPostToVideoPostFields } from 'entities/video/mapper/mapAPIVideoPostToVideoPostFields';
import { TVideoPost } from 'entities/video/types';
import { useCallback, useState } from 'react';
import { useEditVideoPost } from 'widgets/video-form/hooks/useEditVideoPost';

export const useUnpublishVideo = (videoPost: TVideoPost) => {
  const [isUnpublishing, setIsUnpublishing] = useState(false);
  const { defaultLocale } = useLocales();
  const editVideoPost = useEditVideoPost();

  const handleConfirmUnpublish = useCallback(async () => {
    setIsUnpublishing(true);

    const initialValue = await mapAPIVideoPostToVideoPostFields(
      videoPost,
      defaultLocale,
    );

    await editVideoPost({
      ...initialValue,
      publishDate: undefined,
    });

    setIsUnpublishing(false);
  }, [videoPost, defaultLocale, editVideoPost]);

  return { handleConfirmUnpublish, isUnpublishing };
};
