import { getTextMinLengthMessage, TEXT_MIN_LENGTH } from 'shared/consts';
import { declination } from 'shared/lib/declination';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toBoolean } from 'shared/lib/toBoolean';
import { TLangType } from 'shared/types/common';
import z from 'zod';

const MAX_NUMBER_LENGTH = 2;
export const MAX_OPPONENT_PLAYER_FIRST_NAME_LENGTH = 255;
export const MAX_OPPONENT_PLAYER_LAST_NAME_LENGTH = 255;
export const MAX_OPPONENT_PLAYER_NICKNAME_LENGTH = 255;

const maxLengthTextGenerate = (length: number) =>
  `Максимальная длина ${length} ${declination(length, ['символ', 'символа', 'символов'])}`;

export const opponnentPlayerFormValidationSchema = (locales: TLangType[]) =>
  z
    .object({
      translations: z.object({
        ...reduceTranslationObject(locales, () =>
          z.object({
            firstName: z
              .string()
              .min(TEXT_MIN_LENGTH, getTextMinLengthMessage())
              .max(
                MAX_OPPONENT_PLAYER_FIRST_NAME_LENGTH,
                maxLengthTextGenerate(MAX_OPPONENT_PLAYER_FIRST_NAME_LENGTH),
              ),
            lastName: z
              .string()
              .min(TEXT_MIN_LENGTH, getTextMinLengthMessage())
              .max(
                MAX_OPPONENT_PLAYER_LAST_NAME_LENGTH,
                maxLengthTextGenerate(MAX_OPPONENT_PLAYER_LAST_NAME_LENGTH),
              ),
          }),
        ),
      }),
      nickname: z
        .string()
        .max(
          MAX_OPPONENT_PLAYER_NICKNAME_LENGTH,
          maxLengthTextGenerate(MAX_OPPONENT_PLAYER_NICKNAME_LENGTH),
        )
        .optional(),
      countryId: z.string().min(TEXT_MIN_LENGTH, getTextMinLengthMessage()),
      number: z
        .string()
        .min(TEXT_MIN_LENGTH, getTextMinLengthMessage())
        .max(MAX_NUMBER_LENGTH, maxLengthTextGenerate(MAX_NUMBER_LENGTH)),
      discarded: z.string(),
      discardedAt: z.any().optional(),
    })
    .passthrough()
    .superRefine((form, context) => {
      if (form.discarded === 'true' && !toBoolean(form.discardedAt)) {
        context.addIssue({
          code: z.ZodIssueCode.too_small,
          message: getTextMinLengthMessage(),
          path: ['discardedAt'],
          type: 'date',
          minimum: TEXT_MIN_LENGTH,
          inclusive: false,
        });
      }
    });
