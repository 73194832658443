import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { playersQueryKeys } from 'entities/players/api/consts';
import { TPlayer } from 'entities/players/model/types';
import { TPaginatedResponse } from 'shared/api/pagination';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

import { fetchPlayer, fetchPlayers } from './api';

export type TFetchPlayersParams = {
  filter?: Record<string, string>;
} & TPaginatedParameters;

export const useFetchPlayersQuery = (
  { filter, ...defaultPagination }: TFetchPlayersParams,
  options: UseQueryOptions<TPaginatedResponse<TPlayer>> = {},
) =>
  usePaginationQuery(
    (pagination) => ({
      queryKey: [playersQueryKeys.players, pagination, filter],
      queryFn: () => fetchPlayers({ params: { ...pagination, ...filter } }),
      ...options,
    }),
    defaultPagination,
  );

export const useFetchPlayerQuery = (
  id: TEntityId = '',
  options: UseQueryOptions<TPlayer, unknown, TPlayer> = {},
) =>
  useQuery({
    queryKey: [playersQueryKeys.player, id],
    queryFn: () => fetchPlayer(id),
    enabled: toBoolean(id),
    ...options,
  });
