import {
  EditorElementTypeEnum,
  TEditorElement,
  TEditorImageElement,
  TEditorParagraphElement,
  TEditorSliderElement,
  TEditorVideoElement,
} from 'shared/components/Editor/types';
import { deserializeWorkspaceElements } from 'shared/components/Workspace/desearializer';
import { createWorkspaceParagraphElement } from 'shared/components/Workspace/helpers';
import { serializeEditorElementsToHTML } from 'shared/components/Workspace/searializer';
import { TWorkspaceElement } from 'shared/components/Workspace/types';
import { TLangType } from 'shared/types/common';

export const prepareEditorElements = (
  elements: TWorkspaceElement[],
  lang: TLangType,
  defaultLocale: TLangType,
): TEditorElement[] =>
  elements.map((element) => {
    if (element.type === EditorElementTypeEnum.Paragraph) {
      return deserializeWorkspaceElements(element, lang);
    }

    if (element.type === EditorElementTypeEnum.Slider) {
      return {
        ...element,
        files: element.translations[lang] ?? element.translations[defaultLocale],
      } as TEditorSliderElement;
    }

    if (element.type === EditorElementTypeEnum.Video) {
      return {
        ...element,
        file: element.translations[lang] ?? element.translations[defaultLocale],
      } as TEditorVideoElement;
    }

    if (element.type === EditorElementTypeEnum.Image) {
      return {
        ...element,
        file: element.translations[lang] ?? element.translations[defaultLocale],
      } as TEditorImageElement;
    }

    return element;
  });

export const prepareWorkspaceElements = (
  editorElements: TEditorElement[],
  lang: TLangType,
): TWorkspaceElement[] => {
  const workspaceElements: TWorkspaceElement[] = [];

  editorElements.forEach((element) => {
    if (
      element.type === EditorElementTypeEnum.BulletedList ||
      element.type === EditorElementTypeEnum.HeadingTwo
    ) {
      const htmlElement = serializeEditorElementsToHTML(element);
      const paragraphElement = createWorkspaceParagraphElement(htmlElement, lang);

      workspaceElements.push({
        id: element.id,
        ...paragraphElement,
        translations: {
          ...element.translations,
          ...paragraphElement.translations,
        },
      });
    }

    if (element.type === EditorElementTypeEnum.Paragraph) {
      const text = serializeEditorElementsToHTML(element);

      const paragraphElement = {
        ...element,
        translations: {
          ...element.translations,
          [lang]: {
            text,
          },
        },
      } as TEditorParagraphElement;
      workspaceElements.push(paragraphElement);
    }

    if (element.type === EditorElementTypeEnum.Slider) {
      const sliderElement = {
        ...element,
        translations: {
          ...element.translations,
          [lang]: element.files,
        },
      } as TEditorSliderElement;
      workspaceElements.push(sliderElement);
    }

    if (element.type === EditorElementTypeEnum.Video) {
      const videoElement = {
        ...element,
        translations: {
          ...element.translations,
          [lang]: element.file,
        },
      } as TEditorVideoElement;

      workspaceElements.push(videoElement);
    }

    if (element.type === EditorElementTypeEnum.Image) {
      const imageElement = {
        ...element,
        translations: {
          ...element.translations,
          [lang]: element.file,
        },
      } as TEditorImageElement;
      workspaceElements.push(imageElement);
    }
  });

  return workspaceElements;
};
