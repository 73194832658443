import { LocalesControl } from 'shared/components/LocalesControl/LocalesControl';
import { useGetLocalesControl } from 'shared/components/NewsPostForm/components/AddNewsPostLocalesControl/hooks';

export const AddNewsPostLocalesControl = () => {
  const {
    createdLocales,
    selectedLocale,
    onLocaleAdd,
    onLocaleDelete,
    onSelectLocale,
  } = useGetLocalesControl();

  return (
    <LocalesControl
      createdLocales={createdLocales}
      selectedLocale={selectedLocale}
      onLocaleAdd={onLocaleAdd}
      onLocaleDelete={onLocaleDelete}
      onLocaleSelect={onSelectLocale}
    />
  );
};
