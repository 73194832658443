// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Roboto */\n\n/* Montserrat */\n\n/* Playfair Display */\n", "",{"version":3,"sources":["webpack://./src/1-app/assets/styles/fonts.css"],"names":[],"mappings":"AAAA,WAAW;;AAIX,eAAe;;AAIf,qBAAqB","sourcesContent":["/* Roboto */\n\n@import url(\"https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700\");\n\n/* Montserrat */\n\n@import url(\"https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700\");\n\n/* Playfair Display */\n\n@import url(\"https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700\");\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
