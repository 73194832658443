// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Icon-module__root--j_BEC {\n  display: inline-block;\n  line-height: 0;\n}\n\n.Icon-module__xs--IfiSm {\n  width: 12px;\n  height: 12px;\n}\n\n.Icon-module__s--XiTAm {\n  width: 16px;\n  height: 16px;\n}\n\n.Icon-module__m--La8QC {\n  width: 20px;\n  height: 20px;\n}\n\n.Icon-module__l--ouJMK {\n  width: 24px;\n  height: 24px;\n}\n\n.Icon-module__xl--d0h1k {\n  width: 48px;\n  height: 48px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Icon/Icon.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".root {\n  display: inline-block;\n  line-height: 0;\n}\n\n.xs {\n  width: 12px;\n  height: 12px;\n}\n\n.s {\n  width: 16px;\n  height: 16px;\n}\n\n.m {\n  width: 20px;\n  height: 20px;\n}\n\n.l {\n  width: 24px;\n  height: 24px;\n}\n\n.xl {\n  width: 48px;\n  height: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Icon-module__root--j_BEC",
	"xs": "Icon-module__xs--IfiSm",
	"s": "Icon-module__s--XiTAm",
	"m": "Icon-module__m--La8QC",
	"l": "Icon-module__l--ouJMK",
	"xl": "Icon-module__xl--d0h1k"
};
export default ___CSS_LOADER_EXPORT___;
