import { RightPanelCollapseWrapper } from 'feature/right-panel/ui/RightPanelCollapseWrapper/RightPanelCollapseWrapper';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'shared/components/Form/Select/FormSelect';

import {
  TDefaultRightPanelProps,
  TRightPanelMatchProps,
  TSelectFieldProps,
} from '../../types';
import { MatchSelect } from '../MatchSelect/MatchSelect';

type TProps<T extends FieldValues> = {
  tournament: TSelectFieldProps<T>;
  season: TSelectFieldProps<T>;
  players: TSelectFieldProps<T>;
  managers: TSelectFieldProps<T>;
  match: TRightPanelMatchProps;
  description: string;
  control: Control<T>;
};

export const CommonConnections = <T extends FieldValues>({
  control,
  tournament,
  season,
  description,
  players,
  managers,
  match,
  ...restProps
}: TDefaultRightPanelProps & TProps<T>) => {
  const { t } = useTranslation();
  return (
    <RightPanelCollapseWrapper
      description={description}
      label={t('content.connection')}
      title={t('content.chooseConnection')}
      {...restProps}
    >
      <FormSelect
        control={control}
        fieldName={tournament.fieldName}
        label={t('content.competition')}
        multiple={false}
        options={tournament.data}
        placeholder={tournament.placeholder}
        search
      />
      <FormSelect
        control={control}
        disabled={season.disabled}
        fieldName={season.fieldName}
        label={t('content.season')}
        multiple={false}
        options={season.data}
        placeholder={season.placeholder}
        search
      />
      <MatchSelect {...match} />
      <FormSelect
        control={control}
        fieldName={players.fieldName}
        label={t('content.player.player')}
        multiple={true}
        options={players.data}
        placeholder={players.placeholder}
        search
      />
      <FormSelect
        control={control}
        fieldName={managers.fieldName}
        label={t('content.trainerList')}
        multiple={true}
        options={managers.data}
        placeholder={managers.placeholder}
        search
      />
    </RightPanelCollapseWrapper>
  );
};
