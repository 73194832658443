// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Divider-module__horizontal--NvAGd {\n  height: 0;\n  border: 0;\n  border-top: 1px solid var(--divider);\n  width: 100%;\n}\n\n.Divider-module__vertical--NcK4x {\n  height: 100%;\n  width: 0;\n  border: 0;\n  border-left: 1px solid var(--divider);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Divider/Divider.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,SAAS;EACT,oCAAoC;EACpC,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,QAAQ;EACR,SAAS;EACT,qCAAqC;AACvC","sourcesContent":[".horizontal {\n  height: 0;\n  border: 0;\n  border-top: 1px solid var(--divider);\n  width: 100%;\n}\n\n.vertical {\n  height: 100%;\n  width: 0;\n  border: 0;\n  border-left: 1px solid var(--divider);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"horizontal": "Divider-module__horizontal--NvAGd",
	"vertical": "Divider-module__vertical--NcK4x"
};
export default ___CSS_LOADER_EXPORT___;
