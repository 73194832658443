import { videoCategoriesApiKeys } from 'entities/videoCategories/consts';
import {
  TCreateVideoCategoryDTO,
  TVideoCategory,
  TVideoEditCategory,
} from 'entities/videoCategories/types';
import { del, get, patch, post } from 'shared/api/axios';
import { mapPaginatedResponse } from 'shared/api/pagination';
import { TFetchParams } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchVideoCategories = ({ params }: TFetchParams) =>
  get<TVideoCategory[], true>(videoCategoriesApiKeys.getVideoCategories, {
    params,
  }).then((response) => mapPaginatedResponse(response.data));

export const createVideoCategory = (data: TCreateVideoCategoryDTO) =>
  post<TVideoCategory>(videoCategoriesApiKeys.createVideoCategory, {
    videoCategory: data,
  }).then((response) => response.data.data);

export const deleteVideoCategory = (categoryId: TEntityId) =>
  del(videoCategoriesApiKeys.deleteVideoCategory(categoryId)).then(
    (response) => response.data.data,
  );

export const editVideoCategory = ({ categoryId, data }: TVideoEditCategory) =>
  patch(videoCategoriesApiKeys.editVideoCategory(categoryId), {
    videoCategory: data,
  }).then((response) => response.data.data);
