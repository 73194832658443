import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import { CountrySelect } from 'feature/country-select/CountrySelect';
import { UploadImage } from 'feature/right-panel/ui/UploadImage/UploadImage';
import { teamModalValidationSchema } from 'feature/team-modal/validationSchema';
import i18n from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { Field, FormInput } from 'shared/components/Form';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { Text } from 'shared/components/Text/Text';
import { getImageSettings } from 'shared/constants';
import { getLangNameMap } from 'shared/consts';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toBoolean } from 'shared/lib/toBoolean';
import { TTranslations } from 'shared/types/common';

import styles from './TeamModal.module.css';

export type TTeamModalFields = {
  picture?: File;
  countryId: string;
  clubName: TTranslations<string>;
  name: TTranslations<string>;
  defaultTeam: boolean;
};

type TProps = {
  title: string;
  closeModal: () => void;
  actionSubmit: (data: TTeamModalFields) => void;
  isOpened: boolean;
  initialValues?: TTeamModalFields;
  isLoading?: boolean;
};

const getDefaultTeamLabels = (): Record<string, string> => ({
  true: i18n.t('content.yes'),
  false: i18n.t('content.no'),
});

export function TeamModal({
  closeModal,
  actionSubmit,
  initialValues,
  isOpened,
  title,
  isLoading,
}: TProps) {
  const { t } = useTranslation();
  const { locales } = useLocales();

  const defaultValues: TTeamModalFields = useMemo(() => {
    return {
      defaultTeam: false,
      countryId: '',
      picture: undefined,
      name: reduceTranslationObject(locales, () => ''),
      clubName: reduceTranslationObject(locales, () => ''),
    };
  }, [locales]);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<TTeamModalFields>({
    values: initialValues ?? defaultValues,
    mode: 'onChange',
    resolver: zodResolver(teamModalValidationSchema(locales)),
  });

  const submit = handleSubmit((data) => {
    actionSubmit(data);
  });

  const closeModalHandler = useCallback(() => {
    closeModal();
    reset();
  }, [isOpened]);

  return (
    <ActionModal
      contentClassName={styles.modal}
      opened={isOpened}
      title={title}
      width={542}
      confirmButtonProps={{
        isDisabled: !isValid,
        onClick: submit,
        isLoading,
      }}
      onClose={closeModalHandler}
    >
      <Text size={14} tag="h3" weight="SemiBold">
        {t('content.teams.teamName')}
      </Text>
      <div className={styles.form}>
        {locales?.map((locale) => {
          return (
            <FormInput
              key={locale}
              control={control}
              fieldName={`name.${locale}`}
              inputClassName={styles.inputForm}
              label={getLangNameMap()[locale]}
              maxLength={255}
              placeholder={t('content.inputHeading')}
              required
              size="s"
            />
          );
        })}
      </div>
      <div className={styles.inputsList}>
        <Text size={14} tag="h3" weight="SemiBold">
          {t('content.manager.clubName')}
        </Text>
        <div className={styles.form}>
          {locales?.map((locale) => {
            return (
              <FormInput
                key={locale}
                control={control}
                fieldName={`clubName.${locale}`}
                inputClassName={styles.inputForm}
                label={getLangNameMap()[locale]}
                maxLength={255}
                placeholder={t('content.inputHeading')}
                required
                size="s"
              />
            );
          })}
        </div>
        <div className={styles.formTeam}>
          <Field label={t('content.teams.defaultTeam')} required>
            <div className={styles.status}>
              {Object.keys(getDefaultTeamLabels()).map((defaultTeam) => (
                <Controller
                  key={defaultTeam}
                  control={control}
                  name="defaultTeam"
                  render={({ field }) => (
                    <RadioButton
                      checked={field.value === toBoolean(defaultTeam)}
                      label={getDefaultTeamLabels()[defaultTeam]}
                      name={field.name}
                      size="s"
                      value={defaultTeam}
                      onChange={() => {
                        field.onChange(toBoolean(defaultTeam));
                      }}
                    />
                  )}
                />
              ))}
            </div>
          </Field>
        </div>
      </div>
      <div className={styles.formTeam}>
        <CountrySelect
          className={styles.country}
          control={control}
          fieldName="countryId"
          labelClassName={styles.text}
        />
      </div>
      <div className={styles.formTeam}>
        <UploadImage
          aspectRatio={getImageSettings().team.picture.aspectRatio}
          control={control}
          fieldName="picture"
          hint={getImageSettings().team.picture.hint}
          label={t('content.teams.logo')}
          labelClassName={styles.text}
          modalTitle={t('shared.imageSetting')}
          showIcon={false}
        />
      </div>
    </ActionModal>
  );
}
