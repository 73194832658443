import { TNewsPost } from 'entities/news/types';
import i18n from 'i18next';
import { NewsPostActions } from 'pages/news/NewsPage/NewsPostActions';
import { Dispatch, SetStateAction } from 'react';
import { Badge } from 'shared/components/Badge/Badge';
import { Icon } from 'shared/components/Icon/Icon';
import { DateTimeCell } from 'shared/components/SimpleTable/components/DateTimeCell';
import { Status } from 'shared/components/Status';
import { Text } from 'shared/components/Text/Text';
import { TEntityId, TLangType } from 'shared/types/common';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from './NewsTable.module.css';

export const getNewsColumnsConfig: TConfigCreator<TNewsPost> = (
  defaultLocale: TLangType,
  activeNewsPostId: TEntityId | null,
  setActiveNewsPostId: Dispatch<SetStateAction<TEntityId | null>>,
) => [
  {
    title: i18n.t('content.public'),
    headClassName: styles.publicationNameColumn,
    render: (item) => (
      <div className={styles.publicationNameColumn}>
        {item.pictures?.base ? (
          <div className={styles.previewWrapper}>
            <img
              alt={`${item.title} preview`}
              className={styles.preview}
              src={item.pictures.base.x1}
            />
          </div>
        ) : (
          <div className={styles.previewWrapper}>
            <Icon kind="picture" size="s" />
          </div>
        )}
        <Text className={styles.title}>{item.title}</Text>
      </div>
    ),
  },
  {
    title: i18n.t('content.category'),
    render: (item) => (
      <Text className={styles.categories}>
        {item.categories?.[0]?.translations[defaultLocale]?.name ?? ''}
      </Text>
    ),
  },
  {
    title: i18n.t('content.createDate'),
    render: (item) => <DateTimeCell dateTimeString={item.createdAt ?? ''} />,
  },
  {
    title: 'ID',
    render: (item) => item.id,
  },
  {
    title: i18n.t('content.importance'),
    render: (item) =>
      item.importance > 0 ? (
        <Badge
          color="green"
          size="s"
          text={i18n.t('content.slider')}
          variant="outlined"
        />
      ) : null,
  },
  {
    title: i18n.t('content.status'),
    render: (item) => <Status status={item.status} />,
  },
  {
    title: i18n.t('content.publicDate'),
    render: (item) => <DateTimeCell dateTimeString={item?.published ?? ''} />,
  },
  {
    title: '',
    cellClassName: styles.actionsColumn,
    render: (item) => (
      <NewsPostActions
        activeNewsPostId={activeNewsPostId}
        newsPost={item}
        setActiveNewsPostId={setActiveNewsPostId}
      />
    ),
  },
];
