// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsLayout-module__root--AgJzu {\n  display: flex;\n  min-height: calc(100vh - var(--nav-header-height));\n}\n", "",{"version":3,"sources":["webpack://./src/4-widgets/settings-layout/ui/SettingsLayout/SettingsLayout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kDAAkD;AACpD","sourcesContent":[".root {\n  display: flex;\n  min-height: calc(100vh - var(--nav-header-height));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SettingsLayout-module__root--AgJzu"
};
export default ___CSS_LOADER_EXPORT___;
