// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CountryLabel-module__optionLabel--FKFh1 {\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n  gap: 4px;\n}\n\n.CountryLabel-module__countryFlag--BLE6Z {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n  aspect-ratio: 1 / 1;\n  border-radius: var(--border-radius-max);\n}\n\n.CountryLabel-module__countryFlag--BLE6Z.CountryLabel-module__s--DvkOa {\n  width: 12px;\n}\n\n.CountryLabel-module__countryFlag--BLE6Z.CountryLabel-module__m--wk6tB {\n  width: 16px;\n}\n\n.CountryLabel-module__text--r5xY9 {\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/5-feature/country-select/CountryLabel/CountryLabel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,mBAAmB;EACnB,uCAAuC;AACzC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".optionLabel {\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n  gap: 4px;\n}\n\n.countryFlag {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n  aspect-ratio: 1 / 1;\n  border-radius: var(--border-radius-max);\n}\n\n.countryFlag.s {\n  width: 12px;\n}\n\n.countryFlag.m {\n  width: 16px;\n}\n\n.text {\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionLabel": "CountryLabel-module__optionLabel--FKFh1",
	"countryFlag": "CountryLabel-module__countryFlag--BLE6Z",
	"s": "CountryLabel-module__s--DvkOa",
	"m": "CountryLabel-module__m--wk6tB",
	"text": "CountryLabel-module__text--r5xY9"
};
export default ___CSS_LOADER_EXPORT___;
