// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormInput-module__withIcon--D2pWo::-moz-placeholder {\n    padding-left: 20px;\n  }.FormInput-module__withIcon--D2pWo::placeholder {\n    padding-left: 20px;\n  }\n  .FormInput-module__withIcon--D2pWo + i {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    margin-left: 8px;\n    color: var(--gray-250);\n  }\n\n.FormInput-module__textarea--bYZoR {\n  min-height: 96px;\n  resize: vertical;\n}\n\n.FormInput-module__inputWrapper--_i0on {\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Form/Input/FormInput.module.css"],"names":[],"mappings":"AACE;IACE,kBAAkB;EACpB,CAFA;IACE,kBAAkB;EACpB;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,2BAA2B;IAC3B,gBAAgB;IAChB,sBAAsB;EACxB;;AAGF;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".withIcon {\n  &::placeholder {\n    padding-left: 20px;\n  }\n\n  & + i {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    margin-left: 8px;\n    color: var(--gray-250);\n  }\n}\n\n.textarea {\n  min-height: 96px;\n  resize: vertical;\n}\n\n.inputWrapper {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"withIcon": "FormInput-module__withIcon--D2pWo",
	"textarea": "FormInput-module__textarea--bYZoR",
	"inputWrapper": "FormInput-module__inputWrapper--_i0on"
};
export default ___CSS_LOADER_EXPORT___;
