// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthForm-module__root--Kh9rn {\n  height: 100%;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 392px;\n  text-align: center;\n}\n\n.AuthForm-module__apiSelect--UnOI7 {\n  width: 100%;\n  background-color: var(--bg-white);\n}\n\n.AuthForm-module__title--nmrku {\n  color: var(--gray-550);\n  margin-bottom: 4px;\n}\n\n.AuthForm-module__subtitle--hXrhK {\n  color: var(--gray-550);\n  margin-bottom: 20px;\n}\n\n.AuthForm-module__form--DGqK4 {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/5-feature/auth/ui/AuthForm/AuthForm.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,iCAAiC;AACnC;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".root {\n  height: 100%;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 392px;\n  text-align: center;\n}\n\n.apiSelect {\n  width: 100%;\n  background-color: var(--bg-white);\n}\n\n.title {\n  color: var(--gray-550);\n  margin-bottom: 4px;\n}\n\n.subtitle {\n  color: var(--gray-550);\n  margin-bottom: 20px;\n}\n\n.form {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "AuthForm-module__root--Kh9rn",
	"apiSelect": "AuthForm-module__apiSelect--UnOI7",
	"title": "AuthForm-module__title--nmrku",
	"subtitle": "AuthForm-module__subtitle--hXrhK",
	"form": "AuthForm-module__form--DGqK4"
};
export default ___CSS_LOADER_EXPORT___;
