import axios from 'axios';
import { useCreateVideoPostMutation } from 'entities/video/mutations';
import { notify } from 'shared/components/Notification';
import { getDefaultErrorMessage, getDefaultErrorTitle } from 'shared/validation';
import { TVideoPostFields } from 'widgets/video-form/types';

export const useCreateVideoPost = () => {
  const { mutateAsync } = useCreateVideoPostMutation();

  return async (data: TVideoPostFields) => {
    try {
      await mutateAsync(data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        notify(getDefaultErrorMessage(), {
          type: 'error',
          title: getDefaultErrorTitle(),
        });
      }
    }
  };
};
