// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavCaption-module__navButtons--RjiAh {\n  min-width: 176px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/DatePicker/components/CustomCaption/NavCaption/NavCaption.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,QAAQ;AACV","sourcesContent":[".navButtons {\n  min-width: 176px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navButtons": "NavCaption-module__navButtons--RjiAh"
};
export default ___CSS_LOADER_EXPORT___;
