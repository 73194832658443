import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './RootLayout.module.css';

type TProps = {
  navigation?: ReactElement;
};

export const RootLayout = ({ navigation }: TProps) => {
  return (
    <div className={styles.root}>
      {navigation}
      <Outlet />
    </div>
  );
};
