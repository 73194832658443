// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsPostForm-module__root--LwfS1 {\n  background-color: var(--gray-100);\n  min-height: calc(100vh - var(--nav-header-height));\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.NewsPostForm-module__editWrapper--O9og7 {\n  width: 100%;\n  max-width: 700px;\n  padding-top: var(--pad);\n  padding-bottom: var(--pad);\n}\n\n.NewsPostForm-module__content--cJP78 {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  min-height: calc(100vh - var(--nav-header-height) - var(--second-header-height));\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/NewsPostForm/NewsPostForm.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,kDAAkD;EAClD,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,gFAAgF;EAChF,kBAAkB;AACpB","sourcesContent":[".root {\n  background-color: var(--gray-100);\n  min-height: calc(100vh - var(--nav-header-height));\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.editWrapper {\n  width: 100%;\n  max-width: 700px;\n  padding-top: var(--pad);\n  padding-bottom: var(--pad);\n}\n\n.content {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  min-height: calc(100vh - var(--nav-header-height) - var(--second-header-height));\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "NewsPostForm-module__root--LwfS1",
	"editWrapper": "NewsPostForm-module__editWrapper--O9og7",
	"content": "NewsPostForm-module__content--cJP78"
};
export default ___CSS_LOADER_EXPORT___;
