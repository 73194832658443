import { getTextMaxLengthMessage } from 'pages/stories/edit/EditStory/components/forms/utils';
import { getTextMinLengthMessage, TEXT_MIN_LENGTH } from 'shared/consts';
import z from 'zod';

import { PROMOCODE_MAX_LENGTH } from './constants';

export const copyElementValidationSchema = z.object({
  title: z.string(),
  promocode: z
    .string()
    .min(TEXT_MIN_LENGTH, { message: getTextMinLengthMessage() })
    .max(PROMOCODE_MAX_LENGTH, getTextMaxLengthMessage(PROMOCODE_MAX_LENGTH)),
  size: z.string(),
  fontFamily: z.string(),
  fontStyle: z.string(),
  fill: z.string(),
  background: z.string(),
});
