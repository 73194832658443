import { ManagerCard } from 'entities/managers/ui/ManagerCard/ManagerCard';
import { DEFAULT_PAGINATION_TEAM_SIZE } from 'pages/team/constants';
import { useManagers } from 'pages/team/hooks/useManagers';
import styles from 'pages/team/team-page/TeamPage/TeamPage.module.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CardButton } from 'shared/components/CardButton/CardButton';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { routes } from 'shared/routes';

export const ManagerTab = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamId } = useParams<{
    teamId: string;
  }>();

  const { managersList, isShowPagination, isLoading, total, page, changePage } =
    useManagers(teamId);

  if (isLoading) {
    return (
      <Centered>
        <Loader showLabel />
      </Centered>
    );
  }

  return (
    <>
      <div className={styles.listWrapper}>
        <CardButton
          title={t('shared.create')}
          onClick={() => teamId && navigate(routes.createManager.as(teamId))}
        />
        {managersList.map((item) => {
          return <ManagerCard key={item.id} manager={item} />;
        })}
      </div>
      {isShowPagination && (
        <Pagination
          className={styles.pagination}
          pageSize={DEFAULT_PAGINATION_TEAM_SIZE}
          selectedPage={page}
          totalItems={total}
          onPageChange={changePage}
        />
      )}
    </>
  );
};
