import { LocalesControl } from 'shared/components/LocalesControl/LocalesControl';
import { VideoFormBackButton } from 'widgets/video-form/ui/VideoFormHeaderPanel/VideoFormBackButton/VideoFormBackButton';
import { VideoFormDraftButton } from 'widgets/video-form/ui/VideoFormHeaderPanel/VideoFormDraftButton/VideoFormDraftButton';
import { VideoFormPreviewButton } from 'widgets/video-form/ui/VideoFormHeaderPanel/VideoFormPreviewButton/VideoFormPreviewButton';
import { VideoFormPublishButton } from 'widgets/video-form/ui/VideoFormHeaderPanel/VideoFormPublish/VideoFormPublishButton';
import { VideoFormUnpublishButton } from 'widgets/video-form/ui/VideoFormHeaderPanel/VideoFormUnpublishButton/VideoFormUnpublishButton';
import { useVideoTranslation } from 'widgets/video-form/useVideoTranslation';

import styles from './VideoFormHeaderPanel.module.css';

export const VideoFormHeaderPanel = () => {
  const {
    locale: translation,
    translationList,
    handleAddLocale,
    handleSelectLocale,
    handleDeleteLocale,
  } = useVideoTranslation();

  return (
    <div className={styles.root}>
      <VideoFormBackButton />
      <LocalesControl
        createdLocales={translationList}
        selectedLocale={translation}
        onLocaleAdd={handleAddLocale}
        onLocaleDelete={handleDeleteLocale}
        onLocaleSelect={handleSelectLocale}
      />
      <div className={styles.control}>
        <VideoFormPreviewButton />
        <VideoFormDraftButton />
        <VideoFormUnpublishButton />
        <VideoFormPublishButton />
      </div>
    </div>
  );
};
