// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlayerCareersTable-module__rowGridTemplate--dizDj {\n  grid-template-columns: 22.5% 7.6% 12.8% 9.7% 11.7% 8.6% auto 3.1%;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/player/careers/PlayerCareersPage/PlayerCareersTable/PlayerCareersTable.module.css"],"names":[],"mappings":"AAAA;EACE,iEAAiE;AACnE","sourcesContent":[".rowGridTemplate {\n  grid-template-columns: 22.5% 7.6% 12.8% 9.7% 11.7% 8.6% auto 3.1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowGridTemplate": "PlayerCareersTable-module__rowGridTemplate--dizDj"
};
export default ___CSS_LOADER_EXPORT___;
