import { TSocialNetworkModalValue } from 'feature/social-network-modal/types';
import { useDeleteManagerSocialNetwork } from 'pages/manager/social-networks/ManagerSocialNetworksPage/hooks/useDeleteManagerSocialNetwork';
import { useEditManagerSocialNetwork } from 'pages/manager/social-networks/ManagerSocialNetworksPage/hooks/useEditManagerSocialNetwork';
import { TUseManagerSocialNetworksActions } from 'pages/manager/social-networks/ManagerSocialNetworksPage/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import { toString } from 'shared/lib/toString';
import { TGroupAction } from 'shared/types/common';

export const useManagerSocialNetworksActions = ({
  managerSocialNetwork,
  activeSocialLinkId,
  setActiveSocialLinkId,
}: TUseManagerSocialNetworksActions) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const [isEditModalOpen, setIsEditModalOpen] = useBoolean(false);
  const { id, managerId, socialNetwork, url } = managerSocialNetwork;

  const isActive = id === activeSocialLinkId;

  const { isDeletingManagerSocialNetwork, deleteManagerSocialNetwork } =
    useDeleteManagerSocialNetwork();
  const { isEditingManagerSocialNetwork, editManagerSocialNetwork } =
    useEditManagerSocialNetwork();

  const actions: TGroupAction[] = useMemo(
    () => [
      {
        title: t('content.edit'),
        icon: 'edit-02',
        action: () => setIsEditModalOpen(true),
      },
      {
        title: t('content.delete'),
        icon: 'trash',
        action: () => setIsDeleteModalOpen(true),
      },
    ],
    [],
  );

  const handleActionsButtonClick = () => {
    setActiveSocialLinkId(isActive ? null : id);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setActiveSocialLinkId(null);
  };

  const handleConfirmDeletion = () => {
    handleCloseDeleteModal();
    deleteManagerSocialNetwork(id);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setActiveSocialLinkId(null);
  };

  const handleConfirmEditing = ({
    url: newUrl,
    socialNetworkId: newSocialNetworkId,
  }: TSocialNetworkModalValue) => {
    editManagerSocialNetwork(
      {
        id,
        managerId,
        url: newUrl,
        socialNetworkId: newSocialNetworkId,
      },
      handleCloseEditModal,
    );
  };

  return {
    actionsProps: {
      actions,
      activeItemId: activeSocialLinkId,
      isLoading: isDeletingManagerSocialNetwork,
      isOpen: isActive,
      onIconClick: handleActionsButtonClick,
    },
    deleteModalProps: {
      onClose: handleCloseDeleteModal,
      onConfirm: handleConfirmDeletion,
      isOpened: isDeleteModalOpen,
      description: t('content.deleteWarning'),
      title: t('content.deleteSocial'),
    },
    editModalProps: {
      initialValue: {
        url,
        socialNetworkId: toString(socialNetwork.id),
      },
      title: t('content.editLink'),
      closeModal: handleCloseEditModal,
      actionSubmit: handleConfirmEditing,
      isOpened: isEditModalOpen,
      isLoading: isEditingManagerSocialNetwork,
    },
  };
};
