import alertCircle from 'shared/assets/icons/alert-circle.svg';
import alertTriangle from 'shared/assets/icons/alert-triangle.svg';
import alignCenter from 'shared/assets/icons/align-center.svg';
import alignLeft from 'shared/assets/icons/align-left.svg';
import alignRight from 'shared/assets/icons/align-right.svg';
import bold01 from 'shared/assets/icons/bold-01.svg';
import button from 'shared/assets/icons/button.svg';
import calendar from 'shared/assets/icons/calendar.svg';
import checkmark from 'shared/assets/icons/checkmark.svg';
import chevronDown from 'shared/assets/icons/chevron-down.svg';
import chevronLeft from 'shared/assets/icons/chevron-left.svg';
import chevronRight from 'shared/assets/icons/chevron-right.svg';
import chevronSelectorVertical from 'shared/assets/icons/chevron-selector-vertical.svg';
import chevronUp from 'shared/assets/icons/chevron-up.svg';
import clock from 'shared/assets/icons/clock.svg';
import copy from 'shared/assets/icons/copy.svg';
import crop01 from 'shared/assets/icons/crop-01.svg';
import dotpoints01 from 'shared/assets/icons/dotpoints-01.svg';
import dotsGrag from 'shared/assets/icons/dots-grag.svg';
import dotsHorizontal from 'shared/assets/icons/dots-horizontal.svg';
import dotsVertical from 'shared/assets/icons/dots-vertical.svg';
import dropper from 'shared/assets/icons/dropper.svg';
import edit02 from 'shared/assets/icons/edit-02.svg';
import empty from 'shared/assets/icons/empty.svg';
import eye from 'shared/assets/icons/eye.svg';
import feedback from 'shared/assets/icons/feedback.svg';
import fileDownload03 from 'shared/assets/icons/file-download-03.svg';
import filePlus from 'shared/assets/icons/file-plus.svg';
import filterLines from 'shared/assets/icons/filter-lines.svg';
import image01 from 'shared/assets/icons/image-01.svg';
import image03 from 'shared/assets/icons/image-03.svg';
import imagePlus from 'shared/assets/icons/image-plus.svg';
import info from 'shared/assets/icons/info.svg';
import italic01 from 'shared/assets/icons/italic-01.svg';
import link01 from 'shared/assets/icons/link-01.svg';
import linkBroken02 from 'shared/assets/icons/link-broken-02.svg';
import logout from 'shared/assets/icons/logout.svg';
import move from 'shared/assets/icons/move.svg';
import noEyes from 'shared/assets/icons/no-eyes.svg';
import paperclip from 'shared/assets/icons/paperclip.svg';
import picture from 'shared/assets/icons/picture.svg';
import play from 'shared/assets/icons/play.svg';
import playCircle from 'shared/assets/icons/play-circle.svg';
import plus from 'shared/assets/icons/plus.svg';
import quiz from 'shared/assets/icons/quiz.svg';
import refreshCcw01 from 'shared/assets/icons/refresh-ccw-01.svg';
import refreshCcw02 from 'shared/assets/icons/refresh-ccw-02.svg';
import refreshCw01 from 'shared/assets/icons/refresh-cw-01.svg';
import reverseCountdown from 'shared/assets/icons/reverse-countdown.svg';
import searchMd from 'shared/assets/icons/search-md.svg';
import settings01 from 'shared/assets/icons/settings-01.svg';
import settings02 from 'shared/assets/icons/settings-02.svg';
import simpleCircle from 'shared/assets/icons/simple-circle.svg';
import slidersImage from 'shared/assets/icons/sliders-image.svg';
import spinner from 'shared/assets/icons/spinner.svg';
import strikethrough01 from 'shared/assets/icons/strikethrough-01.svg';
import swipeUp from 'shared/assets/icons/swipe-up.svg';
import templates from 'shared/assets/icons/templates.svg';
import text from 'shared/assets/icons/text.svg';
import trash from 'shared/assets/icons/trash.svg';
import underline01 from 'shared/assets/icons/underline-01.svg';
import video from 'shared/assets/icons/video.svg';
import voting from 'shared/assets/icons/voting.svg';
import x from 'shared/assets/icons/x.svg';
import zoomIn from 'shared/assets/icons/zoom-in.svg';
import zoomOut from 'shared/assets/icons/zoom-out.svg';

import { IconFactory } from './IconFactory';

export const componentsMap = {
  'alert-circle': IconFactory(alertCircle),
  'alert-triangle': IconFactory(alertTriangle),
  'align-center': IconFactory(alignCenter),
  'align-left': IconFactory(alignLeft),
  'align-right': IconFactory(alignRight),
  button: IconFactory(button),
  calendar: IconFactory(calendar),
  checkmark: IconFactory(checkmark),
  'chevron-left': IconFactory(chevronLeft),
  'chevron-down': IconFactory(chevronDown),
  'chevron-right': IconFactory(chevronRight),
  'chevron-selector-vertical': IconFactory(chevronSelectorVertical),
  'chevron-up': IconFactory(chevronUp),
  clock: IconFactory(clock),
  copy: IconFactory(copy),
  'dots-grag': IconFactory(dotsGrag),
  'dots-vertical': IconFactory(dotsVertical),
  dropper: IconFactory(dropper),
  'edit-02': IconFactory(edit02),
  eye: IconFactory(eye),
  feedback: IconFactory(feedback),
  filePlus: IconFactory(filePlus),
  'filter-lines': IconFactory(filterLines),
  'image-plus': IconFactory(imagePlus),
  quiz: IconFactory(quiz),
  'refresh-ccw-01': IconFactory(refreshCcw01),
  picture: IconFactory(picture),
  plus: IconFactory(plus),
  'reverse-countdown': IconFactory(reverseCountdown),
  'search-md': IconFactory(searchMd),
  'settings-01': IconFactory(settings01),
  'settings-02': IconFactory(settings02),
  'simple-circle': IconFactory(simpleCircle),
  spinner: IconFactory(spinner),
  'swipe-up': IconFactory(swipeUp),
  templates: IconFactory(templates),
  text: IconFactory(text),
  trash: IconFactory(trash),
  voting: IconFactory(voting),
  x: IconFactory(x),
  'dotpoints-01': IconFactory(dotpoints01),
  'file-download-03': IconFactory(fileDownload03),
  paperclip: IconFactory(paperclip),
  'image-01': IconFactory(image01),
  'image-03': IconFactory(image03),
  'sliders-image': IconFactory(slidersImage),
  play: IconFactory(play),
  'play-circle': IconFactory(playCircle),
  dotsHorizontal: IconFactory(dotsHorizontal),
  logout: IconFactory(logout),
  'bold-01': IconFactory(bold01),
  'italic-01': IconFactory(italic01),
  'underline-01': IconFactory(underline01),
  'strikethrough-01': IconFactory(strikethrough01),
  'link-01': IconFactory(link01),
  'link-broken-02': IconFactory(linkBroken02),
  'no-eyes': IconFactory(noEyes),
  info: IconFactory(info),
  video: IconFactory(video),
  empty: IconFactory(empty),
  'crop-01': IconFactory(crop01),
  move: IconFactory(move),
  'refresh-cw-01': IconFactory(refreshCw01),
  'zoom-in': IconFactory(zoomIn),
  'zoom-out': IconFactory(zoomOut),
  'refresh-ccw-02': IconFactory(refreshCcw02),
} as const;
