import { queryClient } from 'app/Providers';
import { useLocales } from 'entities/config/lib/useLocales';
import { managersQueryKeys } from 'entities/managers/api/consts';
import { useEditManagerMutation } from 'entities/managers/api/mutations';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { notify } from 'shared/components/Notification';
import { toString } from 'shared/lib/toString';
import { routes } from 'shared/routes';
import { mapManagerFormFieldsToDTO } from 'widgets/manager-form/mapper/mapManagerFormFieldsToDTO';
import { TManagerFormFields } from 'widgets/manager-form/types';

export const useEditManager = () => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const navigate = useNavigate();
  const { teamId, managerId } = useParams<{
    teamId: string;
    managerId: string;
  }>();

  const { mutate, isLoading } = useEditManagerMutation({
    onSuccess: (manager) => {
      queryClient.setQueryData(
        [managersQueryKeys.manager, toString(manager.id)],
        manager,
      );

      if (teamId) {
        navigate(routes.managerSocialNetworks.as(teamId, manager.id));
      }
    },
    onError: () => {
      notify(t('content.manager.notifyNotEdit'), { type: 'error' });
    },
  });

  const editManager = async (managerFormFields: TManagerFormFields) => {
    if (managerId) {
      const managerDTO = await mapManagerFormFieldsToDTO(managerFormFields, locales);

      mutate({ ...managerDTO, managerId });
    }
  };

  return {
    isLoading,
    editManager,
  };
};
