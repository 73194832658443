import { useUserStore } from 'entities/user/model/store';
import { TUserRoles } from 'shared/types/user';

export const useRoles = () => {
  const userRoles = useUserStore((state) => state.user?.roles);

  return (roles: TUserRoles) => {
    return (
      userRoles && roles.reduce((acc, role) => acc || userRoles.has(role), false)
    );
  };
};
