import { createDefaultElement } from 'shared/components/Editor/helpers';
import {
  EditorElementTypeEnum,
  TEditorParagraphElement,
} from 'shared/components/Editor/types';
import { TLangType } from 'shared/types/common';

export const createEditorParagraphElement = (
  text: string,
  defaultLocale: TLangType,
): TEditorParagraphElement => ({
  type: EditorElementTypeEnum.Paragraph,
  translations: {
    [defaultLocale]: { text },
  },
  children: createDefaultElement(text),
});
