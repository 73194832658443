// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageInput-module__button--sVdvT {\n  color: var(--gray-400);\n  cursor: pointer;\n  display: flex;\n}\n\n.ImageInput-module__icon--ebiNe {\n  flex-shrink: 0;\n  color: var(--gray-300);\n  margin-right: 8px;\n}\n\n.ImageInput-module__withFile--UvRBx {\n  cursor: default;\n  padding: 3px;\n}\n\n.ImageInput-module__placeholder--pKO2B {\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Form/ImageInput/ImageInput.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".button {\n  color: var(--gray-400);\n  cursor: pointer;\n  display: flex;\n}\n\n.icon {\n  flex-shrink: 0;\n  color: var(--gray-300);\n  margin-right: 8px;\n}\n\n.withFile {\n  cursor: default;\n  padding: 3px;\n}\n\n.placeholder {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ImageInput-module__button--sVdvT",
	"icon": "ImageInput-module__icon--ebiNe",
	"withFile": "ImageInput-module__withFile--UvRBx",
	"placeholder": "ImageInput-module__placeholder--pKO2B"
};
export default ___CSS_LOADER_EXPORT___;
