import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { Cropper, TCropperProps } from 'shared/components/cropper/Cropper/Cropper';
import { cropperController } from 'shared/components/cropper/cropperController';
import { TCropper } from 'shared/components/cropper/types';
import { getMimeTypeFromBase64DataURI } from 'shared/lib/getMimeTypeFromBase64DataURI';

import styles from './CropperModal.module.css';

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  onCrop: (url: string) => void;
} & TCropperProps;

export const CropperModal = ({
  isOpen,
  onClose,
  onCrop,
  imageBase64Url,
  ...cropperProps
}: TProps) => {
  const { t } = useTranslation();

  const cropperRef = useRef<TCropper | null>(null);

  const getCroppedImage = () => {
    const cropper = cropperRef.current!;
    const isActiveCropping = cropperController.isActiveCropping(cropper);

    if (isActiveCropping) {
      const mimeType = getMimeTypeFromBase64DataURI(imageBase64Url);
      const croppedImage = cropperController.getCroppedUrl(cropper, mimeType);
      onCrop(croppedImage);
    } else {
      onCrop(imageBase64Url);
    }
  };

  return (
    <ActionModal
      contentClassName={styles.root}
      opened={isOpen}
      title={t('shared.imageSetting')}
      width={724}
      confirmButtonProps={{
        onClick: getCroppedImage,
      }}
      onClose={onClose}
    >
      <Cropper
        imageBase64Url={imageBase64Url}
        initCropper={(newCropper) => (cropperRef.current = newCropper)}
        mode="optional"
        {...cropperProps}
      />
    </ActionModal>
  );
};
