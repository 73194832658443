// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Brick-module__brick--S7Cjz {\n  height: 16px;\n  width: 100%;\n  display: inline-block;\n  border-radius: 6px;\n  background-color: var(--gray-150);\n  vertical-align: middle;\n  animation: Brick-module__flicker--jbuXn var(--duration-loading) ease-in-out infinite;\n}\n\n@keyframes Brick-module__flicker--jbuXn {\n  0% {\n    background-color: var(--gray-150);\n  }\n\n  50% {\n    background-color: var(--gray-100);\n  }\n\n  100% {\n    background-color: var(--gray-150);\n  }\n}\n\n.Brick-module__round--DFYs3 {\n  border-radius: var(--border-radius-max);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Skeleton/components/Brick/Brick.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,iCAAiC;EACjC,sBAAsB;EACtB,oFAA+D;AACjE;;AAEA;EACE;IACE,iCAAiC;EACnC;;EAEA;IACE,iCAAiC;EACnC;;EAEA;IACE,iCAAiC;EACnC;AACF;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".brick {\n  height: 16px;\n  width: 100%;\n  display: inline-block;\n  border-radius: 6px;\n  background-color: var(--gray-150);\n  vertical-align: middle;\n  animation: flicker var(--duration-loading) ease-in-out infinite;\n}\n\n@keyframes flicker {\n  0% {\n    background-color: var(--gray-150);\n  }\n\n  50% {\n    background-color: var(--gray-100);\n  }\n\n  100% {\n    background-color: var(--gray-150);\n  }\n}\n\n.round {\n  border-radius: var(--border-radius-max);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brick": "Brick-module__brick--S7Cjz",
	"flicker": "Brick-module__flicker--jbuXn",
	"round": "Brick-module__round--DFYs3"
};
export default ___CSS_LOADER_EXPORT___;
