import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { Text } from 'shared/components/Text/Text';
import { LocalStorageKeys } from 'shared/constants';

import styles from './LanguageSettingPage.module.css';

type TLocale = 'en' | 'ru';

export const LanguageSettingPage = () => {
  const { i18n, t } = useTranslation();
  const [_, setLocale] = useLocalStorage(LocalStorageKeys.locale);

  const onChangeLocale = (locale: TLocale) => {
    setLocale(locale);
    i18n.changeLanguage(locale);
  };

  return (
    <div className={styles.root}>
      <Text size={20} tag="h1" weight="Bold">
        {t('content.options.language')}
      </Text>
      <div className={styles.wrapper}>
        <RadioButton
          checked={i18n.language === 'ru'}
          label={t('content.onlyRussian')}
          name="language"
          value="ru"
          onChange={() => onChangeLocale('ru')}
        />
        <RadioButton
          checked={i18n.language === 'en'}
          label={t('content.onlyEnglish')}
          name="language"
          value="en"
          onChange={() => onChangeLocale('en')}
        />
      </div>
    </div>
  );
};
