import { TEntityId, TLangType } from 'shared/types/common';
import { TVideoPostFields } from 'widgets/video-form/types';

export const videoInitialValue = (
  defaultLocale: TLangType,
  id: TEntityId = '',
): TVideoPostFields => ({
  id,
  preview: {
    file: undefined,
    data: '',
  },
  labels: [],
  categories: [],
  tournament: undefined,
  players: [],
  managers: [],
  important: false,
  slider: {
    file: undefined,
    data: '',
  },
  review: false,
  locale: {
    selected: defaultLocale,
    created: [defaultLocale],
  },
  translations: {
    [defaultLocale]: { title: '' },
  },
  videoLink: '',
});
