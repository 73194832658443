import { useFetchVideoForEditQuery } from 'entities/video/queries';
import { useParams } from 'react-router-dom';
import { Loader } from 'shared/components/Loader/Loader';
import { VideoForm } from 'widgets/video-form/ui/VideoForm';

import styles from './EditVideo.module.css';

export const EditVideo = () => {
  const { videoId } = useParams<{ videoId: string }>();

  const { data, isLoading, isError, error } = useFetchVideoForEditQuery(
    videoId ?? '',
  );

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return <Loader className={styles.loading} />;
  }

  return <VideoForm values={data} />;
};
