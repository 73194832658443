import { TVideoFilter } from 'feature/filters/types';
import { TSortOptions } from 'widgets/elements-list-table/types';

export const createSortQuery = <SortBy extends string>(
  sortOptions: TSortOptions<SortBy> | undefined,
): `${SortBy}` | `-${SortBy}` | undefined => {
  if (!sortOptions) {
    return;
  }
  return `${sortOptions.direction === 'asc' ? '' : '-'}${sortOptions.sortBy}`;
};

export const createFilterQuery = (filter: TVideoFilter | null) => {
  if (!filter) {
    return;
  }
  const filterQuery: Record<string, string> = {};
  Object.entries(filter).forEach(([key, filterValue]) => {
    const isArray = Array.isArray(filterValue);
    if (isArray && filterValue.length === 0) {
      return;
    }
    if (key === 'status') {
      /* TODO: Добавить после доработки фильтрации по published на сервере */
      return;
    }
    if (isArray && filterValue.length > 0) {
      filterQuery[`filter[${key}][overlap]`] = filterValue.join(',');
      return;
    }
    if (!isArray) {
      filterQuery[`filter[${key}][eq]`] = filterValue === 'in' ? '1' : '0';
    }
  });
  return filterQuery;
};

export const calculateSelectedPage = <T>(filteredData?: T[], page = 1) => {
  if (filteredData && filteredData.length === 0) {
    return 0;
  }
  return page;
};

export const calculateTotalItems = <T>(filteredData?: T[], total = 0) => {
  if (filteredData && filteredData.length === 0) {
    return 0;
  }
  return total;
};
