import { PlayerCard } from 'entities/players/ui/PlayerCard/PlayerCard';
import { DEFAULT_PAGINATION_TEAM_SIZE } from 'pages/team/constants';
import { usePlayers } from 'pages/team/hooks/usePlayers';
import styles from 'pages/team/team-page/TeamPage/TeamPage.module.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CardButton } from 'shared/components/CardButton/CardButton';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { routes } from 'shared/routes';

export const PlayerTab = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { teamId } = useParams<{
    teamId: string;
  }>();

  const { playersList, isShowPagination, isLoading, total, page, changePage } =
    usePlayers(teamId);

  if (isLoading) {
    return (
      <Centered>
        <Loader showLabel />
      </Centered>
    );
  }

  return (
    <>
      <div className={styles.listWrapper}>
        <CardButton
          title={t('content.teams.createPlayer')}
          onClick={() => teamId && navigate(routes.createPlayer.as(teamId))}
        />
        {playersList.map((item) => {
          return <PlayerCard key={item.id} player={item} />;
        })}
      </div>
      {isShowPagination && (
        <Pagination
          className={styles.pagination}
          pageSize={DEFAULT_PAGINATION_TEAM_SIZE}
          selectedPage={page}
          totalItems={total}
          onPageChange={changePage}
        />
      )}
    </>
  );
};
