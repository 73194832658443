import { useState } from 'react';
import { TSortOptions } from 'widgets/elements-list-table/types';

export const useCommonListTableSort = <T extends string>(
  defaultSortOptions: TSortOptions<T>,
) => {
  const [sortOptions, setSortOptions] =
    useState<TSortOptions<T>>(defaultSortOptions);

  const handleSort = ({ sortBy, direction }: TSortOptions<T>) => {
    if (direction === 'desc') {
      setSortOptions({
        direction: 'desc',
        sortBy,
      });
    } else {
      setSortOptions({
        direction: 'asc',
        sortBy,
      });
    }
  };

  return {
    sortOptions,
    handleSort,
  };
};
