import { useState } from 'react';

export const useModal = <T extends string>(name: T) => {
  const [open, setOpen] = useState(false);

  return {
    [`opened${name}Modal`]: open,
    [`close${name}Modal`]: () => setOpen(false),
    [`open${name}Modal`]: () => setOpen(true),
  } as {
    [key in `opened${T}Modal`]: boolean;
  } & {
    [key in `close${T}Modal`]: () => void;
  } & {
    [key in `open${T}Modal`]: () => void;
  };
};
