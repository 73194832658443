// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManagerSocialNetworksTable-module__root--go3wS {\n  width: 100%;\n}\n\n.ManagerSocialNetworksTable-module__rowGridTemplate--ccyCA {\n  grid-template-columns: auto 36px;\n  min-height: 44px;\n  padding-left: unset;\n}\n\n.ManagerSocialNetworksTable-module__headRow--IAvgn {\n  grid-template-columns: auto 36px;\n  min-height: 36px;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/manager/social-networks/ManagerSocialNetworksPage/ManagerSocialNetworksTable/ManagerSocialNetworksTable.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,gCAAgC;EAChC,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gCAAgC;EAChC,gBAAgB;AAClB","sourcesContent":[".root {\n  width: 100%;\n}\n\n.rowGridTemplate {\n  grid-template-columns: auto 36px;\n  min-height: 44px;\n  padding-left: unset;\n}\n\n.headRow {\n  grid-template-columns: auto 36px;\n  min-height: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ManagerSocialNetworksTable-module__root--go3wS",
	"rowGridTemplate": "ManagerSocialNetworksTable-module__rowGridTemplate--ccyCA",
	"headRow": "ManagerSocialNetworksTable-module__headRow--IAvgn"
};
export default ___CSS_LOADER_EXPORT___;
