// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreviewImageButton-module__previewButton--bEWXo {\n  transition: none;\n  max-width: -moz-max-content;\n  max-width: max-content;\n}\n\n.PreviewImageButton-module__previewIconButton--p0vCr {\n  padding: 7px;\n  max-width: -moz-max-content;\n  max-width: max-content;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/PreviewImageButton/PreviewImageButton.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,2BAAsB;EAAtB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,2BAAsB;EAAtB,sBAAsB;AACxB","sourcesContent":[".previewButton {\n  transition: none;\n  max-width: max-content;\n}\n\n.previewIconButton {\n  padding: 7px;\n  max-width: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"previewButton": "PreviewImageButton-module__previewButton--bEWXo",
	"previewIconButton": "PreviewImageButton-module__previewIconButton--p0vCr"
};
export default ___CSS_LOADER_EXPORT___;
