// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EyeDropper-module__root--olU5M {\n  background: #4d45dd;\n  border: 1px solid var(--gray-200);\n  border-radius: var(--border-radius-s);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: var(--gray-50);\n  padding: 7px;\n  max-width: 100%;\n  outline: none;\n  cursor: pointer;\n  width: 28px;\n  height: 28px;\n}\n\n  .EyeDropper-module__root--olU5M:hover {\n    border-color: var(--gray-300);\n  }\n\n  .EyeDropper-module__root--olU5M:focus {\n    border-color: var(--green-400);\n  }\n\n.EyeDropper-module__icon--GXW9Z {\n  width: 12px;\n  height: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/ColorPicker/components/EyeDropper/EyeDropper.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iCAAiC;EACjC,qCAAqC;EACrC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,eAAe;EACf,WAAW;EACX,YAAY;AASd;;EAPE;IACE,6BAA6B;EAC/B;;EAEA;IACE,8BAA8B;EAChC;;AAGF;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".root {\n  background: #4d45dd;\n  border: 1px solid var(--gray-200);\n  border-radius: var(--border-radius-s);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: var(--gray-50);\n  padding: 7px;\n  max-width: 100%;\n  outline: none;\n  cursor: pointer;\n  width: 28px;\n  height: 28px;\n\n  &:hover {\n    border-color: var(--gray-300);\n  }\n\n  &:focus {\n    border-color: var(--green-400);\n  }\n}\n\n.icon {\n  width: 12px;\n  height: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "EyeDropper-module__root--olU5M",
	"icon": "EyeDropper-module__icon--GXW9Z"
};
export default ___CSS_LOADER_EXPORT___;
