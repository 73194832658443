import { getTextMaxLengthMessage } from 'feature/settings-navigation/settingNavigationLinks';
import { TSettingsNavigationLink } from 'feature/settings-navigation/types';
import { useProtectedLinks } from 'shared/hooks/useProtectedLinks';

type TUseProtectedSettingsLinksReturn = {
  links: TSettingsNavigationLink[];
};

/**
 * Хук для получения списка защищённых ссылок для навигации.
 *
 * @returns {TUseProtectedSettingsLinksReturn} Объект со списком защищённых ссылок.
 */
export const useProtectedSettingsLinks = (): TUseProtectedSettingsLinksReturn => {
  const getProtectedLinks = useProtectedLinks();

  const links = getTextMaxLengthMessage().reduce<TSettingsNavigationLink[]>(
    (acc, navigationItem) => {
      const filteredLinks = getProtectedLinks(navigationItem.subLinks);

      if (filteredLinks.length > 0) {
        acc.push({ ...navigationItem, subLinks: filteredLinks });
      }

      return acc;
    },
    [],
  );

  return {
    links,
  };
};
