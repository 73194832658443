import { useRoles } from 'entities/user/lib/useRoles';
import { TProtectedRoutes } from 'shared/types/router';

export const useProtectedRoutes = (routes: TProtectedRoutes) => {
  const canUser = useRoles();

  const filterRoutes = (routes: TProtectedRoutes): TProtectedRoutes =>
    routes.reduce<TProtectedRoutes>((acc, route) => {
      // Если для роута не прописаны роли, то считаем, что пользователь имеет к нему доступ
      // Если роли прописаны, то сверяем их с ролью, полученной от сервера.
      const hasPermission = !route.roles || (route.roles && canUser(route.roles));

      if (!hasPermission) {
        return acc;
      }

      if (route.children) {
        return [...acc, { ...route, children: filterRoutes(route.children) }];
      }
      return [...acc, route];
    }, []);

  return filterRoutes(routes);
};
