import i18n from 'i18next';
import { secureProtocol } from 'shared/constants';
import { getTextMinLengthMessage, TEXT_MIN_LENGTH } from 'shared/consts';
import { declination } from 'shared/lib/declination';
import { isValidUrl } from 'shared/lib/isValidUrl';
import { linkInWhitelist } from 'shared/lib/linkInWhitelist';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toBoolean } from 'shared/lib/toBoolean';
import { TLangType } from 'shared/types/common';
import { isSecuredLink } from 'shared/utils/links';
import z from 'zod';

export const MAX_MANAGER_FIRST_NAME_LENGTH = 255;
export const MAX_MANAGER_LAST_NAME_LENGTH = 255;
export const MAX_MANAGER_BIOGRAPHY_LENGTH = 8000;
export const MAX_MANAGER_PUPIL_LENGTH = 1000;
const MAX_LINK_LENGTH = 4096;

const getMaxLengthTextGenerate = (length: number) =>
  `${i18n.t('content.maximumLength')} ${length} ${declination(length, [i18n.t('content.symbol'), i18n.t('content.symbolDifferentEnding'), i18n.t('content.symbols')])}`;

export const managerFormValidationSchema = (
  locales: TLangType[],
  whitelistDomains: string[],
) =>
  z
    .object({
      firstName: z.object({
        ...reduceTranslationObject(locales, () =>
          z
            .string()
            .min(TEXT_MIN_LENGTH, getTextMinLengthMessage())
            .max(
              MAX_MANAGER_FIRST_NAME_LENGTH,
              getMaxLengthTextGenerate(MAX_MANAGER_FIRST_NAME_LENGTH),
            ),
        ),
      }),
      lastName: z.object({
        ...reduceTranslationObject(locales, () =>
          z
            .string()
            .min(TEXT_MIN_LENGTH, getTextMinLengthMessage())
            .max(
              MAX_MANAGER_LAST_NAME_LENGTH,
              getMaxLengthTextGenerate(MAX_MANAGER_LAST_NAME_LENGTH),
            ),
        ),
      }),
      biography: z
        .object({
          ...reduceTranslationObject(locales, () =>
            z
              .string()
              .max(
                MAX_MANAGER_BIOGRAPHY_LENGTH,
                getMaxLengthTextGenerate(MAX_MANAGER_BIOGRAPHY_LENGTH),
              ),
          ),
        })
        .optional(),
      countryId: z.string().min(TEXT_MIN_LENGTH, getTextMinLengthMessage()),
      teamId: z.string().min(TEXT_MIN_LENGTH, getTextMinLengthMessage()),
      managerPositionId: z.string().min(TEXT_MIN_LENGTH, getTextMinLengthMessage()),
      discarded: z.string(),
      discardedAt: z.any().optional(),
      shopLink: z
        .string()
        .max(MAX_LINK_LENGTH, getMaxLengthTextGenerate(MAX_LINK_LENGTH))
        .optional()
        .refine(
          (link) => (link ? isValidUrl(link) : true),
          i18n.t('content.notAvailableLink'),
        )
        .refine(
          (link) => (link ? isSecuredLink(link) : true),
          `${i18n.t('content.startLink')} ${secureProtocol}`,
        )
        .refine(
          (link) => (link ? linkInWhitelist(link, whitelistDomains) : true),
          i18n.t('content.notAvailableDomen'),
        ),
      sharingLink: z
        .string()
        .max(MAX_LINK_LENGTH, getMaxLengthTextGenerate(MAX_LINK_LENGTH))
        .optional()
        .refine(
          (link) => (link ? isValidUrl(link) : true),
          i18n.t('content.notAvailableLink'),
        )
        .refine(
          (link) => (link ? isSecuredLink(link) : true),
          `${i18n.t('content.startLink')} ${secureProtocol}`,
        )
        .refine(
          (link) => (link ? linkInWhitelist(link, whitelistDomains) : true),
          i18n.t('content.notAvailableDomen'),
        ),
    })
    .passthrough()
    .superRefine((form, context) => {
      if (form.discarded === 'true' && !toBoolean(form.discardedAt)) {
        context.addIssue({
          code: z.ZodIssueCode.too_small,
          message: getTextMinLengthMessage(),
          path: ['discardedAt'],
          type: 'date',
          minimum: TEXT_MIN_LENGTH,
          inclusive: false,
        });
      }
    });
