import { useFetchUser } from 'entities/user/model/queries';
import { useUserStore } from 'entities/user/model/store';
import { TUserRolesData } from 'entities/user/model/types';
import { useEffect } from 'react';
import { TUserRole } from 'shared/types/user';

const normalizeRoles = (roles: TUserRolesData[]): Set<TUserRole> =>
  new Set<TUserRole>(roles.map<TUserRole>(({ name }) => name));

export const useUser = () => {
  const { data: userData, isLoading } = useFetchUser();

  const { user, setUser } = useUserStore((state) => state);

  useEffect(() => {
    if (userData) {
      setUser({
        ...userData,
        roles: normalizeRoles(userData.roles),
      });
    } else {
      setUser(userData);
    }
  }, [userData]);

  return { user, isLoading };
};
