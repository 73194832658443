// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsNavigationLink-module__link--YNKWJ {\n  display: block;\n  width: 100%;\n  min-width: 212px;\n  padding: 8px 12px;\n  text-decoration: none;\n  border-radius: 8px;\n}\n\n  .SettingsNavigationLink-module__link--YNKWJ:hover {\n    background: var(--gray-100);\n  }\n\n  .SettingsNavigationLink-module__link--YNKWJ.SettingsNavigationLink-module__linkActive--jVNR6 {\n    background: var(--gray-150);\n  }\n", "",{"version":3,"sources":["webpack://./src/5-feature/settings-navigation/ui/SettingsNavigationLink/SettingsNavigationLink.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;AASpB;;EAPE;IACE,2BAA2B;EAC7B;;EAEA;IACE,2BAA2B;EAC7B","sourcesContent":[".link {\n  display: block;\n  width: 100%;\n  min-width: 212px;\n  padding: 8px 12px;\n  text-decoration: none;\n  border-radius: 8px;\n\n  &:hover {\n    background: var(--gray-100);\n  }\n\n  &.linkActive {\n    background: var(--gray-150);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "SettingsNavigationLink-module__link--YNKWJ",
	"linkActive": "SettingsNavigationLink-module__linkActive--jVNR6"
};
export default ___CSS_LOADER_EXPORT___;
