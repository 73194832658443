import { useLocales } from 'entities/config/lib/useLocales';
import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import { Divider } from 'shared/components/Divider';
import { editorWithPlugins } from 'shared/components/Editor/utils/editor/editorWithPlugins';
import { Field } from 'shared/components/Form';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { WorkspaceEditor } from 'shared/components/Workspace/components/WorkspaceEditor/WorkspaceEditor';
import { WorkspacePanel } from 'shared/components/Workspace/components/WorkspacePanel/WorkspacePanel';
import { WorkspacePreview } from 'shared/components/Workspace/components/WorkspacePreview/WorkspacePreview';
import { WorkspaceSlate } from 'shared/components/Workspace/components/WorkspaceSlate';
import { WorkspaceTitle } from 'shared/components/Workspace/components/WorkspaceTitle/WorkspaceTitle';
import { useIsDisabledStructure } from 'shared/components/Workspace/hooks';
import { WorkspaceProvider } from 'shared/components/Workspace/WorkspaceContext/WorkspaceProvider';
import { TLangType } from 'shared/types/common';

import styles from './Workspace.module.css';

export type TWorkspaceProps = {
  control: Control<TEditNewsPostFields>;
  currentLocale: TLangType;
};

export const Workspace = ({ control, currentLocale }: TWorkspaceProps) => {
  const { defaultLocale } = useLocales();
  const disabledStructureChange = useIsDisabledStructure(currentLocale);
  const editor = useMemo(
    () => editorWithPlugins(disabledStructureChange, defaultLocale),
    [disabledStructureChange],
  );
  const { fieldState } = useController({
    control,
    name: 'content.body',
  });

  return (
    <WorkspaceProvider editor={editor}>
      <div className={styles.root}>
        <WorkspaceSlate
          control={control}
          currentLocale={currentLocale}
          editor={editor}
        >
          <div className={styles.content}>
            <WorkspacePreview control={control} currentLocale={currentLocale} />
            <WorkspaceTitle control={control} currentLocale={currentLocale} />
            <Divider />
            <Field error={fieldState.error} showErrors>
              <WorkspaceEditor
                control={control}
                disabledStructureChange={disabledStructureChange}
              />
            </Field>
          </div>
          <WorkspacePanel currentLocale={currentLocale} />
        </WorkspaceSlate>
      </div>
    </WorkspaceProvider>
  );
};
