import { TEditor, TEditorVideo } from 'shared/components/Editor/types';
import { editorParagraphController } from 'shared/components/Editor/utils/paragraph/editorParagraphController';
import { createVideoElement } from 'shared/components/Editor/utils/video/createVideoElement';
import { TLangType } from 'shared/types/common';
import { Transforms } from 'slate';

export const editorVideoController = {
  insertVideo(editor: TEditor, video: TEditorVideo, defaultLocale: TLangType) {
    const videoElement = createVideoElement(video);
    Transforms.insertNodes(editor, videoElement);
    editorParagraphController.insertParagraph(editor, '', defaultLocale);
  },

  changeVideo(editor: TEditor, path: number[], file: TEditorVideo) {
    Transforms.setNodes(
      editor,
      {
        file,
      },
      { at: path },
    );
  },
};
