import { toast } from 'react-toastify';
import { TypeOptions } from 'react-toastify/dist/types';
import { TIconType } from 'shared/components/Icon/types';

import { Notification } from './Notification';

type TNotifyOptions = {
  icon?: TIconType;
  title?: string;
  type: TypeOptions;
};

export const notify = (message: string, options?: TNotifyOptions) =>
  toast(
    ({ toastProps }) => (
      <Notification
        icon={options?.icon}
        message={message}
        title={options?.title}
        {...toastProps}
      />
    ),
    {
      type: options?.type,
      closeButton: false,
      icon: false,
      hideProgressBar: true,
      position: 'bottom-right',
    },
  );
