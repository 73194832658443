import {
  TFilterComponentProps,
  TImportanceFilter,
  TVideoFilter,
} from 'feature/filters/types';
import styles from 'feature/filters/ui/Filter.module.css';
import i18n from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { RadioButton } from 'shared/components/Form/RadioButton';

type TImportanceRadioButtonIn = {
  value: 'in';
  name: 'Слайдер';
};

type TImportanceRadioButtonOut = {
  value: 'out';
  name: 'Вне слайдера';
};

type TImportanceRadioButton = [TImportanceRadioButtonIn, TImportanceRadioButtonOut];

type TProps = TFilterComponentProps<TVideoFilter>;
const getValues = (): TImportanceRadioButton => [
  { value: 'in', name: i18n.t('shared.slider') },
  { value: 'out', name: i18n.t('shared.outSlider') },
];

export const ImportanceFilter = ({ filter, setFilter }: TProps) => {
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<TImportanceFilter>('in');

  const onSubmit = () => {
    setFilter({ ...filter, importance: filterValue });
  };
  const onClick = (_name: string, value: TImportanceFilter) => {
    setFilterValue(value);
  };

  return (
    <form className={styles.container} onSubmit={onSubmit}>
      {getValues().map((radio) => (
        <RadioButton
          key={radio.value}
          checked={filterValue === radio.value}
          className={styles.radio}
          label={radio.name}
          name={radio.name}
          value={radio.value}
          onChange={onClick}
        />
      ))}
      <Button htmlType="submit" onClick={onSubmit}>
        {t('shared.confirm')}
      </Button>
    </form>
  );
};
