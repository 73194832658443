import { withCorrectVoidBehavior } from 'shared/components/Editor/plugins/editorWithCorrectVoidBehavior';
import { editorWithHeadingElement } from 'shared/components/Editor/plugins/editorWithHeadingElement';
import { editorWithImages } from 'shared/components/Editor/plugins/editorWithImages';
import { editorWithLinks } from 'shared/components/Editor/plugins/editorWithLinks';
import { editorWithBulletedListElement } from 'shared/components/Editor/plugins/editorWithListElement';
import { editorWithSplitParagraphs } from 'shared/components/Editor/plugins/editorWithSplitParagraphs';
import { TLangType } from 'shared/types/common';
import { createEditor } from 'slate';
import { withReact } from 'slate-react';

export const editorWithPlugins = (
  disabledStructureChange: boolean,
  defaultLocale: TLangType,
) => {
  return withCorrectVoidBehavior(
    editorWithSplitParagraphs(
      editorWithHeadingElement(
        editorWithBulletedListElement(
          editorWithLinks(
            editorWithImages(withReact(createEditor()), defaultLocale),
            defaultLocale,
          ),
          disabledStructureChange,
        ),
        disabledStructureChange,
      ),
    ),
    defaultLocale,
  );
};
