import { useLocales } from 'entities/config/lib/useLocales';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'shared/components/Form';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { useValidVideoLink } from 'shared/components/VideoPlayer/helpers';
import { VideoPlayer } from 'shared/components/VideoPlayer/VideoPlayer';
import { secureProtocol } from 'shared/constants';
import { onBlurVideoLinkHandler } from 'shared/handlers';
import { TVideoPostFields } from 'widgets/video-form/types';

import styles from './VideoWorkspace.module.css';

export const VideoWorkspace = () => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const { control, watch } = useFormContext<TVideoPostFields>();
  const currentLocale = watch('locale.selected');
  const videoLink = watch('videoLink');

  const { isValidLink, attachedVideoHosting } = useValidVideoLink(videoLink);

  return (
    <div className={styles.content}>
      <FormInput
        className={styles.preview}
        control={control}
        disabled={defaultLocale !== currentLocale}
        fieldName="videoLink"
        label={t('content.video.videoLink')}
        placeholder={t('shared.addLink')}
        required
        showErrors
        size="s"
        tip={t('content.video.recomendation')}
        onBlur={() => onBlurVideoLinkHandler(isValidLink, videoLink)}
      />
      <Text className={styles.hintText} size={12}>
        {t('content.video.formatVideo')} {secureProtocol}
      </Text>
      {!videoLink && (
        <div className={styles.emptyVideo}>
          <Icon className={styles.icon} kind="empty" />
        </div>
      )}
      {isValidLink && (
        <div className={styles.videoWrapper}>
          <VideoPlayer
            hosting={attachedVideoHosting ?? ''}
            src={videoLink}
            width={494}
          />
        </div>
      )}
      <FormInput
        className={styles.preview}
        control={control}
        fieldName={`translations.${currentLocale}.title`}
        label={t('content.heading')}
        maxLength={255}
        placeholder={t('content.inputHeading')}
        required
        showErrors
        size="s"
        tip={t('content.video.preview')}
      />
    </div>
  );
};
