import cn from 'classnames';
import React, { useCallback } from 'react';
import { OptionProps } from 'react-select';
import { BaseCheckbox } from 'shared/components/Form/Checkbox/BaseCheckbox';
import { useSelectContext } from 'shared/components/Form/NewSelect/hooks';
import { TOption, TSelectSize } from 'shared/components/Form/NewSelect/types';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { TCheckboxSize } from 'shared/components/Form/types';

import styles from '../NewSelect.module.css';

type TProps = OptionProps<TOption>;
const selectSizeToRadioCheckboxSizeMap: Record<TSelectSize, TCheckboxSize> = {
  small: 's',
  medium: 'm',
  large: 'm',
};

export const Option = (props: TProps) => {
  const { isSelected, isMulti, data, selectOption, clearValue } = props;
  const { size: selectSize } = useSelectContext();
  const size = selectSize ? selectSizeToRadioCheckboxSizeMap[selectSize] : undefined;
  const onSelectRadio = useCallback(
    () => (isSelected ? clearValue() : selectOption(data)),
    [isSelected, data],
  );

  if (isMulti) {
    return (
      <BaseCheckbox
        key={data.value}
        checked={isSelected}
        className={cn(styles.checkbox, data.className)}
        label={data.label}
        labelClassName={styles.checkboxLabel}
        size={size}
        onChange={() => {
          selectOption(data);
        }}
      />
    );
  }

  return (
    <RadioButton
      key={data.value}
      checked={isSelected}
      className={cn(styles.radioButton, data.className)}
      label={data.label}
      name={data.label}
      size={size}
      value={data.value}
      onClick={onSelectRadio}
    />
  );
};
