import { useLocales } from 'entities/config/lib/useLocales';
import { mapCreatePlayerCareerDTO } from 'entities/playerCareers/mapper/mapCreatePlayerCareerDTO';
import { useCreatePlayerCareerMutation } from 'entities/playerCareers/queries';
import { TPlayerCareerModalFields } from 'feature/player-career-modal/PlayerCareerModal';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'react-use';

export const useCreatePlayerCareer = () => {
  const { t } = useTranslation();
  const { locales } = useLocales();

  const [isCreateModalOpen, setIsCreateModalOpen] = useBoolean(false);
  const { playerId = '' } = useParams<{
    teamId: string;
    playerId: string;
  }>();
  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => setIsCreateModalOpen(false);

  const { mutateAsync: createCareerMutation, isLoading } =
    useCreatePlayerCareerMutation();

  const createPlayerCareerHandler = async (data: TPlayerCareerModalFields) => {
    const createPlayerCareerDTO = await mapCreatePlayerCareerDTO({
      data,
      locales,
      playerId,
    });

    await createCareerMutation(createPlayerCareerDTO);
    setIsCreateModalOpen(false);
  };

  return {
    openCreateModal,
    createCareerModalProps: {
      title: t('content.createCareer'),
      closeModal: closeCreateModal,
      actionSubmit: createPlayerCareerHandler,
      isOpened: isCreateModalOpen,
      isLoading,
    },
  };
};
