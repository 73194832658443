// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditorLinkElement-module__selected--mQC9F {\n  box-shadow: 0 0 0 1px currentColor;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Editor/elements/EditorLinkElement/EditorLinkElement.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC","sourcesContent":[".selected {\n  box-shadow: 0 0 0 1px currentColor;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected": "EditorLinkElement-module__selected--mQC9F"
};
export default ___CSS_LOADER_EXPORT___;
