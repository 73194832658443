import { useAchievementModal } from 'feature/achievement-modal/hooks';
import { TAchievementModalProps } from 'feature/achievement-modal/types';
import {
  ACHIEVEMENT_TITLE_MAX_LENGTH,
  ACHIEVEMENT_YEAR_MAX_LENGTH,
  ACHIEVEMENT_YEAR_MAX_VALUE,
  ACHIEVEMENT_YEAR_MIN_VALUE,
} from 'feature/achievement-modal/validationSchema';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { FormInput } from 'shared/components/Form';
import { Text } from 'shared/components/Text/Text';
import { getLangNameMap } from 'shared/consts';

import styles from './AchievementModal.module.css';

export const AchievementModal = (props: TAchievementModalProps) => {
  const { t } = useTranslation();
  const { locales, control, actionModalProps } = useAchievementModal(props);

  return (
    <ActionModal {...actionModalProps}>
      <div className={styles.section}>
        <Text className={styles.sectionTitle} size={14} tag="h3" weight="SemiBold">
          {t('content.player.nameAchievement')}
        </Text>
        {/* TODO: логика повторяется, вынести в компонент */}
        <div className={styles.inputWrapper}>
          {locales?.map((locale) => {
            return (
              <FormInput
                key={locale}
                control={control}
                fieldName={`translations.${locale}.title`}
                inputClassName={styles.inputForm}
                label={getLangNameMap()[locale]}
                maxLength={ACHIEVEMENT_TITLE_MAX_LENGTH}
                placeholder={t('content.inputHeading')}
                required
                size="s"
              />
            );
          })}
        </div>
      </div>
      <div className={styles.section}>
        <Text className={styles.sectionTitle} size={14} tag="h3" weight="SemiBold">
          {t('content.player.timeToGetAchievement')}
        </Text>
        <FormInput
          control={control}
          fieldName="year"
          inputClassName={styles.inputForm}
          label={t('content.player.yearAchievement')}
          max={ACHIEVEMENT_YEAR_MAX_VALUE}
          maxLength={ACHIEVEMENT_YEAR_MAX_LENGTH}
          min={ACHIEVEMENT_YEAR_MIN_VALUE}
          placeholder={t('content.player.inputToYearAchievement')}
          required
          size="s"
          type="number"
        />
      </div>
    </ActionModal>
  );
};
