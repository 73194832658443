// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlayerStyles-module__root--Lv9Qm {\n  width: 100%;\n  height: 100%;\n  border: 1px var(--gray-200) solid;\n  border-radius: var(--border-radius-l);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/VideoPlayer/PlayerStyles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iCAAiC;EACjC,qCAAqC;AACvC","sourcesContent":[".root {\n  width: 100%;\n  height: 100%;\n  border: 1px var(--gray-200) solid;\n  border-radius: var(--border-radius-l);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlayerStyles-module__root--Lv9Qm"
};
export default ___CSS_LOADER_EXPORT___;
