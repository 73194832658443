import { Badge } from 'shared/components/Badge/Badge';
import { Text } from 'shared/components/Text/Text';

import styles from '../UIKit.module.css';

export const BadgeExample = () => {
  return (
    <>
      <Text size={24} tag="h2">
        Badge
      </Text>
      <div className={styles.row}>
        <Badge color="green" size="l" text="Badge" variant="white" />
        <Badge color="green" size="l" text="Badge" variant="outlined" />
        <Badge color="green" size="l" text="Badge" variant="soft" />
        <Badge color="green" size="l" text="Badge" variant="solid" />
      </div>
      <div className={styles.row}>
        <Badge size="s" text="Badge-sm" variant="white" />
        <Badge text="Badge" variant="white" />
        <Badge size="l" text="Badge-lg" variant="white" />
      </div>
      <div className={styles.row}>
        <Badge shape="pilled" size="s" text="Badge-sm" variant="white" />
        <Badge shape="pilled" text="Badge" variant="white" />
        <Badge shape="pilled" size="l" text="Badge-lg" variant="white" />
      </div>
      <div className={styles.row}>
        <Badge
          color="green"
          shape="pilled"
          size="s"
          text="Badge-sm"
          variant="solid"
        />
        <Badge color="green" shape="pilled" text="Badge" variant="solid" />
        <Badge
          color="green"
          shape="pilled"
          size="l"
          text="Badge-lg"
          variant="solid"
        />
      </div>
      <div className={styles.row}>
        <Badge shape="rounded" size="l" text="Badge" variant="white" />
        <Badge shape="pilled" size="l" text="Badge" variant="white" />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '8px',
        }}
      >
        <div className={styles.badgerGroup}>
          <Badge
            color="dark"
            shape="rounded"
            size="m"
            text="Badge"
            variant="outlined"
          />
          <Badge
            color="gray"
            shape="rounded"
            size="m"
            text="Badge"
            variant="outlined"
          />
          <Badge
            color="red"
            shape="rounded"
            size="m"
            text="Badge"
            variant="outlined"
          />
          <Badge
            color="light"
            shape="rounded"
            size="m"
            text="Badge"
            variant="outlined"
          />
          <Badge
            color="green"
            shape="rounded"
            size="m"
            text="Badge"
            variant="outlined"
          />
        </div>
        <div className={styles.badgerGroup}>
          <Badge color="dark" shape="rounded" size="m" text="Badge" variant="soft" />
          <Badge color="gray" shape="rounded" size="m" text="Badge" variant="soft" />
          <Badge color="red" shape="rounded" size="m" text="Badge" variant="soft" />
          <Badge
            color="light"
            shape="rounded"
            size="m"
            text="Badge"
            variant="soft"
          />
          <Badge
            color="green"
            shape="rounded"
            size="m"
            text="Badge"
            variant="soft"
          />
        </div>
        <div className={styles.badgerGroup}>
          <Badge
            color="dark"
            shape="rounded"
            size="m"
            text="Badge"
            variant="solid"
          />
          <Badge
            color="gray"
            shape="rounded"
            size="m"
            text="Badge"
            variant="solid"
          />
          <Badge color="red" shape="rounded" size="m" text="Badge" variant="solid" />
          <Badge
            color="light"
            shape="rounded"
            size="m"
            text="Badge"
            variant="solid"
          />
          <Badge
            color="green"
            shape="rounded"
            size="m"
            text="Badge"
            variant="solid"
          />
        </div>
        <div className={styles.badgerGroup}>
          <Badge
            color="light"
            shape="rounded"
            size="m"
            text="Badge"
            variant="white"
          />
        </div>
      </div>
    </>
  );
};
