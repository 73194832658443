import { ManagerLayout } from 'feature/manager-layout/ui/ManagerLayout/ManagerLayout';
import { SocialNetworkModal } from 'feature/social-network-modal/ui/SocialNetworkModal/SocialNetworkModal';
import { useCreateManagerSocialNetwork } from 'pages/manager/social-networks/ManagerSocialNetworksPage/hooks/useCreateManagerSocialNetwork';
import { useManagerSocialNetworks } from 'pages/manager/social-networks/ManagerSocialNetworksPage/hooks/useManagerSocialNetworks';
import { ManagerSocialNetworksTable } from 'pages/manager/social-networks/ManagerSocialNetworksPage/ManagerSocialNetworksTable/ManagerSocialNetworksTable';
import {
  calculateSelectedPage,
  calculateTotalItems,
} from 'pages/video/VideoPage/utils';
import { useTranslation } from 'react-i18next';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

import styles from './ManagerSocialNetworksPage.module.css';

export const ManagerSocialNetworksPage = () => {
  const { t } = useTranslation();
  const {
    error,
    isLoading,
    changePage,
    socialLinks,
    page,
    total,
    handleConfirmButtonClick,
  } = useManagerSocialNetworks();
  const { openCreateModal, createSocialNetworkModalProps } =
    useCreateManagerSocialNetwork();

  return (
    <ManagerLayout
      confirmButtonProps={{
        onClick: handleConfirmButtonClick,
      }}
    >
      <CommonListWrapper
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.socialLinks')}
        createButtonProps={{
          content: t('content.addLinkOtherEnding'),
          onClick: openCreateModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(socialLinks, page),
          totalItems: calculateTotalItems(socialLinks, total),
          onPageChange: changePage,
        }}
      >
        <ManagerSocialNetworksTable socialLinks={socialLinks} />
      </CommonListWrapper>
      {createSocialNetworkModalProps.isOpened && (
        <SocialNetworkModal {...createSocialNetworkModalProps} />
      )}
    </ManagerLayout>
  );
};
