import cn from 'classnames';
import { useRightPanelMatchSelect } from 'feature/right-panel/hooks/useRightPanelMatchSelect';
import { TRightPanelMatchProps } from 'feature/right-panel/types';
import { MatchDatePicker } from 'feature/right-panel/ui/MatchDatePicker/MatchDatePicker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'shared/components/Form';
import { BaseSelect } from 'shared/components/Form/Select/BaseSelect';

import styles from './MatchSelect.module.css';

export const MatchSelect = ({ disabled, ...props }: TRightPanelMatchProps) => {
  const { t } = useTranslation();
  const { placeholder, hasMatch, openDatePicker, matchDatePickerProps } =
    useRightPanelMatchSelect(props);

  return (
    <Field disabled={disabled} label={t('content.match')}>
      <BaseSelect
        icon="calendar"
        placeholder={placeholder}
        placeholderClassName={cn({
          [styles.placeholderWithValue]: hasMatch,
        })}
        onClick={openDatePicker}
      />
      {matchDatePickerProps && <MatchDatePicker {...matchDatePickerProps} />}
    </Field>
  );
};
