import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchPlayerQuery } from 'entities/players/api/queries';
import { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mapPlayerToPlayerFormFields } from 'widgets/player-form/mapper/mapPlayerToPlayerFormFields';
import { TPlayerFormFields } from 'widgets/player-form/types/types';

export const useLoadPlayerForEdit = () => {
  const [isLoadingPrepare, setIsLoadingPrepare] = useState(true);
  const [playerFormFields, setPlayerFormFields] = useState<
    TPlayerFormFields | undefined
  >(undefined);

  const { locales, defaultLocale } = useLocales();

  const { playerId } = useParams<{
    playerId: string;
  }>();

  const { isLoading, data: player, status } = useFetchPlayerQuery(playerId);

  useLayoutEffect(() => {
    if (player && status === 'success') {
      mapPlayerToPlayerFormFields(player, locales, defaultLocale)
        .then((playerFormFields) => {
          setPlayerFormFields(playerFormFields);
        })
        .finally(() => setIsLoadingPrepare(false));
    }

    if (status === 'error') {
      setIsLoadingPrepare(false);
    }
  }, [status]);

  return {
    playerFormFields,
    isLoading: isLoading || isLoadingPrepare,
  };
};
