// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tooltip-module__content--LlcKm {\n  max-width: 300px;\n  background-color: var(--gray-450);\n  border-radius: 4px;\n  color: var(--gray-100);\n  padding: 2px 8px;\n  z-index: var(--tooltip-index);\n\n  font-size: 12px;\n  line-height: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Tooltip/Tooltip.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iCAAiC;EACjC,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB;EAChB,6BAA6B;;EAE7B,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".content {\n  max-width: 300px;\n  background-color: var(--gray-450);\n  border-radius: 4px;\n  color: var(--gray-100);\n  padding: 2px 8px;\n  z-index: var(--tooltip-index);\n\n  font-size: 12px;\n  line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Tooltip-module__content--LlcKm"
};
export default ___CSS_LOADER_EXPORT___;
