import { TFetchMatchesProps } from 'entities/matches/types';

export const createFetchMatchesFilter = (props?: TFetchMatchesProps) => {
  let params = {};

  if (props && props.tournamentId) {
    params = {
      'filter[tournament_id][eq]': props.tournamentId,
    };
  }

  if (props && props.seasonId) {
    params = {
      ...params,
      'filter[season_id][eq]': props.seasonId,
    };
  }

  return params;
};
