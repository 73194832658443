import { useFetchLabelsQuery } from 'entities/labels/queries';
import { useFetchNewsCategoriesQuery } from 'entities/newsCategories/queries';
import { useRightPanelLabel } from 'feature/right-panel/hooks/useRightPanelLabel';
import { TDefaultRightPanelProps } from 'feature/right-panel/types';
import { CommonLabel } from 'feature/right-panel/ui/CommonLabel/CommonLabel';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';

export const AddNewsPostRightPanelLabel = (commonProps: TDefaultRightPanelProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TEditNewsPostFields>();
  const { data: { data: labelsData } = {} } = useFetchLabelsQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });
  const { data: { data: categoriesData } = {} } = useFetchNewsCategoriesQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });

  const { field: selectedLabels } = useController({
    control,
    name: 'labels',
  });

  const { field: selectedCategories } = useController({
    control,
    name: 'categories',
  });

  const { labels, categories } = useRightPanelLabel<TEditNewsPostFields>({
    labels: labelsData,
    labelsFieldName: 'labels',
    categoriesFieldName: 'categories',
    categories: categoriesData,
    selectedLabelsCount: selectedLabels.value.length,
    selectedCategoriesCount: selectedCategories.value.length,
  });

  return (
    <CommonLabel
      categories={categories}
      control={control}
      labels={labels}
      description={t('shared.NewsPostForm.AddNewsPostRightPanelLabel.description', {
        mark: '.',
      })}
      {...commonProps}
    />
  );
};
