import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchVideoCategoriesQuery } from 'entities/videoCategories/queries';
import { TFilterComponentProps, TVideoFilter } from 'feature/filters/types';
import { CategoryFilter } from 'feature/filters/ui/CategoryFilter/CategoryFilter';
import { TMappedCategory } from 'feature/filters/ui/CategoryFilter/type';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';

type TProps = TFilterComponentProps<TVideoFilter>;

export const CategoryFilterWrapper = (props: TProps) => {
  const { defaultLocale } = useLocales();
  const { data: { data: categories } = {}, isLoading } =
    useFetchVideoCategoriesQuery({
      pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
    });
  const mappedCategories = categories?.map<TMappedCategory>(
    ({ id, translations }) => ({
      id,
      name: translations[defaultLocale].name,
    }),
  );
  return (
    <CategoryFilter {...props} categories={mappedCategories} isLoading={isLoading} />
  );
};
