import cn from 'classnames';
import React, { MouseEvent, useCallback, useState } from 'react';
import image03 from 'shared/assets/icons/image-03.svg';
import { CardInformation } from 'shared/components/Card/CardInformationPart/CardInformation';
import { TCardProps } from 'shared/components/Card/types';
import { IconFactory } from 'shared/components/Icon/IconFactory';
import { IconButton } from 'shared/components/IconButton/IconButton';

import styles from './Card.module.css';

const ImageNotFoundIcon = IconFactory(image03);

export const Card = ({ onEdit, onClick, onDelete, ...props }: TCardProps) => {
  const [imageError, setImageError] = useState(false);

  const onDeleteHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onDelete();
    },
    [onDelete],
  );

  const onEditHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onEdit();
    },
    [onEdit],
  );

  return (
    <div className={styles.cardWrapper} onClick={onClick}>
      <div
        className={cn(styles.imageBlock, {
          [styles.imageBlockTeam]: props.type === 'team',
        })}
      >
        {props.entity.picture && !imageError ? (
          <div
            className={cn(styles.imageContainer, {
              [styles.imageContainerTeam]: props.type === 'team',
            })}
          >
            <img
              alt={props.entity.name}
              className={styles.image}
              src={props.entity.picture}
              onError={() => setImageError(true)}
            />
          </div>
        ) : (
          <ImageNotFoundIcon className={styles.imageNotFound} />
        )}
        <IconButton
          className={cn(styles.button, styles.editButton)}
          icon="edit-02"
          iconSize="s"
          onClick={onEditHandler}
        />
        <IconButton
          className={styles.button}
          icon="trash"
          iconSize="s"
          onClick={onDeleteHandler}
        />
      </div>
      <CardInformation {...props} />
    </div>
  );
};
