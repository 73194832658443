import i18n from 'i18next';
import { toString } from 'shared/lib/toString';

const MAX_LENGTH_VAR = '%MAX_LENGTH_VAR%';
const TEXT_TYPE = '%TEXT_TYPE%';
const DEFAULT_MAX_LENGTH = 20;
const getTextMaxLengthMessageText = () =>
  i18n.t('content.story.warningLength', {
    textType: TEXT_TYPE,
    maxLength: MAX_LENGTH_VAR,
  });

type TGetTextMaxLengthTextType = 'text' | 'link' | 'title';

const getTextType = (textType: TGetTextMaxLengthTextType) => {
  switch (textType) {
    case 'text':
      return i18n.t('content.story.textWithEnding');
    case 'link':
      return i18n.t('content.story.linkWithEnding');
    case 'title':
      return i18n.t('content.story.titleWithEnding');
    default:
      return i18n.t('content.story.textWithEnding');
  }
};

export const getTextMaxLengthMessage = (
  maxLength: number | string = DEFAULT_MAX_LENGTH,
  textType: TGetTextMaxLengthTextType = 'text',
) =>
  getTextMaxLengthMessageText()
    .replace(MAX_LENGTH_VAR, toString(maxLength))
    .replace(TEXT_TYPE, getTextType(textType));
