import { prepareVideoPostDTO } from 'entities/video/mapper/prepareVideoPostDTO';
import { TEditVideoPostDTO } from 'entities/video/types';
import { TVideoPostFields } from 'widgets/video-form/types';

type TProps = {
  data: TVideoPostFields;
};

export const editVideoPostDTO = ({ data }: TProps): TEditVideoPostDTO => {
  const requestData = prepareVideoPostDTO(data);

  return {
    videoPost: {
      ...requestData.videoPost,
    },
  };
};
