import cn from 'classnames';
import { forwardRef, MouseEventHandler } from 'react';
import { Icon } from 'shared/components/Icon/Icon';
import { TIconSize, TIconType } from 'shared/components/Icon/types';
import { preventDefaultEvent } from 'shared/lib/preventDefaultEvent';

import styles from './IconButton.module.css';

export type TIconButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon: TIconType;
  className?: string;
  disabled?: boolean;
  iconSize?: TIconSize;
};

export const IconButton = forwardRef<HTMLButtonElement, TIconButtonProps>(
  ({ onClick, icon, className, disabled, iconSize = 'l' }, ref) => (
    <button
      className={cn(styles.root, className)}
      disabled={disabled}
      ref={ref}
      type="button"
      onClick={onClick}
      onMouseDown={preventDefaultEvent}
    >
      <Icon kind={icon} size={iconSize} />
    </button>
  ),
);

IconButton.displayName = 'IconButton';
