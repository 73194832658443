import { AchievementModal } from 'feature/achievement-modal/AchievementModal';
import { ManagerLayout } from 'feature/manager-layout/ui/ManagerLayout/ManagerLayout';
import { useCreateManagerTeamAchievement } from 'pages/manager/team-achievements/ManagerTeamAchievementsPage/hooks/useCreateManagerTeamAchievement';
import { useManagerTeamAchievements } from 'pages/manager/team-achievements/ManagerTeamAchievementsPage/hooks/useManagerTeamAchievements';
import { ManagerTeamAchievementsTable } from 'pages/manager/team-achievements/ManagerTeamAchievementsPage/ManagerTeamAchievementsTable/ManagerTeamAchievementsTable';
import {
  calculateSelectedPage,
  calculateTotalItems,
} from 'pages/video/VideoPage/utils';
import { useTranslation } from 'react-i18next';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

import styles from './ManagerTeamAchievementsPage.module.css';

export const ManagerTeamAchievementsPage = () => {
  const { t } = useTranslation();
  const {
    error,
    isLoading,
    changePage,
    managerTeamAchievements = [],
    page,
    total,
    handleConfirmButtonClick,
  } = useManagerTeamAchievements();
  const { createTeamAchievementModalProps, openCreateAchievementModal } =
    useCreateManagerTeamAchievement();

  return (
    <ManagerLayout
      confirmButtonProps={{
        onClick: handleConfirmButtonClick,
      }}
    >
      <CommonListWrapper
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.teamAchievements')}
        createButtonProps={{
          content: t('content.createAchievement'),
          onClick: openCreateAchievementModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(managerTeamAchievements, page),
          totalItems: calculateTotalItems(managerTeamAchievements, total),
          onPageChange: changePage,
        }}
      >
        <ManagerTeamAchievementsTable
          managerTeamAchievements={managerTeamAchievements}
        />
      </CommonListWrapper>
      {createTeamAchievementModalProps.isOpened && (
        <AchievementModal {...createTeamAchievementModalProps} />
      )}
    </ManagerLayout>
  );
};
