import { TStoriesSortParams } from 'entities/stories/queries';
import { TStoryGroupData } from 'entities/stories/types';
import { getStoriesColumnsConfig } from 'pages/stories/StoriesPage/columnsConfig';
import React from 'react';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { TSortOptions } from 'widgets/elements-list-table/types';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './StoriesPage.module.css';

type TProps = {
  storyGroups: TStoryGroupData[];
  onSort: (sortOptions: TSortOptions<TStoriesSortParams>) => void;
  sortOptions: TSortOptions<TStoriesSortParams>;
};

export const StoriesTable = ({ storyGroups, onSort, sortOptions }: TProps) => {
  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getStoriesColumnsConfig,
  });

  return (
    <CommonListTable
      activeItemId={activeItemId}
      columns={columnsConfig}
      data={storyGroups}
      rowClassName={styles.rowGridTemplate}
      sortOptions={sortOptions}
      onSort={onSort}
    />
  );
};
