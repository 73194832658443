import { useRightPanelConnections } from 'feature/right-panel/hooks/useRightPanelConnections';
import { TDefaultRightPanelProps } from 'feature/right-panel/types';
import { CommonConnections } from 'feature/right-panel/ui/CommonConnections/CommonConnections';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TVideoPostFields } from 'widgets/video-form/types';

export const VideoConnections = (commonProps: TDefaultRightPanelProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TVideoPostFields>();

  const { tournament, players, managers, season, match } =
    useRightPanelConnections<TVideoPostFields>({
      seasonFieldName: 'season',
      tournamentFieldName: 'tournament',
      playersFieldName: 'players',
      managersFieldName: 'managers',
      matchFieldName: 'match',
      control,
    });

  return (
    <CommonConnections
      control={control}
      description={t('content.video.videoConnection', { mark: '.' })}
      managers={managers}
      match={match}
      players={players}
      season={season}
      tournament={tournament}
      {...commonProps}
    />
  );
};
