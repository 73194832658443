import { useLocales } from 'entities/config/lib/useLocales';
import { useDeleteTeamMutation } from 'entities/teams/mutations';
import { TTeam } from 'entities/teams/types';
import { TeamModal } from 'feature/team-modal/TeamModal';
import { useEditTeam } from 'pages/team/TeamsPage/hooks/useEditTeam';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'shared/components/Card/Card';
import { ECardType } from 'shared/components/Card/types';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { notify } from 'shared/components/Notification';

type TProps = {
  team: TTeam;
  onClick: () => void;
};

export const TeamCard = ({ team, onClick }: TProps) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const { mutateAsync: deleteTeam, isLoading } = useDeleteTeamMutation();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const onDeleteHandler = () => {
    deleteTeam(team.id, {
      onError: () => notify(t('content.validation.notifyDeleteTeam')),
    });
  };

  const {
    editTeam,
    isLoading: isLoadingEditTeam,
    openModal,
    isOpenModal,
    closeModal,
    initialValues,
  } = useEditTeam({ team });

  return (
    <>
      <Card
        key={team.id}
        type={ECardType.Team}
        entity={{
          name: team.translations[defaultLocale].name,
          picture: team.picture.x3,
        }}
        onClick={onClick}
        onDelete={() => setOpenConfirmModal(true)}
        onEdit={openModal}
      />
      <TeamModal
        actionSubmit={editTeam}
        closeModal={closeModal}
        initialValues={initialValues}
        isLoading={isLoadingEditTeam}
        isOpened={isOpenModal}
        title={t('content.team.edit')}
      />
      <ConfirmationModal
        description={t('content.team.warningDelete', { mark: '?' })}
        isLoading={isLoading}
        isOpened={openConfirmModal}
        title={t('content.team.notifyDelete', { mark: '.' })}
        onClose={() => setOpenConfirmModal(false)}
        onConfirm={onDeleteHandler}
      />
    </>
  );
};
