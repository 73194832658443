// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WorkspacePanelAddLinkModal-module__body--KsKyv {\n  gap: 16px;\n}\n\n.WorkspacePanelAddLinkModal-module__disabled--OBXPa {\n  opacity: var(--disabled-opacity);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Workspace/components/WorkspacePanel/WorkspacePanelAddLinkModal/WorkspacePanelAddLinkModal.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".body {\n  gap: 16px;\n}\n\n.disabled {\n  opacity: var(--disabled-opacity);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "WorkspacePanelAddLinkModal-module__body--KsKyv",
	"disabled": "WorkspacePanelAddLinkModal-module__disabled--OBXPa"
};
export default ___CSS_LOADER_EXPORT___;
