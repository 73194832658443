import { TeamPage } from 'pages/team/team-page/TeamPage/TeamPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const TeamRoute: TProtectedRoute = {
  path: routes.editTeam.url,
  element: <TeamPage />,
  roles: DEFAULT_ROUTER_ROLES,
};
