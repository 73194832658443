import { useQuery } from '@tanstack/react-query';
import { fetchUser } from 'entities/user/model/api';
import { userQueryKeys } from 'entities/user/model/consts';
import { useToken } from 'shared/api/token';
import { toBoolean } from 'shared/lib/toBoolean';

export const useFetchUser = () => {
  const token = useToken();
  return useQuery({
    queryKey: [userQueryKeys.user],
    queryFn: fetchUser,
    enabled: toBoolean(token),
  });
};
