import cn from 'classnames';
import i18n from 'i18next';
import { ReactNode } from 'react';
import { Button, TButtonProps } from 'shared/components/Button/Button';
import { Loader } from 'shared/components/Loader/Loader';
import {
  Pagination,
  TPaginationProps,
} from 'shared/components/Pagination/Pagination';
import { Text } from 'shared/components/Text/Text';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'shared/consts';

import styles from './CommonListWrapper.module.css';
import { EmptyListPage } from './EmptyListPage';

type TCreateButtonProps = Omit<TButtonProps, 'children' | 'isLoading'> & {
  content: ReactNode;
};

type TProps = {
  title: string;
  createButtonProps: TCreateButtonProps;
  isLoading: boolean;
  isEmpty: boolean;
  error: unknown;
  emptyComponentDescription?: string;
  paginationProps: TPaginationProps;
  children: ReactNode;
  buttonProps?: TButtonProps;
};

export const CommonListWrapper = ({
  title,
  createButtonProps: {
    content,
    onClick: onCreateButtonClick,
    icon: createButtonIcon = 'plus',
    iconPosition: createButtonIconPosition = 'before',
    iconSize: createButtonIconSize = 'xs',
    className: createButtonClassName,
    ...restCreateButtonProps
  },
  isLoading,
  error,
  isEmpty,
  emptyComponentDescription = i18n.t('shared.Form.select.text'),
  paginationProps,
  children,
  buttonProps,
}: TProps) => {
  if (isLoading) {
    return (
      <div className={styles.root}>
        <Loader className={styles.loading} showLabel />
      </div>
    );
  }

  if (error) {
    throw error;
  }

  if (isEmpty && !isLoading) {
    return (
      <EmptyListPage
        createButtonContent={content}
        message={emptyComponentDescription}
        onCreateButtonClick={onCreateButtonClick}
      />
    );
  }

  const isShowPagination =
    paginationProps.totalItems > 0 &&
    paginationProps.totalItems >
      (paginationProps?.pageSize ?? DEFAULT_PAGINATION_PAGE_SIZE) &&
    !isLoading;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Text size={24} tag="h1" weight="Bold">
          {title}
        </Text>
        <Button
          className={cn(styles.createButton, createButtonClassName)}
          color={buttonProps?.color}
          icon={createButtonIcon}
          iconPosition={createButtonIconPosition}
          iconSize={createButtonIconSize}
          isLoading={isLoading}
          kind={buttonProps?.kind}
          onClick={onCreateButtonClick}
          {...restCreateButtonProps}
        >
          {content}
        </Button>
      </div>
      {isLoading ? <Loader className={styles.loading} showLabel /> : children}
      {isShowPagination && (
        <Pagination
          className={cn(styles.pagination, paginationProps.className)}
          {...paginationProps}
        />
      )}
    </div>
  );
};
