import { AchievementModal } from 'feature/achievement-modal/AchievementModal';
import { PlayerLayout } from 'feature/player-layout/ui/PlayerLayout/PlayerLayout';
import { useCreatePlayerAchievement } from 'pages/player/achievements/PlayerAchievementsPage/hooks/useCreatePlayerAchievement';
import { usePlayerAchievements } from 'pages/player/achievements/PlayerAchievementsPage/hooks/usePlayerAchievements';
import styles from 'pages/player/achievements/PlayerAchievementsPage/PlayerAchievementsPage.module.css';
import { PlayerAchievementsTable } from 'pages/player/achievements/PlayerAchievementsPage/PlayerAchievementsTable/PlayerAchievementsTable';
import {
  calculateSelectedPage,
  calculateTotalItems,
} from 'pages/video/VideoPage/utils';
import { useTranslation } from 'react-i18next';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

export const PlayerAchievementsPage = () => {
  const { t } = useTranslation();
  const {
    error,
    isLoading,
    changePage,
    playerAchievements,
    page,
    total,
    handleConfirmButtonClick,
  } = usePlayerAchievements();

  const { createAchievementModalProps, openCreateAchievementModal } =
    useCreatePlayerAchievement();

  return (
    <PlayerLayout
      confirmButtonProps={{
        onClick: handleConfirmButtonClick,
      }}
    >
      <CommonListWrapper
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.player.personalAchievement')}
        createButtonProps={{
          content: t('content.createAchievement'),
          onClick: openCreateAchievementModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(playerAchievements, page),
          totalItems: calculateTotalItems(playerAchievements, total),
          onPageChange: changePage,
        }}
      >
        <PlayerAchievementsTable playerAchievements={playerAchievements} />
      </CommonListWrapper>
      {createAchievementModalProps.isOpened && (
        <AchievementModal {...createAchievementModalProps} />
      )}
    </PlayerLayout>
  );
};
