// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmationModal-module__root--Ag29Z {\n  width: 380px;\n  border-color: 1px solid var(--gray-50);\n  border-radius: var(--border-radius-default);\n  padding: 24px 16px 16px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: var(--bg-white);\n}\n\n.ConfirmationModal-module__title--Xikxp {\n  color: var(--dark-blue-400);\n  margin-bottom: 8px;\n}\n\n.ConfirmationModal-module__description--n5DZH {\n  color: var(--dark-blue-350);\n  text-align: center;\n  white-space: pre-line;\n}\n\n.ConfirmationModal-module__actions--siht_ {\n  align-self: stretch;\n  margin-top: 16px;\n  display: flex;\n  flex-direction: row;\n  gap: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/ConfirmationModal/ConfirmationModal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,sCAAsC;EACtC,2CAA2C;EAC3C,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV","sourcesContent":[".root {\n  width: 380px;\n  border-color: 1px solid var(--gray-50);\n  border-radius: var(--border-radius-default);\n  padding: 24px 16px 16px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: var(--bg-white);\n}\n\n.title {\n  color: var(--dark-blue-400);\n  margin-bottom: 8px;\n}\n\n.description {\n  color: var(--dark-blue-350);\n  text-align: center;\n  white-space: pre-line;\n}\n\n.actions {\n  align-self: stretch;\n  margin-top: 16px;\n  display: flex;\n  flex-direction: row;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ConfirmationModal-module__root--Ag29Z",
	"title": "ConfirmationModal-module__title--Xikxp",
	"description": "ConfirmationModal-module__description--n5DZH",
	"actions": "ConfirmationModal-module__actions--siht_"
};
export default ___CSS_LOADER_EXPORT___;
