// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VideoFormHeaderPanel-module__root--M1wIq {\n  position: relative;\n  width: 100%;\n  display: flex;\n  background-color: var(--bg-white);\n  justify-content: space-between;\n  align-items: center;\n  height: var(--second-header-height);\n  padding: 12px 24px;\n  border-bottom: 1px solid var(--gray-200);\n}\n\n  .VideoFormHeaderPanel-module__root--M1wIq > *:nth-child(2) {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%);\n  }\n\n.VideoFormHeaderPanel-module__control--oQX4l {\n  margin-left: auto;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/4-widgets/video-form/ui/VideoFormHeaderPanel/VideoFormHeaderPanel.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,iCAAiC;EACjC,8BAA8B;EAC9B,mBAAmB;EACnB,mCAAmC;EACnC,kBAAkB;EAClB,wCAAwC;AAQ1C;;EANE;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,4CAA4C;EAC9C;;AAGF;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV","sourcesContent":[".root {\n  position: relative;\n  width: 100%;\n  display: flex;\n  background-color: var(--bg-white);\n  justify-content: space-between;\n  align-items: center;\n  height: var(--second-header-height);\n  padding: 12px 24px;\n  border-bottom: 1px solid var(--gray-200);\n\n  > *:nth-child(2) {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%);\n  }\n}\n\n.control {\n  margin-left: auto;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "VideoFormHeaderPanel-module__root--M1wIq",
	"control": "VideoFormHeaderPanel-module__control--oQX4l"
};
export default ___CSS_LOADER_EXPORT___;
