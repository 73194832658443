import { mapApiNewsPostToNewsPost } from 'entities/news/mapper/mapApiNewsPostToNewsPost';
import { TAPINewsPost } from 'entities/news/types';
import { TPaginatedResponse } from 'shared/api/pagination';
import { TLangType } from 'shared/types/common';

export const mapNewsPostsResponse = (
  response: TPaginatedResponse<TAPINewsPost>,
  defaultLocale: TLangType,
) => {
  return {
    ...response,
    data: response.data.map((apiNewsPost) =>
      mapApiNewsPostToNewsPost(apiNewsPost, defaultLocale),
    ),
  };
};
