import {
  TButtonElementSettings,
  TCopyElementSettings,
  TQuizElementSettings,
  TStoryElementSettings,
  TStoryToolKind,
  TTextElementSettings,
  TVotingElementSettings,
} from 'entities/stories/types';
import i18n from 'i18next';
import {
  fontFamilyOptions,
  fontSizeOptions,
} from 'pages/stories/edit/EditStory/components/forms/constants';
import { TStoryEditorState } from 'pages/stories/edit/EditStory/types';
import { isProduction } from 'shared/constants';
import { TLangType } from 'shared/types/common';

import { TPreviewSizeOption, TStoryTool } from './types';

const TEXT_ELEMENT_LIMIT = 5;
const DEFAULT_ELEMENT_LIMIT = 1;
const UNAVAILABLE_ELEMENT_LIMIT = 0;

export const PREVIEW_SIZES: TPreviewSizeOption[] = [
  { id: 1, width: 430, height: 932, label: 'iPhone 14 Pro Max' },
  { id: 2, width: 393, height: 852, label: 'iPhone 14 Pro' },
  { id: 3, width: 428, height: 926, label: 'iPhone 14 Plus' },
  { id: 4, width: 390, height: 844, label: 'iPhone 14' },
  { id: 5, width: 428, height: 926, label: 'iPhone 13 Pro Max' },
  { id: 6, width: 390, height: 844, label: 'iPhone 13/13 Pro' },
  { id: 7, width: 375, height: 812, label: 'iPhone 13 mini' },
  { id: 8, width: 320, height: 568, label: 'iPhone SE' },
  { id: 9, width: 375, height: 667, label: 'iPhone 8' },
  { id: 10, width: 360, height: 640, label: 'Android Small' },
  { id: 11, width: 360, height: 800, label: 'Android Large' },
];

export const getStoryTools = (): TStoryTool[] => [
  {
    kind: 'text',
    title: i18n.t('content.story.text'),
    icon: 'text',
    limit: TEXT_ELEMENT_LIMIT,
  },
  {
    kind: 'button',
    title: i18n.t('content.story.button'),
    icon: 'button',
    limit: DEFAULT_ELEMENT_LIMIT,
  },
  {
    kind: 'swipeUp',
    title: i18n.t('content.story.swipeUp'),
    icon: 'swipe-up',
    limit: UNAVAILABLE_ELEMENT_LIMIT,
  },
  {
    kind: 'quiz',
    title: i18n.t('content.story.quiz'),
    icon: 'quiz',
    limit: isProduction ? UNAVAILABLE_ELEMENT_LIMIT : DEFAULT_ELEMENT_LIMIT,
  },
  {
    kind: 'copy',
    title: i18n.t('content.story.copy'),
    icon: 'copy',
    limit: DEFAULT_ELEMENT_LIMIT,
  },
  {
    kind: 'countdown',
    title: i18n.t('content.story.countdown'),
    icon: 'reverse-countdown',
    limit: 0,
  },
  {
    kind: 'voting',
    title: i18n.t('content.story.voting'),
    icon: 'voting',
    limit: isProduction ? UNAVAILABLE_ELEMENT_LIMIT : DEFAULT_ELEMENT_LIMIT,
  },
  {
    kind: 'feedback',
    title: i18n.t('content.story.feedback'),
    icon: 'feedback',
    limit: UNAVAILABLE_ELEMENT_LIMIT,
  },
];

export const getStoryTitlesByKind = () => ({
  text: i18n.t('content.story.text'),
  button: i18n.t('content.story.button'),
  swipeUp: i18n.t('content.story.swipeUp'),
  quiz: i18n.t('content.story.quiz'),
  copy: i18n.t('content.story.copy'),
  countdown: i18n.t('content.story.countdown'),
  voting: i18n.t('content.story.voting'),
  feedback: i18n.t('content.story.feedback'),
});

export const getDefaultTextElementSettings = (): TTextElementSettings => ({
  text: i18n.t('content.story.defaultText'),
  fontFamily: fontFamilyOptions[0].value as string,
  fill: '#000000',
  background: '#ffffff',
  textAlign: 'left',
  fontSize: fontSizeOptions[0].value as number,
  fontStyle: 'Regular',
  width: 200,
});

export const DEFAULT_BUTTON_ELEMENT_SETTINGS: TButtonElementSettings = {
  title: '',
  fontFamily: fontFamilyOptions[0].value as string,
  fill: '#000000',
  size: 'Medium',
  background: '#ffffff',
  fontStyle: 'Regular',
  link: 'https://google.com',
};

export const getDefaultCopyElementSettings = (): TCopyElementSettings => ({
  fill: '#000000',
  background: '#ffffff',
  fontFamily: fontFamilyOptions[0].value as string,
  fontStyle: 'Regular',
  size: 'Medium',
  promocode: i18n.t('content.story.defaultPromoCode'),
  title: '',
});

export const DEFAULT_VOTING_ELEMENT_SETTINGS: TVotingElementSettings = {
  fill: '#000000',
  background: '#ffffff',
  fontFamily: fontFamilyOptions[0].value as string,
  fontStyle: 'Regular',
  title: '',
};

export const getDefaultQuizElementSettings = (): TQuizElementSettings => ({
  title: '',
  fill: '#ffffff',
  background: '#005C95',
  titleDisabled: false,
  correctAnswerIndex: 0,
  answers: [
    `${i18n.t('content.story.answer')} 1`,
    `${i18n.t('content.story.answer')} 2`,
  ],
});

export const defaultSettingsByKind: Record<string, TStoryElementSettings> = {
  text: getDefaultTextElementSettings(),
  button: DEFAULT_BUTTON_ELEMENT_SETTINGS,
  copy: getDefaultCopyElementSettings(),
  quiz: getDefaultQuizElementSettings(),
  voting: DEFAULT_VOTING_ELEMENT_SETTINGS,
};

export const defaultElementWidthByKind: {
  [T in TStoryToolKind]?: number | 'auto';
} = {
  text: 200,
  button: 'auto',
  quiz: 280,
  copy: 'auto',
  voting: 230,
};

export const storyEditorInitialState = (locale: TLangType): TStoryEditorState => ({
  highlightedElementId: null,
  selectedLocale: locale,
  selectedStory: null,
  selectedPreviewSize: null,
  selectedElement: null,
  elementsWithErrors: null,
});
