// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WorkspacePanel-module__root--BZ9rZ {\n  padding: 16px;\n  height: var(--workspace-bottom-panel-height);\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-top: 1px solid var(--gray-200);\n}\n\n.WorkspacePanel-module__actions--Tyfe7 {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.WorkspacePanel-module__divider--UpoQn {\n  height: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Workspace/components/WorkspacePanel/WorkspacePanel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4CAA4C;EAC5C,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,YAAY;AACd","sourcesContent":[".root {\n  padding: 16px;\n  height: var(--workspace-bottom-panel-height);\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-top: 1px solid var(--gray-200);\n}\n\n.actions {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.divider {\n  height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "WorkspacePanel-module__root--BZ9rZ",
	"actions": "WorkspacePanel-module__actions--Tyfe7",
	"divider": "WorkspacePanel-module__divider--UpoQn"
};
export default ___CSS_LOADER_EXPORT___;
