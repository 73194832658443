import { ManagerLayout } from 'feature/manager-layout/ui/ManagerLayout/ManagerLayout';
import { useCreateManager } from 'pages/manager/create/CreateManagerPage/useCreateManager';
import { ManagerForm } from 'widgets/manager-form/ui/ManagerForm/ManagerForm';
import { useManagerForm } from 'widgets/manager-form/ui/ManagerForm/useManagerForm';

export const CreateManagerPage = () => {
  const { createManager, isLoading } = useCreateManager();
  const managerForm = useManagerForm();

  const createManagerHandler = () => {
    createManager(managerForm.getValues());
  };

  return (
    <ManagerLayout
      confirmButtonProps={{
        isDisabled: !managerForm.formState.isValid,
        onClick: createManagerHandler,
        isLoading,
      }}
    >
      <ManagerForm control={managerForm.control} />
    </ManagerLayout>
  );
};
