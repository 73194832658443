import cn from 'classnames';
import React, { CSSProperties } from 'react';
import { useDropzone } from 'shared/hooks/useDropzone';
import { TDragItemType } from 'shared/types/common';

import styles from './Dropzone.module.css';

type TProps = {
  dragItemType: TDragItemType;
  className: string;
  style?: CSSProperties | undefined;
};

export const Dropzone = ({ dragItemType, className, style }: TProps) => {
  const [isDropzoneHover, dropzoneDrop] = useDropzone(dragItemType);

  return (
    <div
      ref={dropzoneDrop}
      style={style}
      className={cn(styles.dropZone, className, {
        [styles.dropZoneHover]: isDropzoneHover,
      })}
    />
  );
};
