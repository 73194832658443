// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StoryWrapper-module__dropZone--TS6pu {\n  height: 132px;\n  width: 94px;\n  border-radius: var(--border-radius-default);\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/stories/edit/EditStory/components/StoryWrapper/StoryWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,2CAA2C;AAC7C","sourcesContent":[".dropZone {\n  height: 132px;\n  width: 94px;\n  border-radius: var(--border-radius-default);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropZone": "StoryWrapper-module__dropZone--TS6pu"
};
export default ___CSS_LOADER_EXPORT___;
