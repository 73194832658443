// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dropzone-module__dropZone--yoIVJ {\n  border: 1px dashed;\n  border-color: var(--gray-300);\n}\n\n.Dropzone-module__dropZoneHover--AGFwz {\n  border-color: var(--green-400);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Dropzone/Dropzone.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".dropZone {\n  border: 1px dashed;\n  border-color: var(--gray-300);\n}\n\n.dropZoneHover {\n  border-color: var(--green-400);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropZone": "Dropzone-module__dropZone--yoIVJ",
	"dropZoneHover": "Dropzone-module__dropZoneHover--AGFwz"
};
export default ___CSS_LOADER_EXPORT___;
