import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchOpponentTeam, fetchOpponentTeams } from 'entities/opponentTeams/api';
import { opponentTeamsQueryKeys } from 'entities/opponentTeams/consts';
import { TGetOpponentTeam, TOpponentTeam } from 'entities/opponentTeams/types';
import { TPaginatedResponse } from 'shared/api/pagination';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';
import { toBoolean } from 'shared/lib/toBoolean';

export const useFetchOpponentTeamQuery = (params: TGetOpponentTeam) =>
  useQuery({
    queryKey: [opponentTeamsQueryKeys.opponentTeam, params.id],
    queryFn: () => fetchOpponentTeam(params),
    enabled: toBoolean(params.id),
  });

export const useFetchOpponentTeamsQuery = (
  defaultPagination: TPaginatedParameters,
  options: UseQueryOptions<TPaginatedResponse<TOpponentTeam>> = {},
) =>
  usePaginationQuery(
    (pagination) => ({
      queryKey: [opponentTeamsQueryKeys.opponentTeams, pagination],
      queryFn: () => fetchOpponentTeams({ params: pagination }),
      ...options,
    }),
    defaultPagination,
  );
