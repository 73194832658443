// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LanguageSettingPage-module__root--NWCeq {\n  padding: 24px;\n  height: 100%;\n  background-color: var(--bg-white);\n  display: flex;\n  flex-direction: column;\n}\n\n.LanguageSettingPage-module__wrapper--J9yH_ {\n  margin-top: 16px;\n  display: flex;\n  gap: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/settings/language/LanguageSettingPage/LanguageSettingPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,iCAAiC;EACjC,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,SAAS;AACX","sourcesContent":[".root {\n  padding: 24px;\n  height: 100%;\n  background-color: var(--bg-white);\n  display: flex;\n  flex-direction: column;\n}\n\n.wrapper {\n  margin-top: 16px;\n  display: flex;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LanguageSettingPage-module__root--NWCeq",
	"wrapper": "LanguageSettingPage-module__wrapper--J9yH_"
};
export default ___CSS_LOADER_EXPORT___;
