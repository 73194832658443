import { playersApiKeys } from 'entities/players/api/consts';
import {
  TCreatePlayerDTO,
  TEditPlayerDTO,
  TPlayer,
} from 'entities/players/model/types';
import { del, get, patch, post } from 'shared/api/axios';
import { mapPaginatedResponse } from 'shared/api/pagination';
import { TFetchParams } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchPlayers = ({ params }: TFetchParams) =>
  get<TPlayer[], true>(playersApiKeys.getPlayers, {
    params,
  }).then((response) => mapPaginatedResponse(response.data));

export const fetchPlayer = (id: TEntityId) =>
  get<TPlayer>(playersApiKeys.getPlayer(id)).then((response) => response.data.data);

export const createPlayer = (createPlayerDTO: TCreatePlayerDTO) =>
  post<TPlayer>(playersApiKeys.createPlayer, createPlayerDTO).then(
    (response) => response.data.data,
  );

export const editPlayer = ({ playerId, ...editPlayerDTO }: TEditPlayerDTO) =>
  patch<TPlayer>(playersApiKeys.editPlayer(playerId), editPlayerDTO).then(
    (response) => response.data.data,
  );

export const deletePlayer = (playerId: TEntityId) =>
  del(playersApiKeys.deletePlayer(playerId));
