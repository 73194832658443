import { useLocales } from 'entities/config/lib/useLocales';
import { useMemo } from 'react';
import { FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TCommonOptionType, TSelectFieldProps } from '../types';
import { generateOptionsArray, generatePlaceholder } from '../utils';

type TUseRightPanelLabel<T extends FieldValues> = {
  labels?: TCommonOptionType[];
  categories?: TCommonOptionType[];
  labelsFieldName: Path<T>;
  categoriesFieldName: Path<T>;
  selectedLabelsCount?: number;
  selectedCategoriesCount?: number;
};

type TUseRightPanelLabelReturn<T extends FieldValues> = {
  labels: TSelectFieldProps<T>;
  categories: TSelectFieldProps<T>;
};

export const useRightPanelLabel = <T extends FieldValues>({
  labels,
  categories,
  labelsFieldName,
  categoriesFieldName,
  selectedLabelsCount,
  selectedCategoriesCount,
}: TUseRightPanelLabel<T>): TUseRightPanelLabelReturn<T> => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();

  const labelsForSelect = useMemo(
    () => generateOptionsArray(defaultLocale, labels),
    [labels],
  );

  const categoriesForSelect = useMemo(
    () => generateOptionsArray(defaultLocale, categories),
    [categories],
  );

  const labelsPlaceholder = generatePlaceholder({
    count: selectedLabelsCount,
    singular: t('content.label'),
    few: t('content.labels'),
    many: t('content.labelWithOtherEnding'),
    defaultPlaceholder: t('content.chooseLabelFromList'),
  });

  const categoriesPlaceholder = generatePlaceholder({
    count: selectedCategoriesCount,
    singular: t('content.categoryWithSmallLetter'),
    few: t('content.categories'),
    many: t('content.categoryWithOtherEnding'),
    defaultPlaceholder: t('content.chooseCategoryFromList'),
  });

  return {
    labels: {
      data: labelsForSelect,
      fieldName: labelsFieldName,
      placeholder: labelsPlaceholder,
    },
    categories: {
      data: categoriesForSelect,
      fieldName: categoriesFieldName,
      placeholder: categoriesPlaceholder,
    },
  };
};
