import { TSettingsNavigationProps } from 'feature/settings-navigation/types';
import { SettingsNavigationItem } from 'feature/settings-navigation/ui/SettingsNavigationItem/SettingsNavigationItem';
import { SettingsNavigationLink } from 'feature/settings-navigation/ui/SettingsNavigationLink/SettingsNavigationLink';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components/Text/Text';
import { routes } from 'shared/routes';

import styles from './SettingsNavigation.module.css';

export const SettingsNavigation = ({ links }: TSettingsNavigationProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Text size={20} tag="h2" weight="Bold">
        {t('content.settings.general')}
      </Text>
      <SettingsNavigationLink
        key={'language'}
        className={styles.link}
        link={{
          name: t('content.options.language'),
          route: routes.settingsLanguages,
        }}
      />
      <Text size={20} tag="h2" weight="Bold">
        {t('content.options')}
      </Text>
      {links.map((navigationItem) => (
        <SettingsNavigationItem
          key={navigationItem.name}
          navigationItem={navigationItem}
        />
      ))}
    </div>
  );
};
