// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlayerCareerModal-module__form--KC6Qn {\n  margin-top: 8px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.PlayerCareerModal-module__modal--Nx04R {\n  height: 449px;\n  overflow-y: scroll;\n  margin-right: 3px;\n}\n\n.PlayerCareerModal-module__modal--Nx04R::-webkit-scrollbar-track {\n    margin: 4px;\n  }\n\n.PlayerCareerModal-module__inputForm--OwZ3D::-moz-placeholder {\n    color: var(--gray-500);\n  }\n\n.PlayerCareerModal-module__inputForm--OwZ3D::placeholder {\n    color: var(--gray-500);\n  }\n", "",{"version":3,"sources":["webpack://./src/5-feature/player-career-modal/PlayerCareerModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;AAKnB;;AAHE;IACE,WAAW;EACb;;AAIA;IACE,sBAAsB;EACxB;;AAFA;IACE,sBAAsB;EACxB","sourcesContent":[".form {\n  margin-top: 8px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.modal {\n  height: 449px;\n  overflow-y: scroll;\n  margin-right: 3px;\n\n  &::-webkit-scrollbar-track {\n    margin: 4px;\n  }\n}\n\n.inputForm {\n  &::placeholder {\n    color: var(--gray-500);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "PlayerCareerModal-module__form--KC6Qn",
	"modal": "PlayerCareerModal-module__modal--Nx04R",
	"inputForm": "PlayerCareerModal-module__inputForm--OwZ3D"
};
export default ___CSS_LOADER_EXPORT___;
