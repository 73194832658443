import { TSettingsNavigationLink } from 'feature/settings-navigation/types';
import { SettingsNavigationLink } from 'feature/settings-navigation/ui/SettingsNavigationLink/SettingsNavigationLink';
import { Text } from 'shared/components/Text/Text';

import styles from './SettingsNavigationItem.module.css';

export type TProps = {
  navigationItem: TSettingsNavigationLink;
};

export const SettingsNavigationItem = ({ navigationItem }: TProps) => (
  <div className={styles.wrapper}>
    <Text className={styles.categoryTitle} size={12} tag="p">
      {navigationItem.name}
    </Text>

    {navigationItem.subLinks.map((link) => (
      <SettingsNavigationLink key={link.name} className={styles.link} link={link} />
    ))}
  </div>
);
