// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SaveStoryModal-module__mainContent--gv5v0 {\n  padding: 24px;\n  gap: 16px;\n}\n\n.SaveStoryModal-module__info--WvISA {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.SaveStoryModal-module__idInfo--xUAxJ {\n  gap: 8px;\n}\n\n.SaveStoryModal-module__copyId--sYtyo {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.SaveStoryModal-module__copyId--sYtyo span {\n    margin-left: 4px;\n  }\n\n.SaveStoryModal-module__alertMessage--QMQIB {\n  display: flex;\n  align-items: center;\n  margin-top: 4px;\n}\n\n.SaveStoryModal-module__alertMessage--QMQIB > i {\n    color: var(--status-error);\n  }\n\n.SaveStoryModal-module__alertMessage--QMQIB > span {\n    margin-left: 4px;\n  }\n\n.SaveStoryModal-module__textMuted--YLQ0d {\n  color: var(--gray-400);\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/stories/edit/EditStory/components/SaveStoryModal/SaveStoryModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AAKrB;;AAHE;IACE,gBAAgB;EAClB;;AAGF;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AASjB;;AAPE;IACE,0BAA0B;EAC5B;;AAEA;IACE,gBAAgB;EAClB;;AAGF;EACE,sBAAsB;AACxB","sourcesContent":[".mainContent {\n  padding: 24px;\n  gap: 16px;\n}\n\n.info {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.idInfo {\n  gap: 8px;\n}\n\n.copyId {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n\n  span {\n    margin-left: 4px;\n  }\n}\n\n.alertMessage {\n  display: flex;\n  align-items: center;\n  margin-top: 4px;\n\n  > i {\n    color: var(--status-error);\n  }\n\n  > span {\n    margin-left: 4px;\n  }\n}\n\n.textMuted {\n  color: var(--gray-400);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContent": "SaveStoryModal-module__mainContent--gv5v0",
	"info": "SaveStoryModal-module__info--WvISA",
	"idInfo": "SaveStoryModal-module__idInfo--xUAxJ",
	"copyId": "SaveStoryModal-module__copyId--sYtyo",
	"alertMessage": "SaveStoryModal-module__alertMessage--QMQIB",
	"textMuted": "SaveStoryModal-module__textMuted--YLQ0d"
};
export default ___CSS_LOADER_EXPORT___;
