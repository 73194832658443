import { AchievementModal } from 'feature/achievement-modal/AchievementModal';
import { useManagerTeamAchievementsActions } from 'pages/manager/team-achievements/ManagerTeamAchievementsPage/hooks/useManagerTeamAchievementsActions';
import { TManagerTeamAchievementsActions } from 'pages/manager/team-achievements/ManagerTeamAchievementsPage/types';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';

export const ManagerTeamAchievementsActions = (
  props: TManagerTeamAchievementsActions,
) => {
  const { actionsProps, deleteModalProps, editModalProps } =
    useManagerTeamAchievementsActions(props);

  return (
    <>
      <Actions {...actionsProps} />
      {deleteModalProps.isOpened && <ConfirmationModal {...deleteModalProps} />}
      {editModalProps.isOpened && <AchievementModal {...editModalProps} />}
    </>
  );
};
