import { useFetchPlayerTeamAchievementsQuery } from 'entities/playerTeamAchievements/queries';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'shared/routes';

export const usePlayerTeamAchievements = () => {
  const navigate = useNavigate();
  const { teamId = '', playerId = '' } = useParams<{
    teamId: string;
    playerId: string;
  }>();

  const {
    data: { data: playerTeamAchievements = [], page, total } = {},
    isLoading: isPlayerTeamAchievementsLoading,
    error: playerTeamAchievementsError,
    changePage,
  } = useFetchPlayerTeamAchievementsQuery({
    filter: playerId ? { 'filter[player_id][eq]': playerId } : {},
  });

  const handleConfirmButtonClick = () => {
    navigate(routes.playerAchievements.as(teamId, playerId), {
      replace: true,
    });
  };

  return {
    total,
    page,
    playerTeamAchievements,
    isLoading: isPlayerTeamAchievementsLoading,
    error: playerTeamAchievementsError,
    handleConfirmButtonClick,
    changePage,
  };
};
