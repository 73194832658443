import { ReactNode } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { TIconType } from 'shared/components/Icon/types';
import { DNDItemTypes } from 'shared/constants';

export enum Languages {
  ru = 'ru',
  en = 'en',
  de = 'de',
  fr = 'fr',
}

export type TLangType = keyof typeof Languages;
export type TEntityId = string | number;

export type TSimpleEntity<T> = T & {
  id: string;
  name: string;
};

export enum UIKitSizes {
  s = 's',
  m = 'm',
  l = 'l',
}

export type TTranslations<T> = Record<TLangType, T>;
export type TPartialTranslations<T> = TPartialRecord<TLangType, T>;

export type TTranslationsObject<T> = {
  translations: TTranslations<T>;
};
export type TPartialTranslationsObject<T> = {
  translations: TPartialTranslations<T>;
};

export type TGroupAction = {
  title: string;
  icon: TIconType;
  action: (id: TEntityId) => void;
};

export type TTranslationsMapper = Record<TLangType, string>;

export type TImageContent = {
  x1: string;
  x2: string;
  x3: string;
};

export type TWithChildren = {
  children: ReactNode;
};

export type TWithOptionalChildren = Partial<TWithChildren>;

export type TWithClassName = {
  className: string;
};

export type TWithOptionalClassName = Partial<TWithClassName>;

export type TImageUpload = {
  filename: string;
  data: string;
};

export type TMetaType<Page extends boolean = false> = {
  page: Page extends true ? TMetaPageType : undefined;
};

export type TMetaPageType = {
  number: number;
  size: number;
  offset: number;
  total_entries: number;
  total_pages: number;
};

export type TContentStatus =
  | 'published'
  | 'postponed'
  | 'completed'
  | 'error'
  | 'draft';

export type TPartialRecord<K extends string, T> = {
  [P in K]?: T;
};

export type TDragItemType = (typeof DNDItemTypes)[keyof typeof DNDItemTypes];

export type TDefaultActions = 'edit' | 'delete';

export type TPostFormFile = {
  file: File | undefined;
  data: string;
};

export enum EListStatus {
  Published = 'published',
  Draft = 'draft',
  Postponed = 'postponed',
}

export type TActionByStatus = {
  title: string;
  icon: TIconType;
  action: (value: TEntityId, navigateFunc?: NavigateFunction) => void;
};
