import { useLocalesControl } from 'pages/stories/edit/EditStory/components/StoryHeaderPanel/hooks';
import React from 'react';
import { LocalesControl } from 'shared/components/LocalesControl/LocalesControl';
import { TLangType } from 'shared/types/common';

type TProps = {
  selectedLocale: TLangType;
};

export const StoryHeaderLocalesControl = ({ selectedLocale }: TProps) => {
  const { createdLocales, onLocaleDelete, onLocaleSelect, onLocaleAdd } =
    useLocalesControl();
  return (
    <LocalesControl
      createdLocales={createdLocales}
      selectedLocale={selectedLocale}
      onLocaleAdd={onLocaleAdd}
      onLocaleDelete={onLocaleDelete}
      onLocaleSelect={onLocaleSelect}
    />
  );
};
