import { useProtectedRoutes } from 'app/hooks/useProtectedRoutes';
import { useSession } from 'feature/auth/model/useSession';
import { AuthRoutes } from 'pages/auth';
import { DashboardRoute } from 'pages/dashboard';
import { ErrorPage } from 'pages/error-page/ErrorPage';
import { ManagerRoutes } from 'pages/manager';
import { NewsRoutes } from 'pages/news';
import { PlayerRoutes } from 'pages/player';
import { SettingsRoute } from 'pages/settings';
import { StoriesRoutes } from 'pages/stories';
import { TeamsRoutes } from 'pages/team';
import { UIKitRoute } from 'pages/uikit';
import { VideoRoutes } from 'pages/video';
import { useMemo } from 'react';
import { RouteObject, RouterProvider } from 'react-router-dom';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { isProduction } from 'shared/constants';
import { useDefaultLocales } from 'shared/hooks/useDefaultLocale';
import { sentryCreateBrowserRouter } from 'shared/sentry';
import { TProtectedRoutes } from 'shared/types/router';
import { RootLayout } from 'shared/ui/layouts/root-layout/RootLayout';
import { Navigation } from 'widgets/navigation/ui/Navigation/Navigation';

const devRoutes = isProduction ? [] : [UIKitRoute];

const permissionRoutes: TProtectedRoutes = [
  DashboardRoute,
  StoriesRoutes.List,
  StoriesRoutes.Edit,
  NewsRoutes.List,
  NewsRoutes.Add,
  NewsRoutes.Edit,
  VideoRoutes.List,
  VideoRoutes.Add,
  VideoRoutes.Edit,
  PlayerRoutes.Create,
  PlayerRoutes.Edit,
  PlayerRoutes.Careers,
  PlayerRoutes.SocialNetworks,
  PlayerRoutes.Achievements,
  PlayerRoutes.TeamAchievements,
  ManagerRoutes.Create,
  ManagerRoutes.Edit,
  ManagerRoutes.Careers,
  ManagerRoutes.SocialNetworks,
  ManagerRoutes.TeamAchievements,
  SettingsRoute,
  TeamsRoutes.List,
  TeamsRoutes.TeamPage,
  AuthRoutes.Redirect,
];

const privateRoutesObject: RouteObject = {
  path: '/',
  element: <RootLayout navigation={<Navigation />} />,
  errorElement: <ErrorPage />,
};

const authRoutesObject = AuthRoutes.Routes;

export const Router = () => {
  const { isAuthorized, isLoading } = useSession();
  const protectedRoutes = useProtectedRoutes(permissionRoutes);
  const privateRoutes = {
    ...privateRoutesObject,
    children: [...devRoutes, ...protectedRoutes],
  };
  const totalRoutes = isAuthorized ? privateRoutes : authRoutesObject;
  const router = useMemo(
    () => sentryCreateBrowserRouter([totalRoutes]),
    [isAuthorized],
  );

  useDefaultLocales(isAuthorized);

  return isLoading ? (
    <Centered>
      <Loader size="l" />
    </Centered>
  ) : (
    <RouterProvider router={router} />
  );
};
