import { useForm } from 'react-hook-form';
import { ModeSelectField } from 'shared/components/Form/ModeSelect';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';

const testOptions = [
  { value: 'value1', label: <Icon kind="feedback" size="s" /> },
  { value: 'value2', label: 'Label 2' },
];

export const ModeSelectExample = () => {
  const { control } = useForm({
    defaultValues: { modeSelect: 'value1' },
  });

  return (
    <div>
      <Text size={24} tag="h2">
        Mode Select
      </Text>
      <ModeSelectField
        control={control}
        fieldName="modeSelect"
        options={testOptions}
      />
    </div>
  );
};
