// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SimpleTable-module__container--uxgiu {\n  height: inherit;\n  white-space: nowrap;\n  max-width: 100%;\n}\n\n.SimpleTable-module__table--IWEms {\n  height: inherit;\n  display: block;\n  font-size: 12px;\n  line-height: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/SimpleTable/SimpleTable.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".container {\n  height: inherit;\n  white-space: nowrap;\n  max-width: 100%;\n}\n\n.table {\n  height: inherit;\n  display: block;\n  font-size: 12px;\n  line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SimpleTable-module__container--uxgiu",
	"table": "SimpleTable-module__table--IWEms"
};
export default ___CSS_LOADER_EXPORT___;
