import { useFetchPlayerQuery } from 'entities/players/api/queries';
import { useFetchTeamQuery } from 'entities/teams/queries';
import { getPlayerNavigationLinks } from 'feature/player-layout/model/getPlayerNavigationLinks';
import { useNavigate } from 'react-router-dom';
import { routes } from 'shared/routes';
import { TEntityId } from 'shared/types/common';

type TProps = {
  teamId?: TEntityId;
  playerId?: TEntityId;
};

export const usePlayerLayout = ({ teamId, playerId }: TProps) => {
  const navigate = useNavigate();

  const {
    data: player,
    isLoading: isLoadingPlayer,
    error: playerError,
  } = useFetchPlayerQuery(playerId);
  const {
    data: team,
    isLoading: isLoadingTeam,
    error: teamError,
  } = useFetchTeamQuery(teamId);

  const playerNavigationLinks = getPlayerNavigationLinks(team?.id, player?.id);

  const backButtonClickHandler = () => {
    if (team?.id) {
      navigate(routes.editTeam.as(team.id));
    }
  };

  return {
    error: teamError || playerError,
    isLoading: (isLoadingTeam && teamId) || (isLoadingPlayer && playerId),
    playerNavigationLinks,
    backButtonClickHandler,
  };
};
