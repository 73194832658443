import { AchievementModal } from 'feature/achievement-modal/AchievementModal';
import { PlayerLayout } from 'feature/player-layout/ui/PlayerLayout/PlayerLayout';
import { useCreatePlayerTeamAchievement } from 'pages/player/team-achievements/PlayerTeamAchievementsPage/hooks/useCreatePlayerTeamAchievement';
import { usePlayerTeamAchievements } from 'pages/player/team-achievements/PlayerTeamAchievementsPage/hooks/usePlayerTeamAchievements';
import styles from 'pages/player/team-achievements/PlayerTeamAchievementsPage/PlayerTeamAchievementsPage.module.css';
import { PlayerTeamAchievementsTable } from 'pages/player/team-achievements/PlayerTeamAchievementsPage/PlayerTeamAchievementsTable/PlayerTeamAchievementsTable';
import {
  calculateSelectedPage,
  calculateTotalItems,
} from 'pages/video/VideoPage/utils';
import { useTranslation } from 'react-i18next';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

export const PlayerTeamAchievementsPage = () => {
  const { t } = useTranslation();
  const {
    error,
    isLoading,
    changePage,
    playerTeamAchievements,
    page,
    total,
    handleConfirmButtonClick,
  } = usePlayerTeamAchievements();
  const { createTeamAchievementModalProps, openCreateAchievementModal } =
    useCreatePlayerTeamAchievement();

  return (
    <PlayerLayout
      confirmButtonProps={{
        onClick: handleConfirmButtonClick,
      }}
    >
      <CommonListWrapper
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.teamAchievements')}
        createButtonProps={{
          content: t('content.createAchievement'),
          onClick: openCreateAchievementModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(playerTeamAchievements, page),
          totalItems: calculateTotalItems(playerTeamAchievements, total),
          onPageChange: changePage,
        }}
      >
        <PlayerTeamAchievementsTable
          playerTeamAchievements={playerTeamAchievements}
        />
      </CommonListWrapper>
      {createTeamAchievementModalProps.isOpened && (
        <AchievementModal {...createTeamAchievementModalProps} />
      )}
    </PlayerLayout>
  );
};
