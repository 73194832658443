import i18n from 'i18next';
import { useEffect, useState } from 'react';
import {
  EditorElementTypeEnum,
  TEditor,
  TEditorHeadingElements,
} from 'shared/components/Editor/types';
import { editorController } from 'shared/components/Editor/utils/editor/editorController';
import { Select } from 'shared/components/Form/Select/Select';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { useWorkspaceHeadingActions } from 'shared/components/Workspace/components/WorkspacePanel/WorkspacePanelHeadingSelect/useWorkspaceHeadingActions';

import styles from './WorkspacePanelHeadingSelect.module.css';

type TProps = {
  editor: TEditor;
  disabled?: boolean;
};

const headingOptions: TSelectOption[] = [
  {
    value: EditorElementTypeEnum.HeadingTwo,
    label: i18n.t('shared.Workspace.labelHead'),
    className: styles.headingTwoOption,
  },
  {
    value: EditorElementTypeEnum.Paragraph,
    label: i18n.t('shared.Workspace.labelText'),
  },
];

const getPlaceholder = (type: TEditorHeadingElements) => {
  switch (type) {
    case EditorElementTypeEnum.HeadingTwo:
      return i18n.t('shared.Workspace.labelHead');
    case EditorElementTypeEnum.Paragraph:
      return i18n.t('shared.Workspace.labelText');
    default:
      return '';
  }
};

export const WorkspacePanelHeadingSelect = ({ editor, disabled }: TProps) => {
  const { toggleHeading } = useWorkspaceHeadingActions(editor);
  const [activeElement, setActiveElement] = useState<TEditorHeadingElements>(
    EditorElementTypeEnum.Paragraph,
  );

  useEffect(() => {
    if (editor?.selection) {
      const nodeElement = editorController.getElement(
        editor,
        EditorElementTypeEnum.HeadingTwo,
      );

      if (nodeElement) {
        setActiveElement(EditorElementTypeEnum.HeadingTwo);
      } else {
        setActiveElement(EditorElementTypeEnum.Paragraph);
      }
    }
  });

  const handleChange = (type: string) => {
    toggleHeading(type as TEditorHeadingElements);
  };

  return (
    <Select
      disabled={disabled}
      inputClassName={styles.select}
      multiple={false}
      options={headingOptions}
      placeholder={getPlaceholder(activeElement)}
      placement="top"
      value={activeElement}
      onChange={handleChange}
    />
  );
};
