import React, { ReactNode } from 'react';

import styles from './RightPanel.module.css';

type TProps = {
  components: ReactNode;
};

export const RightPanel = ({ components }: TProps) => {
  return <div className={styles.root}>{components}</div>;
};
