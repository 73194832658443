// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VideoFormDraftButton-module__draftButton--aqfWL {\n  width: auto;\n}\n\n.VideoFormDraftButton-module__disabled--s5DLK {\n  opacity: var(--disabled-opacity);\n}\n", "",{"version":3,"sources":["webpack://./src/4-widgets/video-form/ui/VideoFormHeaderPanel/VideoFormDraftButton/VideoFormDraftButton.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".draftButton {\n  width: auto;\n}\n\n.disabled {\n  opacity: var(--disabled-opacity);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draftButton": "VideoFormDraftButton-module__draftButton--aqfWL",
	"disabled": "VideoFormDraftButton-module__disabled--s5DLK"
};
export default ___CSS_LOADER_EXPORT___;
