import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { FormInput } from 'shared/components/Form';
import { workspacePanelAddLinkModalValidation } from 'shared/components/Workspace/components/WorkspacePanel/WorkspacePanelAddLinkModal/validation';

import styles from './WorkspacePanelAddLinkModal.module.css';

type TProps = {
  onCreate: (props: { url: string; text: string }) => void;
  defaultText?: string;
  defaultUrl?: string;
  onClose: () => void;
  opened: boolean;
};

const MAX_LENGTH = 1000;

export const WorkspacePanelAddLinkModal = ({
  onClose,
  opened,
  onCreate,
  defaultUrl = '',
  defaultText = '',
}: TProps) => {
  const { t } = useTranslation();
  const { control, reset, handleSubmit, getValues, formState } = useForm({
    values: {
      url: defaultUrl,
      text: defaultText,
    },
    mode: 'onBlur',
    resolver: zodResolver(workspacePanelAddLinkModalValidation),
  });

  const onSubmit = () => {
    const { url, text } = getValues();
    onCreate({ url, text });
    onClose?.();
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, [opened]);

  return (
    <ActionModal
      contentClassName={styles.body}
      opened={opened}
      showCloseButton={false}
      title={t('shared.Workspace.addLink')}
      width={542}
      confirmButtonProps={{
        className: cn({
          [styles.disabled]: !formState.isValid,
        }),
        onClick: handleSubmit(onSubmit),
      }}
      onClose={onClose}
    >
      <FormInput
        control={control}
        fieldName="text"
        label={t('shared.Workspace.showAs')}
        maxLength={MAX_LENGTH}
        placeholder={t('shared.Workspace.addText')}
        showErrors
      />
      <FormInput
        control={control}
        fieldName="url"
        label={t('shared.Workspace.addUrl')}
        maxLength={MAX_LENGTH}
        placeholder={t('shared.addLink')}
        showErrors
      />
    </ActionModal>
  );
};
