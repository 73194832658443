import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchPlayerPositionsQuery } from 'entities/playerPositions/query';
import { useMemo } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'shared/components/Form/Select/FormSelect';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { toString } from 'shared/lib/toString';

import styles from './PlayerPositionSelect.module.css';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  className?: string;
};

export const PlayerPositionSelect = <T extends FieldValues>({
  control,
  fieldName,
  className,
}: TProps<T>) => {
  const { t } = useTranslation();
  const {
    field: { value: selectedPosition },
  } = useController({
    control,
    name: fieldName,
  });

  const { defaultLocale } = useLocales();
  const { data } = useFetchPlayerPositionsQuery();

  const playerPositionOptions = useMemo(
    () =>
      data?.map<TSelectOption>((position) => ({
        value: toString(position.id),
        label: position.translations[defaultLocale].jobTitle,
        size: 's',
      })) ?? [],
    [data],
  );

  const playerPositionPlaceholder = useMemo(() => {
    if (selectedPosition && playerPositionOptions.length) {
      return playerPositionOptions.find(
        (position) => position.value === selectedPosition,
      )!.label;
    }

    return t('content.choosePosition');
  }, [selectedPosition, playerPositionOptions]);

  return (
    <FormSelect
      className={className}
      control={control}
      fieldName={fieldName}
      label={t('content.player.playerPosition')}
      multiple={false}
      options={playerPositionOptions}
      optionsContainerClassName={styles.optionsContainer}
      placeholder={playerPositionPlaceholder}
      required
      search={true}
    />
  );
};
