import { TPlayerAchievement } from 'entities/playerAchievements/types';
import { getPlayerAchievementsColumnsConfig } from 'pages/player/achievements/PlayerAchievementsPage/columnsConfig';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './PlayerAchievementsTable.module.css';

type TProps = {
  playerAchievements: TPlayerAchievement[];
};

export const PlayerAchievementsTable = ({ playerAchievements }: TProps) => {
  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getPlayerAchievementsColumnsConfig,
  });

  return (
    <div className={styles.root}>
      <CommonListTable
        activeItemId={activeItemId}
        columns={columnsConfig}
        data={playerAchievements}
        headRowClassName={styles.headRow}
        rowClassName={styles.rowGridTemplate}
      />
    </div>
  );
};
