import { parseISO } from 'date-fns';
import { useLocales } from 'entities/config/lib/useLocales';
import {
  useDeleteNewsPostMutation,
  useEditPublicationDateNewsPostMutation,
} from 'entities/news/mutations';
import { ENewsPostStatus, TNewsPost } from 'entities/news/types';
import { mapNewsPostToEdit } from 'pages/news/edit/EditNewsPost/mapper';
import { TStoriesPageModal } from 'pages/stories/StoriesPage/types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { notify } from 'shared/components/Notification';
import { routes } from 'shared/routes';
import { TEntityId } from 'shared/types/common';

import { TActionsByStatus, TDefaultActionsByStatus } from './types';

type TProps = {
  newsPost: TNewsPost;
  activeNewsPostId: TEntityId | null;
  setActiveNewsPostId: (id: TEntityId | null) => void;
};

export const NewsPostActions = ({
  newsPost,
  activeNewsPostId,
  setActiveNewsPostId,
}: TProps) => {
  const { t } = useTranslation();
  const { id, status, published } = newsPost;
  const { mutateAsync: changeNewsPostPublication, isLoading } =
    useEditPublicationDateNewsPostMutation({
      isChangeMainImage: false,
      isChangeTitle: false,
    });
  const { defaultLocale } = useLocales();
  const navigate = useNavigate();
  const { mutate: deleteNewsPostMutation } = useDeleteNewsPostMutation();
  const isActive = id === activeNewsPostId;
  const [activeModal, setActiveModal] = useState<TStoriesPageModal | null>(null);
  const closeModal = () => {
    setActiveModal(null);
  };

  const defaultActions: TDefaultActionsByStatus = {
    edit: {
      title: t('content.edit'),
      icon: 'edit-02',
      action: (id) => {
        navigate(routes.editNewsPost.as(id));
      },
    },
    delete: {
      title: t('content.delete'),
      icon: 'trash',
      action: (id) => {
        deleteNewsPostMutation(
          { newsPostId: id },
          {
            onError: () =>
              notify(t('content.news.notifyNotDelete'), { type: 'error' }),
          },
        );
        setActiveNewsPostId(null);
      },
    },
  };

  const changeNewsPostPublishDate = async (publishDate?: Date) => {
    const initialValue = await mapNewsPostToEdit(newsPost, defaultLocale);
    closeModal();

    await changeNewsPostPublication({
      ...initialValue,
      publishDate,
    });
  };

  const handleActionsByStatus: TActionsByStatus = {
    [ENewsPostStatus.Postponed]: [
      {
        title: t('content.publicNow'),
        icon: 'plus',
        action: () => setActiveModal('confirmPublishNow'),
      },
      defaultActions.edit,
      {
        title: t('content.changeTimePublic'),
        icon: 'clock',
        action: () => setActiveModal('changePublishDate'),
      },
      defaultActions.delete,
    ],
    [ENewsPostStatus.Draft]: [defaultActions.edit, defaultActions.delete],
    [ENewsPostStatus.Published]: [
      defaultActions.edit,
      {
        title: t('content.deleteFromPublication'),
        icon: 'no-eyes',
        action: () => changeNewsPostPublishDate(),
      },
      defaultActions.delete,
    ],
  };

  const handleClick = useCallback(() => {
    if (activeNewsPostId === id) {
      return setActiveNewsPostId(null);
    }

    setActiveNewsPostId(id);
  }, [id, activeNewsPostId]);

  return (
    <>
      <Actions
        actions={handleActionsByStatus[status]}
        activeItemId={id}
        isLoading={isLoading}
        isOpen={isActive}
        onIconClick={handleClick}
      />
      <DatePicker
        date={(newsPost && published && parseISO(published)) || undefined}
        isOpened={activeModal === 'changePublishDate'}
        onClose={closeModal}
        onDateChange={changeNewsPostPublishDate}
      />
      <ConfirmationModal
        description={t('content.news.publicNews', { mark: '?' })}
        isOpened={activeModal === 'confirmPublishNow'}
        title={t('content.confirm')}
        onClose={closeModal}
        onConfirm={() => changeNewsPostPublishDate(new Date())}
      />
    </>
  );
};
