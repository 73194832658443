import { TVideoCategory } from 'entities/videoCategories/types';
import { getVideoCategoriesColumnsConfig } from 'pages/settings/categories/VideoCategoriesPage/columnsConfig';
import { TVideoCategoriesSortParams } from 'pages/settings/categories/VideoCategoriesPage/types';
import React from 'react';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { TSortOptions } from 'widgets/elements-list-table/types';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './VideoCategoriesPage.module.css';

type TProps = {
  data: TVideoCategory[];
  onSort: (sortOptions: TSortOptions<TVideoCategoriesSortParams>) => void;
  sortOptions: TSortOptions<TVideoCategoriesSortParams>;
};

export const VideoCategoriesPageTable = ({ data, onSort, sortOptions }: TProps) => {
  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getVideoCategoriesColumnsConfig,
  });

  return (
    <CommonListTable
      activeItemId={activeItemId}
      columns={columnsConfig}
      data={data}
      rowClassName={styles.rowGridTemplate}
      sortOptions={sortOptions}
      onSort={onSort}
    />
  );
};
