import { useState } from 'react';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'shared/consts';
import { toString } from 'shared/lib/toString';

export type TPaginatedParameters = {
  page?: number;
  pageSize?: number;
};

export const usePagination = (params?: TPaginatedParameters) => {
  const [pagination, setPagination] = useState(
    createPaginationParams(
      params?.page ?? 1,
      params?.pageSize ?? DEFAULT_PAGINATION_PAGE_SIZE,
    ),
  );

  const changePage = (page: number) => {
    setPagination((p) => ({ ...p, ...createPageNumberParam(page) }));
  };

  return { pagination, changePage };
};

const createPageNumberParam = (pageNumber: number) => {
  return { 'page[number]': toString(pageNumber) };
};

const createPageSizeParam = (pageSize: number = DEFAULT_PAGINATION_PAGE_SIZE) => {
  return { 'page[size]': toString(pageSize) };
};

const createPaginationParams = (pageNumber: number, pageSize?: number) => {
  return {
    ...createPageNumberParam(pageNumber),
    ...createPageSizeParam(pageSize),
  };
};
