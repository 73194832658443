// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreviewSelect-module__control--fHKMJ {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 4px;\n  cursor: pointer;\n  color: var(--gray-400);\n}\n\n  .PreviewSelect-module__control--fHKMJ.PreviewSelect-module__active--Ez9ts {\n    color: var(--green-400);\n  }\n\n.PreviewSelect-module__option--G1iq5 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 16px;\n  padding: 4px;\n}\n\n.PreviewSelect-module__dropdown--Il_OZ {\n  padding: 8px;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.PreviewSelect-module__button--cZvCs {\n  margin-top: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/stories/edit/EditStory/components/PreviewSelect/PreviewSelect.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,QAAQ;EACR,eAAe;EACf,sBAAsB;AAKxB;;EAHE;IACE,uBAAuB;EACzB;;AAGF;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".control {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 4px;\n  cursor: pointer;\n  color: var(--gray-400);\n\n  &.active {\n    color: var(--green-400);\n  }\n}\n\n.option {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 16px;\n  padding: 4px;\n}\n\n.dropdown {\n  padding: 8px;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.button {\n  margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": "PreviewSelect-module__control--fHKMJ",
	"active": "PreviewSelect-module__active--Ez9ts",
	"option": "PreviewSelect-module__option--G1iq5",
	"dropdown": "PreviewSelect-module__dropdown--Il_OZ",
	"button": "PreviewSelect-module__button--cZvCs"
};
export default ___CSS_LOADER_EXPORT___;
