// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer-module__container--XaG_S {\n  display: flex;\n  flex-direction: column;\n  border-top: 1px solid var(--gray-200);\n  background-color: var(--bg-default);\n  border-bottom-left-radius: var(--border-radius-l);\n  border-bottom-right-radius: var(--border-radius-l);\n}\n\n.Footer-module__buttons--xUh8j {\n  display: flex;\n  justify-content: space-between;\n  padding: 12px 16px;\n  align-items: center;\n  background-color: var(--gray-50);\n  border-bottom-left-radius: var(--border-radius-l);\n  border-bottom-right-radius: var(--border-radius-l);\n  gap: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/DatePicker/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qCAAqC;EACrC,mCAAmC;EACnC,iDAAiD;EACjD,kDAAkD;AACpD;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,mBAAmB;EACnB,gCAAgC;EAChC,iDAAiD;EACjD,kDAAkD;EAClD,QAAQ;AACV","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  border-top: 1px solid var(--gray-200);\n  background-color: var(--bg-default);\n  border-bottom-left-radius: var(--border-radius-l);\n  border-bottom-right-radius: var(--border-radius-l);\n}\n\n.buttons {\n  display: flex;\n  justify-content: space-between;\n  padding: 12px 16px;\n  align-items: center;\n  background-color: var(--gray-50);\n  border-bottom-left-radius: var(--border-radius-l);\n  border-bottom-right-radius: var(--border-radius-l);\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Footer-module__container--XaG_S",
	"buttons": "Footer-module__buttons--xUh8j"
};
export default ___CSS_LOADER_EXPORT___;
