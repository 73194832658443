import { SecondLayoutNavigationLink } from 'shared/ui/layouts/second-layout/SecondLayoutNavigationLink/SecondLayoutNavigationLink';
import { TSecondLayoutNavigationLink } from 'shared/ui/layouts/second-layout/types';

import styles from './SecondLayoutNavigation.module.css';

type TProps = {
  links: TSecondLayoutNavigationLink[];
};

export const SecondLayoutNavigation = ({ links }: TProps) => {
  return (
    <div className={styles.root}>
      <ul className={styles.list}>
        {links.map((link) => (
          <SecondLayoutNavigationLink key={link.route} link={link} />
        ))}
      </ul>
    </div>
  );
};
