// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManagerCareersPage-module__text--dixoJ {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.ManagerCareersPage-module__previewWrapper--orMbO {\n  width: 32px;\n  height: 32px;\n  background: var(--gray-150);\n  border-radius: var(--border-radius-max);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: var(--gray-250);\n  margin-right: 20px;\n  flex-shrink: 0;\n  overflow: hidden;\n}\n\n.ManagerCareersPage-module__createButton--VZgQm {\n  padding: 7px 11px;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/manager/careers/ManagerCareersPage/ManagerCareersPage.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,2BAA2B;EAC3B,uCAAuC;EACvC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".text {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.previewWrapper {\n  width: 32px;\n  height: 32px;\n  background: var(--gray-150);\n  border-radius: var(--border-radius-max);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: var(--gray-250);\n  margin-right: 20px;\n  flex-shrink: 0;\n  overflow: hidden;\n}\n\n.createButton {\n  padding: 7px 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "ManagerCareersPage-module__text--dixoJ",
	"previewWrapper": "ManagerCareersPage-module__previewWrapper--orMbO",
	"createButton": "ManagerCareersPage-module__createButton--VZgQm"
};
export default ___CSS_LOADER_EXPORT___;
