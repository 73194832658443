import { AddNewsPostBackButton } from 'shared/components/NewsPostForm/components/AddNewsPostBackButton/AddNewsPostBackButton';
import { AddNewsPostDraftButton } from 'shared/components/NewsPostForm/components/AddNewsPostDraftButton/AddNewsPostDraftButton';
import { AddNewsPostLocalesControl } from 'shared/components/NewsPostForm/components/AddNewsPostLocalesControl/AddNewsPostLocalesControl';
import { AddNewsPostPreview } from 'shared/components/NewsPostForm/components/AddNewsPostPreview/AddNewsPostPreview';
import { AddNewsPostPublishButton } from 'shared/components/NewsPostForm/components/AddNewsPostPublishButton/AddNewsPostPublishButton';
import { AddNewsPostUnPublishButton } from 'shared/components/NewsPostForm/components/AddNewsPostUnpublishedButton';

import styles from './AddNewsPostHeaderPanel.module.css';

export const AddNewsPostHeaderPanel = () => {
  return (
    <div className={styles.root}>
      <AddNewsPostBackButton />
      <AddNewsPostLocalesControl />
      <div className={styles.control}>
        <AddNewsPostPreview />
        <AddNewsPostDraftButton />
        <AddNewsPostUnPublishButton />
        <AddNewsPostPublishButton />
      </div>
    </div>
  );
};
