import cn from 'classnames';
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';
import { TIconSize, TIconType } from 'shared/components/Icon/types';

import { componentsMap } from './constants';
import styles from './Icon.module.css';

export type TIconProps = {
  kind: TIconType;
  size?: TIconSize;
  customSize?: number;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const Icon = forwardRef<HTMLSpanElement, TIconProps>(
  ({ className, kind, size = 'l', ...props }, ref) => {
    const Component = componentsMap[kind];

    return (
      <i className={cn(styles.root, styles[size], className)} ref={ref} {...props}>
        <Component />
      </i>
    );
  },
);
