// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToolbarButton-module__action--evGYr {\n  display: flex;\n  flex-shrink: 0;\n  color: var(--gray-350);\n  border-radius: var(--border-radius-default);\n  transition:\n    background-color var(--duration-interaction),\n    color var(--duration-interaction);\n  padding: 6px;\n}\n\n  .ToolbarButton-module__action--evGYr:hover {\n    color: var(--gray-350);\n    background-color: var(--gray-100);\n  }\n\n.ToolbarButton-module__textAction--nUmr9 {\n  width: auto;\n  background-color: transparent;\n}\n\n.ToolbarButton-module__actionActive--peeCB {\n  color: var(--gray-400);\n  background-color: var(--gray-200);\n}\n\n.ToolbarButton-module__actionActive--peeCB:hover {\n    color: var(--gray-450);\n    background-color: var(--gray-100);\n  }\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/ToolbarButton/ToolbarButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,sBAAsB;EACtB,2CAA2C;EAC3C;;qCAEmC;EACnC,YAAY;AAMd;;EAJE;IACE,sBAAsB;IACtB,iCAAiC;EACnC;;AAGF;EACE,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE,sBAAsB;EACtB,iCAAiC;AAMnC;;AAJE;IACE,sBAAsB;IACtB,iCAAiC;EACnC","sourcesContent":[".action {\n  display: flex;\n  flex-shrink: 0;\n  color: var(--gray-350);\n  border-radius: var(--border-radius-default);\n  transition:\n    background-color var(--duration-interaction),\n    color var(--duration-interaction);\n  padding: 6px;\n\n  &:hover {\n    color: var(--gray-350);\n    background-color: var(--gray-100);\n  }\n}\n\n.textAction {\n  width: auto;\n  background-color: transparent;\n}\n\n.actionActive {\n  color: var(--gray-400);\n  background-color: var(--gray-200);\n\n  &:hover {\n    color: var(--gray-450);\n    background-color: var(--gray-100);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action": "ToolbarButton-module__action--evGYr",
	"textAction": "ToolbarButton-module__textAction--nUmr9",
	"actionActive": "ToolbarButton-module__actionActive--peeCB"
};
export default ___CSS_LOADER_EXPORT___;
