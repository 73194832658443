// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationLink-module__root--ObtTh {\n  box-sizing: border-box;\n}\n\n  .NavigationLink-module__root--ObtTh .NavigationLink-module__link--nCj5K {\n    display: block;\n    height: 100%;\n    color: var(--gray-200);\n    padding: 16px;\n    text-decoration: none;\n    box-sizing: border-box;\n    border-bottom: 2px solid transparent;\n    transition:\n      border-bottom-color var(--duration-interaction),\n      color var(--duration-interaction);\n  }\n\n  .NavigationLink-module__root--ObtTh .NavigationLink-module__link--nCj5K:hover,\n    .NavigationLink-module__root--ObtTh .NavigationLink-module__link--nCj5K.NavigationLink-module__linkActive--GaOLO {\n      color: var(--green-400);\n      border-bottom: 2px solid var(--green-400);\n    }\n", "",{"version":3,"sources":["webpack://./src/4-widgets/navigation/NavigationLink/NavigationLink.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AAoBxB;;EAlBE;IACE,cAAc;IACd,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,qBAAqB;IACrB,sBAAsB;IACtB,oCAAoC;IACpC;;uCAEmC;EAOrC;;EALE;;MAEE,uBAAuB;MACvB,yCAAyC;IAC3C","sourcesContent":[".root {\n  box-sizing: border-box;\n\n  .link {\n    display: block;\n    height: 100%;\n    color: var(--gray-200);\n    padding: 16px;\n    text-decoration: none;\n    box-sizing: border-box;\n    border-bottom: 2px solid transparent;\n    transition:\n      border-bottom-color var(--duration-interaction),\n      color var(--duration-interaction);\n\n    &:hover,\n    &.linkActive {\n      color: var(--green-400);\n      border-bottom: 2px solid var(--green-400);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "NavigationLink-module__root--ObtTh",
	"link": "NavigationLink-module__link--nCj5K",
	"linkActive": "NavigationLink-module__linkActive--GaOLO"
};
export default ___CSS_LOADER_EXPORT___;
