import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchPlayerPositionsQuery } from 'entities/playerPositions/query';
import { useFetchPlayersQuery } from 'entities/players/api/queries';
import i18n from 'i18next';
import { DEFAULT_PAGINATION_TEAM_SIZE } from 'pages/team/constants';
import { useMemo } from 'react';
import { notify } from 'shared/components/Notification';
import { compareIds } from 'shared/utils/entityIds';

export const usePlayers = (teamId: string | undefined) => {
  const { defaultLocale } = useLocales();
  const {
    data: { data: players, total = 0, page = 1 } = {},
    isLoading,
    changePage,
  } = useFetchPlayersQuery(
    {
      pageSize: DEFAULT_PAGINATION_TEAM_SIZE,
      filter: teamId ? { 'filter[team_id][eq]': teamId } : {},
    },
    { cacheTime: 0, onError: () => notify(i18n.t('content.teams.notify')) },
  );

  const { data: playerPositions, isLoading: isLoadingPlayerPositions } =
    useFetchPlayerPositionsQuery();

  const playersList = useMemo(() => {
    if (players && playerPositions) {
      return players.map((player) => {
        const playerPosition =
          playerPositions.find((position) =>
            compareIds(position.id, player.playerPosition),
          )?.translations[defaultLocale].jobTitle ?? '';

        return {
          ...player,
          playerPosition,
        };
      });
    }

    return [];
  }, [playerPositions, players]);

  const isShowPagination =
    total > 0 && total > DEFAULT_PAGINATION_TEAM_SIZE && !isLoading;

  return {
    playersList,
    total,
    page,
    isShowPagination,
    changePage,
    isLoading: isLoadingPlayerPositions || isLoading,
  };
};
