import { ManagerPositionSelect } from 'feature/manager-position-select/ManagerPositionSelect';
import { useForm } from 'react-hook-form';

export const ManagerPositionSelectExample = () => {
  const { control } = useForm({
    values: {
      managerPositionId: '',
    },
  });

  return <ManagerPositionSelect control={control} fieldName="managerPositionId" />;
};
