// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthPage-module__root--Fe9cX {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  padding: 24px;\n}\n\n.AuthPage-module__image--sCH1p {\n  width: auto;\n  max-height: calc(100vh - (var(--pad) * 2));\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/auth/AuthPage/AuthPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,WAAW;EACX,0CAA0C;AAC5C","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  padding: 24px;\n}\n\n.image {\n  width: auto;\n  max-height: calc(100vh - (var(--pad) * 2));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "AuthPage-module__root--Fe9cX",
	"image": "AuthPage-module__image--sCH1p"
};
export default ___CSS_LOADER_EXPORT___;
