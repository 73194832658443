import i18n from 'i18next';
import z from 'zod';

export const workspacePanelAddLinkModalValidation = z.object({
  url: z
    .string()
    .min(1, i18n.t('shared.Workspace.minWarning'))
    .url(i18n.t('shared.Workspace.urlWarning'))
    .startsWith('https://', {
      message: i18n.t('shared.Workspace.urlSafeWarning'),
    }),
  text: z.string(),
});
