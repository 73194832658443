// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsNavigation-module__root--Fqae6 {\n  display: flex;\n  flex-direction: column;\n  width: 236px;\n  padding: 12px 12px var(--pad) 8px;\n  border-right: 1px solid var(--gray-200);\n}\n\n.SettingsNavigation-module__link--QboRf {\n  margin-top: 16px;\n  margin-left: 4px;\n  margin-bottom: 16px;\n  color: var(--dark-blue-100);\n}\n\n.SettingsNavigation-module__link--QboRf:hover {\n    color: var(--dark-blue-100);\n  }\n", "",{"version":3,"sources":["webpack://./src/5-feature/settings-navigation/ui/SettingsNavigation.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,iCAAiC;EACjC,uCAAuC;AACzC;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,2BAA2B;AAK7B;;AAHE;IACE,2BAA2B;EAC7B","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  width: 236px;\n  padding: 12px 12px var(--pad) 8px;\n  border-right: 1px solid var(--gray-200);\n}\n\n.link {\n  margin-top: 16px;\n  margin-left: 4px;\n  margin-bottom: 16px;\n  color: var(--dark-blue-100);\n\n  &:hover {\n    color: var(--dark-blue-100);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SettingsNavigation-module__root--Fqae6",
	"link": "SettingsNavigation-module__link--QboRf"
};
export default ___CSS_LOADER_EXPORT___;
