import cn from 'classnames';
import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'shared/components/Form';
import { Text } from 'shared/components/Text/Text';
import { getLangNameMap } from 'shared/consts';
import { TLangType, TWithOptionalClassName } from 'shared/types/common';

import styles from './TranslationInputs.module.css';

// TODO: вынести в компонент и переиспользоваться везде,
//  где есть конструкция " locales?.map((locale) => (<FormInput ... "
type TProps<T extends FieldValues> = {
  locales: TLangType[];
  title?: string;
  control: Control<T>;
  maxLength: number;
  pathResolver: (locale: TLangType) => Path<T>;
} & TWithOptionalClassName;

export const TranslationInputs = <T extends FieldValues>({
  title,
  className,
  locales,
  control,
  pathResolver,
  maxLength,
}: TProps<T>) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.root, className)}>
      {title && (
        <Text className={styles.title} size={14} tag="h3" weight="SemiBold">
          {title}
        </Text>
      )}
      <div className={styles.inputWrapper}>
        {locales?.map((locale) => (
          <FormInput
            key={locale}
            control={control}
            fieldName={pathResolver(locale)}
            inputClassName={styles.input}
            label={getLangNameMap()[locale]}
            maxLength={maxLength}
            placeholder={t('content.inputHeading')}
            required
            size="s"
          />
        ))}
      </div>
    </div>
  );
};
