import { useFetchPlayerAchievementsQuery } from 'entities/playerAchievements/quaries';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'shared/routes';

export const usePlayerAchievements = () => {
  const navigate = useNavigate();
  const { teamId = '', playerId = '' } = useParams<{
    teamId: string;
    playerId: string;
  }>();

  const {
    data: { data: playerAchievements = [], total, page } = {},
    isLoading: isPlayerAchievementsLoading,
    error: playerAchievementsError,
    changePage,
  } = useFetchPlayerAchievementsQuery({
    filter: playerId ? { 'filter[player_id]': playerId } : {},
  });

  const handleConfirmButtonClick = () => {
    navigate(routes.editTeam.as(teamId), {
      replace: true,
    });
  };

  return {
    playerAchievements,
    page,
    total,
    isLoading: isPlayerAchievementsLoading,
    error: playerAchievementsError,
    handleConfirmButtonClick,
    changePage,
  };
};
