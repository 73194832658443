import cn from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { useCreateNewsPost } from 'shared/components/NewsPostForm/hooks/useCreateNewsPost';
import { useIsEditNewsPost } from 'shared/components/NewsPostForm/hooks/useIsEditNewsPost';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';

import styles from './AddNewsPostDraftButton.module.css';

export const AddNewsPostDraftButton = () => {
  const { t } = useTranslation();
  const { formState, handleSubmit, control } = useFormContext<TEditNewsPostFields>();
  const { field } = useController({
    control,
    name: 'publishDate',
  });
  const isEdit = useIsEditNewsPost();
  const createNewsPost = useCreateNewsPost();

  if (isEdit) {
    return null;
  }

  const onDraftNewsPost = () => {
    field.onChange(undefined);
    handleSubmit(createNewsPost)();
  };

  return (
    <Button
      color="gray"
      isDisabled={formState.isSubmitting}
      kind="outlined"
      className={cn(styles.draftButton, {
        [styles.disabled]: !formState.isValid,
      })}
      onClick={onDraftNewsPost}
    >
      {t('shared.NewsPostForm.AddNewsPostDraftButton.button')}
    </Button>
  );
};
