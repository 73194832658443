// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CropperPanel-module__root--VTxFE {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: 4px;\n  gap: 8px;\n\n  border-radius: 12px;\n  border: 1px solid var(--gray-200);\n}\n\n.CropperPanel-module__divider--tg9yA {\n  height: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/cropper/CropperPanel/CropperPanel.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,QAAQ;;EAER,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE,YAAY;AACd","sourcesContent":[".root {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: 4px;\n  gap: 8px;\n\n  border-radius: 12px;\n  border: 1px solid var(--gray-200);\n}\n\n.divider {\n  height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CropperPanel-module__root--VTxFE",
	"divider": "CropperPanel-module__divider--tg9yA"
};
export default ___CSS_LOADER_EXPORT___;
