import { useQuery } from '@tanstack/react-query';
import { matchesQueryKeys } from 'entities/matches/consts';
import { TFetchMatchesProps } from 'entities/matches/types';
import { createFetchMatchesFilter } from 'entities/matches/utils';

import { fetchMatches } from './api';

export const useFetchMatchesQuery = (
  props?: TFetchMatchesProps,
  queryProps?: { enabled?: boolean },
) =>
  useQuery({
    queryKey: [matchesQueryKeys.matches, createFetchMatchesFilter(props)],
    queryFn: () => fetchMatches(props),
    ...(queryProps || {}),
  });
