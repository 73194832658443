import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import {
  TAchievementModalProps,
  TAchievementModalValue,
} from 'feature/achievement-modal/types';
import { achievementModalValidationSchema } from 'feature/achievement-modal/validationSchema';
import { useForm } from 'react-hook-form';

const ACHIEVEMENT_MODAL_WIDTH = 542;

export const useAchievementModal = ({
  initialValue,
  actionSubmit,
  closeModal,
  isLoading,
  isOpened,
  title,
}: TAchievementModalProps) => {
  const { locales } = useLocales();

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
  } = useForm<TAchievementModalValue>({
    defaultValues: initialValue,
    mode: 'onChange',
    resolver: zodResolver(achievementModalValidationSchema(locales)),
  });

  const onCloseModal = () => {
    closeModal();
    reset();
  };

  const submit = handleSubmit((data) => {
    actionSubmit(data);
  });

  return {
    locales,
    control,
    actionModalProps: {
      onClose: onCloseModal,
      opened: isOpened,
      title,
      width: ACHIEVEMENT_MODAL_WIDTH,
      confirmButtonProps: {
        isDisabled: !isValid || !isDirty,
        onClick: submit,
        isLoading,
      },
    },
  };
};
