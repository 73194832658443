import { OpponentPlayerModal } from 'feature/opponent-player-modal/OpponentPlayerModal';
import { Button } from 'shared/components/Button/Button';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';

import styles from '../UIKit.module.css';

export const OpponentPlayerModalExample = () => {
  const { openedModal, closeModal, openModal } = useModal('');

  return (
    <div className={styles.root}>
      <Button onClick={openModal}>Открыть модальное окно игрока оппонента</Button>
      <OpponentPlayerModal
        actionSubmit={(values) => notify(JSON.stringify(values, undefined, 2))}
        closeModal={closeModal}
        isLoading={false}
        isOpened={openedModal}
        title="Создание игрока"
      />
    </div>
  );
};
