import { playerSocialNetworksApiKeys } from 'entities/playerSocialNetworks/consts';
import {
  TCreatePlayerSocialNetworkDTO,
  TPlayerSocialNetwork,
} from 'entities/playerSocialNetworks/types';
import { del, get, patch, post } from 'shared/api/axios';
import { mapPaginatedResponse } from 'shared/api/pagination';
import { TFetchParams } from 'shared/api/types';
import { toNumber } from 'shared/lib/toNumber';
import { TEntityId } from 'shared/types/common';

export const fetchPlayerSocialNetworks = ({ params }: TFetchParams) =>
  get<TPlayerSocialNetwork[], true>(
    playerSocialNetworksApiKeys.getPlayerSocialNetworks,
    {
      params,
    },
  ).then((response) => mapPaginatedResponse(response.data));

export const deletePlayerSocialNetwork = (playerId: TEntityId) =>
  del(playerSocialNetworksApiKeys.deletePlayerSocialNetwork(playerId));

export const editPlayerSocialNetwork = ({
  id,
  socialNetworkId,
  ...playerSocialNetwork
}: TPlayerSocialNetwork) =>
  patch(playerSocialNetworksApiKeys.editPlayerSocialNetwork(id), {
    ...playerSocialNetwork,
    socialNetworkId: toNumber(socialNetworkId),
  });

export const createPlayerSocialNetwork = ({
  socialNetworkId,
  ...playerSocialNetwork
}: TCreatePlayerSocialNetworkDTO) =>
  post(playerSocialNetworksApiKeys.createPlayerSocialNetwork, {
    socialNetwork: {
      ...playerSocialNetwork,
      socialNetworkId: toNumber(socialNetworkId),
    },
  });
