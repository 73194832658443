import { TEntityId, TImageContent } from 'shared/types/common';

export enum MatchType {
  Guest = 'guest',
  Home = 'home',
}

export type TMatch = {
  id: TEntityId;
  externalId: TEntityId;
  broadcastUrl: string;
  reviewUrl?: string;
  ticketsUrl?: string;
  airlineTicketsUrl: string;
  matchType: MatchType;
  matchPeriod: string;
  status: string;
  result: string;
  teamSchema: string;
  opponentTeamSchema: string;
  startAt: string;
  endAt?: string;
  picture: TImageContent;
  tournamentSeason: string;
  stadium: string;
  tour: string;
  team: string;
  opponentTeam: string;
  coefficient: string;
  season: string;
  tournament: string;
};

export type TFetchMatchesProps = {
  tournamentId?: TEntityId;
  seasonId?: TEntityId;
};
