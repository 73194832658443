// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".THead-module__tableHeader--FZBpR {\n  border-radius: 8px;\n  background-color: var(--gray-25);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/SimpleTable/components/THead/THead.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gCAAgC;AAClC","sourcesContent":[".tableHeader {\n  border-radius: 8px;\n  background-color: var(--gray-25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeader": "THead-module__tableHeader--FZBpR"
};
export default ___CSS_LOADER_EXPORT___;
