// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StoryPreviewModal-module__root--jWEfX {\n  height: 100%;\n}\n\n.StoryPreviewModal-module__storyImageWrapper--TSO7Q {\n  position: relative;\n}\n\n.StoryPreviewModal-module__closeButtonWrapper--nTwGC {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  margin-bottom: 8px;\n}\n\n.StoryPreviewModal-module__closeButton--_t3V1 {\n  padding: 4px;\n  background-color: var(--bg-white);\n  border-radius: var(--border-radius-max);\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/stories/edit/EditStory/components/StoryPreviewModal/StoryPreviewModal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,iCAAiC;EACjC,uCAAuC;AACzC","sourcesContent":[".root {\n  height: 100%;\n}\n\n.storyImageWrapper {\n  position: relative;\n}\n\n.closeButtonWrapper {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  margin-bottom: 8px;\n}\n\n.closeButton {\n  padding: 4px;\n  background-color: var(--bg-white);\n  border-radius: var(--border-radius-max);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "StoryPreviewModal-module__root--jWEfX",
	"storyImageWrapper": "StoryPreviewModal-module__storyImageWrapper--TSO7Q",
	"closeButtonWrapper": "StoryPreviewModal-module__closeButtonWrapper--nTwGC",
	"closeButton": "StoryPreviewModal-module__closeButton--_t3V1"
};
export default ___CSS_LOADER_EXPORT___;
