import i18n from 'i18next';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TNavigationLink } from 'widgets/navigation/types';

export const getNavigationLinks = (): TNavigationLink[] => [
  {
    name: i18n.t('content.story.stories'),
    route: routes.stories,
    roles: DEFAULT_ROUTER_ROLES,
  },
  {
    name: i18n.t('content.news'),
    route: routes.news,
    roles: DEFAULT_ROUTER_ROLES,
  },
  {
    name: i18n.t('content.video'),
    route: routes.video,
    roles: DEFAULT_ROUTER_ROLES,
  },
  {
    name: i18n.t('content.team'),
    route: routes.teams,
    roles: DEFAULT_ROUTER_ROLES,
  },
];
