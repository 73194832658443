import { noop } from 'lodash';
import React from 'react';
import { Card } from 'shared/components/Card/Card';
import { ECardType } from 'shared/components/Card/types';

export const CardsExample = () => {
  return (
    <div>
      <Card
        type={ECardType.Player}
        entity={{
          name: 'First Name',
          lastName: 'Last name',
          picture:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaWijJYIlymw4n0lFctsEW9tl1RvDHPAIikA&s',
          position: 'position',
          number: 99,
        }}
        onClick={noop}
        onDelete={noop}
        onEdit={noop}
      />
      <br />
      <Card
        type={ECardType.Manager}
        entity={{
          name: 'First Name',
          lastName: 'Last name',
          picture:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaWijJYIlymw4n0lFctsEW9tl1RvDHPAIikA&s',
          position: 'position',
        }}
        onClick={noop}
        onDelete={noop}
        onEdit={noop}
      />
      <br />
      <Card
        type={ECardType.Team}
        entity={{
          name: 'First Name',
          picture:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaWijJYIlymw4n0lFctsEW9tl1RvDHPAIikA&s',
        }}
        onClick={noop}
        onDelete={noop}
        onEdit={noop}
      />
    </div>
  );
};
