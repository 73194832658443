import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchManagerPositionsQuery } from 'entities/managerPositions/query';
import { useFetchManagersQuery } from 'entities/managers/api/queries';
import { DEFAULT_PAGINATION_TEAM_SIZE } from 'pages/team/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { compareIds } from 'shared/utils/entityIds';

export const useManagers = (teamId: string | undefined) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const {
    data: { data: managers, total = 0, page = 1 } = {},
    isLoading,
    changePage,
  } = useFetchManagersQuery(
    {
      pageSize: DEFAULT_PAGINATION_TEAM_SIZE,
      filter: teamId ? { 'filter[team_id][eq]': teamId } : {},
    },
    { cacheTime: 0, onError: () => notify(t('content.notifyNotManagersList')) },
  );

  const { data: managerPositions, isLoading: isLoadingManagerPositions } =
    useFetchManagerPositionsQuery();

  const isShowPagination =
    total > 0 && total > DEFAULT_PAGINATION_TEAM_SIZE && !isLoading;

  const managersList = useMemo(() => {
    if (managers && managerPositions) {
      return managers.map((manager) => {
        const managerPosition =
          managerPositions.find((position) =>
            compareIds(position.id, manager.managerPosition),
          )?.translations[defaultLocale].jobTitle ?? '';

        return {
          ...manager,
          managerPosition,
        };
      });
    }

    return [];
  }, [managerPositions, managers]);

  return {
    managersList,
    total,
    page,
    changePage,
    isShowPagination,
    isLoading: isLoadingManagerPositions || isLoading,
  };
};
