// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DateTimeCell-module__root--qYJ84 {\n  min-width: 106px;\n  text-align: center;\n  color: var(--gray-500);\n}\n\n.DateTimeCell-module__time--gOhkd {\n  display: inline-block;\n  min-width: 30px;\n  color: var(--gray-400);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/SimpleTable/components/DateTimeCell/DateTimeCell.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".root {\n  min-width: 106px;\n  text-align: center;\n  color: var(--gray-500);\n}\n\n.time {\n  display: inline-block;\n  min-width: 30px;\n  color: var(--gray-400);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "DateTimeCell-module__root--qYJ84",
	"time": "DateTimeCell-module__time--gOhkd"
};
export default ___CSS_LOADER_EXPORT___;
