import { zodResolver } from '@hookform/resolvers/zod';
import { useConfig } from 'entities/config/lib/useConfig';
import { FormProvider, useForm } from 'react-hook-form';
import { TVideoPostFields } from 'widgets/video-form/types';
import { VideoRightPanel } from 'widgets/video-form/ui/VideoRightPanel/VideoRightPanel';
import { VideoWorkspace } from 'widgets/video-form/ui/VideoWorkspace/VideoWorkspace';
import { videoFormValidationSchema } from 'widgets/video-form/validationSchema';

import styles from './VideoForm.module.css';
import { VideoFormHeaderPanel } from './VideoFormHeaderPanel/VideoFormHeaderPanel';

type TProps = {
  values: TVideoPostFields;
};

export const VideoForm = ({ values }: TProps) => {
  const { config } = useConfig();
  const methods = useForm<TVideoPostFields>({
    mode: 'onChange',
    values,
    resolver: zodResolver(videoFormValidationSchema(config)),
  });

  return (
    <FormProvider {...methods}>
      <form className={styles.root}>
        <VideoFormHeaderPanel />
        <div className={styles.content}>
          <div className={styles.editWrapper}>
            <VideoWorkspace />
          </div>
          <VideoRightPanel />
        </div>
      </form>
    </FormProvider>
  );
};
