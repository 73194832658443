import { CareersRoute } from 'pages/manager/careers';
import { CreateRoute } from 'pages/manager/create';
import { EditRoute } from 'pages/manager/edit';
import { SocialNetworksRoute } from 'pages/manager/social-networks';
import { TeamAchievementsRoute } from 'pages/manager/team-achievements';
import { TProtectedRoute } from 'shared/types/router';

export const ManagerRoutes: Record<string, TProtectedRoute> = {
  Create: CreateRoute,
  Edit: EditRoute,
  Careers: CareersRoute,
  SocialNetworks: SocialNetworksRoute,
  TeamAchievements: TeamAchievementsRoute,
};
