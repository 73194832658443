import axios, { AxiosRequestConfig } from 'axios';
import { APIS } from 'shared/api/constants';
import { onRejection, onResponse } from 'shared/api/interceptors';
import { TAPIName, TClients, TResponseDataType } from 'shared/api/types';
import { getHeaders } from 'shared/api/utils';
import { isProduction, LocalStorageKeys } from 'shared/constants';
import snakecaseKeys from 'snakecase-keys';

const clients: TClients = Object.keys(APIS).reduce((obj, key) => {
  return Object.assign(obj, {
    [key]: axios.create({ baseURL: APIS[key as keyof typeof APIS] }),
  });
}, {} as TClients);

const savedSandbox = localStorage.getItem(
  LocalStorageKeys.backendSandbox,
) as TAPIName;

const baseURL =
  savedSandbox && !isProduction ? APIS[savedSandbox] : process.env.API_URL;

let client = axios.create({ baseURL });

client.interceptors.response.use(onResponse, onRejection);

export const get = <ResponseData, PAGE extends boolean = false>(
  resource: string,
  config?: AxiosRequestConfig,
) =>
  client.get<TResponseDataType<ResponseData, PAGE>>(resource, {
    ...config,
    headers: { ...getHeaders(), ...(config?.headers ?? {}) },
  });

export const post = <ResponseData, PAGE extends boolean = false>(
  resource: string,
  data: AxiosRequestConfig['data'],
) =>
  client.post<TResponseDataType<ResponseData, PAGE>>(
    resource,
    snakecaseKeys(data, { deep: true }),
    {
      headers: getHeaders(),
    },
  );

export const patch = <ResponseData, PAGE extends boolean = false>(
  resource: string,
  data: AxiosRequestConfig['data'],
) =>
  client.patch<TResponseDataType<ResponseData, PAGE>>(
    resource,
    snakecaseKeys(data, { deep: true }),
    {
      headers: getHeaders(),
    },
  );

export const del = (resource: string) =>
  client.delete(resource, { headers: getHeaders() });

export const setApi = (value: keyof typeof APIS) => {
  client.interceptors.response.clear();
  client = clients[value];
  client.interceptors.response.use(onResponse, onRejection);
};
