import React from 'react';
import { TTableSubComponents, TTableWithRef } from 'shared/components/SimpleTable';
import { TSortableCellProps } from 'shared/components/SimpleTable/components/THeadCell/THeadCell';
import { Text } from 'shared/components/Text/Text';
import {
  TColumnConfig,
  TDataItem,
  TSortOptions,
} from 'widgets/elements-list-table/types';

type TProps<T extends TDataItem, SortBy extends string> = Omit<
  TColumnConfig<T, SortBy>,
  'headCellProps'
> & {
  sortOptions: TSortOptions<SortBy>;
  onSort: (sortOptions: TSortOptions<SortBy>) => void;
  headCellProps: TSortableCellProps<SortBy>;
  tableComponent: TTableWithRef & TTableSubComponents<SortBy>;
};

export const CommonListTableHeadCellSortable = <
  T extends TDataItem,
  SortBy extends string,
>({
  title,
  tableComponent: Table,
  sortOptions,
  onSort,
  headCellProps = {},
}: TProps<T, SortBy>) => {
  return (
    <Table.HeadCell
      {...headCellProps}
      sortOptions={sortOptions}
      sortable
      onSort={onSort}
    >
      <Text>{title}</Text>
    </Table.HeadCell>
  );
};
