// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditorImageElement-module__image--AqvTu {\n  max-width: 100%;\n  border-radius: var(--border-radius-l);\n}\n\n.EditorImageElement-module__focusedImage--ZWrqa {\n  box-shadow: 0 0 0 2px var(--gray-250);\n}\n\n.EditorImageElement-module__buttonWrapper--xMt7p {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.EditorImageElement-module__button--od4K5 {\n  padding: 8px;\n  background-color: var(--gray-50);\n  border-radius: var(--border-radius-max);\n  color: var(--gray-450);\n}\n\n.EditorImageElement-module__imageWrapper--ZDXQe {\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Editor/elements/EditorImageElement/EditorImageElement.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,qCAAqC;AACvC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,gCAAgC;EAChC,uCAAuC;EACvC,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".image {\n  max-width: 100%;\n  border-radius: var(--border-radius-l);\n}\n\n.focusedImage {\n  box-shadow: 0 0 0 2px var(--gray-250);\n}\n\n.buttonWrapper {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.button {\n  padding: 8px;\n  background-color: var(--gray-50);\n  border-radius: var(--border-radius-max);\n  color: var(--gray-450);\n}\n\n.imageWrapper {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "EditorImageElement-module__image--AqvTu",
	"focusedImage": "EditorImageElement-module__focusedImage--ZWrqa",
	"buttonWrapper": "EditorImageElement-module__buttonWrapper--xMt7p",
	"button": "EditorImageElement-module__button--od4K5",
	"imageWrapper": "EditorImageElement-module__imageWrapper--ZDXQe"
};
export default ___CSS_LOADER_EXPORT___;
