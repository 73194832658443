import 'cropperjs/dist/cropper.css';
import './initialCropperStyle.css';

import { useRef } from 'react';
import {
  TInitCropperProps,
  useCropper,
} from 'shared/components/cropper/Cropper/useCropper';
import { CropperPanel } from 'shared/components/cropper/CropperPanel/CropperPanel';
import { TCropperMode } from 'shared/components/cropper/types';

import styles from './Cropper.module.css';

export type TCropperProps = {
  imageBase64Url: string;
  defaultAspectRatio: number;
  mode?: TCropperMode;
};

export const Cropper = ({
  imageBase64Url,
  defaultAspectRatio,
  mode = 'strict',
  ...initCropperProps
}: TCropperProps & TInitCropperProps) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const { cropper } = useCropper({
    aspectRatio: defaultAspectRatio,
    imgRef,
    ...initCropperProps,
  });

  return (
    <div className={styles.root}>
      <div className={styles.imageWrapper}>
        <img
          alt="cropped image"
          className={styles.img}
          ref={imgRef}
          src={imageBase64Url}
        />
      </div>
      {cropper && (
        <CropperPanel
          cropper={cropper}
          defaultAspectRatio={defaultAspectRatio}
          mode={mode}
        />
      )}
    </div>
  );
};
