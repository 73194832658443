// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditorSliderElement-module__image--1MGeo {\n  max-width: 100%;\n  border-radius: var(--border-radius-l);\n}\n\n.EditorSliderElement-module__buttonWrapper--J5D4p {\n  position: absolute;\n  z-index: 1;\n  top: 16px;\n  right: 16px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.EditorSliderElement-module__button--OrXCh {\n  padding: 8px;\n  background-color: var(--gray-50);\n  border-radius: var(--border-radius-max);\n  color: var(--gray-450);\n}\n\n.EditorSliderElement-module__sliderWrapper--x7uuW {\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Editor/elements/EditorSliderElement/EditorSliderElement.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,qCAAqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,gCAAgC;EAChC,uCAAuC;EACvC,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".image {\n  max-width: 100%;\n  border-radius: var(--border-radius-l);\n}\n\n.buttonWrapper {\n  position: absolute;\n  z-index: 1;\n  top: 16px;\n  right: 16px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.button {\n  padding: 8px;\n  background-color: var(--gray-50);\n  border-radius: var(--border-radius-max);\n  color: var(--gray-450);\n}\n\n.sliderWrapper {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "EditorSliderElement-module__image--1MGeo",
	"buttonWrapper": "EditorSliderElement-module__buttonWrapper--J5D4p",
	"button": "EditorSliderElement-module__button--OrXCh",
	"sliderWrapper": "EditorSliderElement-module__sliderWrapper--x7uuW"
};
export default ___CSS_LOADER_EXPORT___;
