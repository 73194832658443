import { useFetchManagerSocialNetworksQuery } from 'entities/managerSocialNetworks/queries';
import { useFetchSocialNetworksQuery } from 'entities/socialNetworks/queries';
import { combineManagerSocialNetworks } from 'pages/manager/social-networks/ManagerSocialNetworksPage/helpers';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'shared/routes';

export const useManagerSocialNetworks = () => {
  const navigate = useNavigate();
  const { teamId = '', managerId = '' } = useParams<{
    teamId: string;
    managerId: string;
  }>();

  const {
    data: { data: managerSocialNetworks = [], page = 1, total = 0 } = {},
    isLoading: isManagerSocialNetworksLoading,
    error: managerSocialNetworksError,
    changePage,
  } = useFetchManagerSocialNetworksQuery({
    filter: managerId ? { 'filter[manager_id][eq]': managerId } : {},
  });
  const {
    data: { data: socialNetworksData } = {},
    isLoading: isSocialNetworksLoading,
    error: socialNetworksError,
  } = useFetchSocialNetworksQuery();

  const combinedSocialNetworks = useMemo(() => {
    if (!managerSocialNetworks || !socialNetworksData) {
      return [];
    }

    return combineManagerSocialNetworks(managerSocialNetworks, socialNetworksData);
  }, [managerSocialNetworks, socialNetworksData]);

  const handleConfirmButtonClick = () => {
    navigate(routes.managerCareers.as(teamId, managerId), {
      replace: true,
    });
  };

  return {
    socialLinks: combinedSocialNetworks,
    isLoading: isManagerSocialNetworksLoading || isSocialNetworksLoading,
    error: managerSocialNetworksError || socialNetworksError,
    page,
    total,
    handleConfirmButtonClick,
    changePage,
  };
};
