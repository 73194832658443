import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CleanupQueries } from 'app/providers/CleanupQueries';
import { Router } from 'app/Router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { NotificationContainer } from 'shared/components/Notification';
import { msFromMinutes } from 'shared/lib/date';

// router, error handling, analytics, other wrappers goes here

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: msFromMinutes(1),
      refetchOnWindowFocus: false,
    },
  },
});

export const Providers = () => (
  <QueryClientProvider client={queryClient}>
    <CleanupQueries>
      <ReactQueryDevtools />
      <DndProvider backend={HTML5Backend}>
        <Router />
      </DndProvider>
      <NotificationContainer />
    </CleanupQueries>
  </QueryClientProvider>
);
