import { getPlayerSocialNetworksColumnsConfig } from 'pages/player/social-networks/PlayerSocialNetworksPage/columnsConfig';
import { TPlayerSocialNetworkItem } from 'pages/player/social-networks/PlayerSocialNetworksPage/types';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './PlayerSocialNetworksTable.module.css';

type TProps = {
  socialLinks: TPlayerSocialNetworkItem[];
};

export const PlayerSocialNetworksTable = ({ socialLinks }: TProps) => {
  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getPlayerSocialNetworksColumnsConfig,
  });

  return (
    <div className={styles.root}>
      <CommonListTable
        activeItemId={activeItemId}
        columns={columnsConfig}
        data={socialLinks}
        headRowClassName={styles.headRow}
        rowClassName={styles.rowGridTemplate}
      />
    </div>
  );
};
