// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomDragLayer-module__layer--mEXQ4 {\n  position: fixed;\n  z-index: var(--tooltip-index);\n  pointer-events: none;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/CustomDragLayer/CustomDragLayer.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,6BAA6B;EAC7B,oBAAoB;EACpB,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;AACd","sourcesContent":[".layer {\n  position: fixed;\n  z-index: var(--tooltip-index);\n  pointer-events: none;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layer": "CustomDragLayer-module__layer--mEXQ4"
};
export default ___CSS_LOADER_EXPORT___;
