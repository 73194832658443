import { useState } from 'react';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { OverlayModal } from 'shared/components/OverlayModal/OverlayModal';
import { VideoFormPreviewContent } from 'widgets/video-form/ui/VideoFormHeaderPanel/VideoFormPreviewButton/VideoFormPreviewContent';

import styles from './VideoFormPreviewButton.module.css';

export const VideoFormPreviewButton = () => {
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);

  const openPreviewModal = () => {
    setIsOpenPreviewModal(true);
  };

  const closePreviewModal = () => {
    setIsOpenPreviewModal(false);
  };

  return (
    <>
      <IconButton
        className={styles.button}
        icon="play"
        iconSize="m"
        onClick={openPreviewModal}
      />
      <OverlayModal
        innerClassName={styles.modalInner}
        opened={isOpenPreviewModal}
        onClose={closePreviewModal}
      >
        <IconButton
          className={styles.closeButton}
          icon="x"
          iconSize="s"
          onClick={closePreviewModal}
        />
        <VideoFormPreviewContent />
      </OverlayModal>
    </>
  );
};
