import { newsCategoriesApiKeys } from 'entities/newsCategories/consts';
import { TNewsCategory, TNewsEditCategory } from 'entities/newsCategories/types';
import { del, get, patch, post } from 'shared/api/axios';
import { mapPaginatedResponse } from 'shared/api/pagination';
import { TFetchParams } from 'shared/api/types';
import { TEntityId, TTranslationsObject } from 'shared/types/common';

export const fetchNewsCategories = ({ params }: TFetchParams) =>
  get<TNewsCategory[], true>(newsCategoriesApiKeys.getNewsCategories, {
    params,
  }).then((response) => mapPaginatedResponse(response.data));

export const createNewsCategory = (
  data: TTranslationsObject<{
    name: string;
  }>,
) =>
  post<TNewsCategory>(newsCategoriesApiKeys.createNewsCategory, {
    newsCategory: data,
  }).then((response) => response.data.data);

export const deleteNewsCategory = (categoryId: TEntityId) =>
  del(newsCategoriesApiKeys.deleteNewsCategory(categoryId)).then(
    (response) => response.data.data,
  );

export const editNewsCategory = ({ id, translations }: TNewsEditCategory) =>
  patch(newsCategoriesApiKeys.editNewsCategory(id), {
    newsCategory: { translations },
  }).then((response) => response.data.data);
