// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cropper-module__root--UKZdd {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-height: 448px;\n  padding: 24px 24px 16px 24px;\n  gap: 8px;\n}\n\n.Cropper-module__imageWrapper--HkxNe {\n  position: relative;\n  height: 360px;\n  width: 100%;\n  border-radius: var(--border-radius-l);\n  overflow: hidden;\n}\n\n.Cropper-module__loader--PBqpG {\n  background-color: rgba(255, 255, 255, 0.1);\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  z-index: 1;\n  transition: opacity var(--duration-interaction);\n}\n\n.Cropper-module__img--blDE1 {\n  display: block;\n  max-width: 100%;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/cropper/Cropper/Cropper.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,4BAA4B;EAC5B,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,qCAAqC;EACrC,gBAAgB;AAClB;;AAEA;EACE,0CAA0C;EAC1C,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,UAAU;EACV,+CAA+C;AACjD;;AAEA;EACE,cAAc;EACd,eAAe;EACf,YAAY;AACd","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-height: 448px;\n  padding: 24px 24px 16px 24px;\n  gap: 8px;\n}\n\n.imageWrapper {\n  position: relative;\n  height: 360px;\n  width: 100%;\n  border-radius: var(--border-radius-l);\n  overflow: hidden;\n}\n\n.loader {\n  background-color: rgba(255, 255, 255, 0.1);\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  z-index: 1;\n  transition: opacity var(--duration-interaction);\n}\n\n.img {\n  display: block;\n  max-width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Cropper-module__root--UKZdd",
	"imageWrapper": "Cropper-module__imageWrapper--HkxNe",
	"loader": "Cropper-module__loader--PBqpG",
	"img": "Cropper-module__img--blDE1"
};
export default ___CSS_LOADER_EXPORT___;
