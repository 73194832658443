import { ComponentType, useEffect, useMemo, useState } from 'react';
import { FieldValues, Path, UseFormTrigger } from 'react-hook-form';

import { tabNames } from '../constants';
import { TDefaultRightPanelProps, TTabName } from '../types';

type TRightPanelElement<T extends FieldValues> = {
  Component: ComponentType<TDefaultRightPanelProps>;
  fieldName?: Path<T>;
};

type TUseRightPanel<T extends FieldValues> = {
  hasPreview: boolean;
  trigger: UseFormTrigger<T>;
  preview?: TRightPanelElement<T>;
  label?: TRightPanelElement<T>;
  connections?: TRightPanelElement<T>;
  review?: TRightPanelElement<T>;
  importance?: TRightPanelElement<T>;
};

const initialActiveTabs = [tabNames.preview];

export const useRightPanel = <T extends FieldValues>({
  hasPreview,
  trigger,
  preview,
  label,
  connections,
  review,
  importance,
}: TUseRightPanel<T>) => {
  const [activeTab, setActiveTab] = useState<TTabName[]>(initialActiveTabs);
  const toggleTab = (tab: TTabName) => {
    if (activeTab.includes(tab)) {
      setActiveTab((tabs) => tabs.filter((item) => item !== tab));
    } else {
      setActiveTab((tabs) => [...tabs, tab]);
    }
  };
  const isActiveTab = (tab: TTabName) => activeTab.includes(tab);
  const togglePreview = () => toggleTab(tabNames.preview);
  const triggerPreview = () => {
    preview && trigger(preview.fieldName);
  };

  const toggleLabel = () => {
    if (!hasPreview) {
      triggerPreview();
    } else {
      toggleTab(tabNames.label);
    }
  };
  const toggleConnected = () => {
    if (!hasPreview) {
      triggerPreview();
    } else {
      toggleTab(tabNames.connected);
    }
  };
  const toggleVideoReview = () => {
    if (!hasPreview) {
      triggerPreview();
    } else {
      toggleTab(tabNames.review);
    }
  };
  const toggleImportance = () => {
    if (!hasPreview) {
      triggerPreview();
    } else {
      toggleTab(tabNames.importance);
    }
  };
  const onClickNext = (currentTab: TTabName, nextTab: TTabName) => {
    toggleTab(currentTab);
    setActiveTab((tabs) => [...tabs, nextTab]);
  };

  const Preview = useMemo(() => {
    if (preview) {
      const { Component } = preview;
      return (
        <Component
          key="preview"
          isOpen={isActiveTab(tabNames.preview)}
          onClick={togglePreview}
          onClickNext={() => {
            onClickNext(tabNames.preview, tabNames.label);
          }}
        />
      );
    }

    return null;
  }, [isActiveTab(tabNames.preview)]);

  const Label = useMemo(() => {
    if (label) {
      const { Component } = label;
      return (
        <Component
          key="label"
          disabled={!hasPreview}
          isOpen={isActiveTab(tabNames.label)}
          onClick={toggleLabel}
          onClickNext={() => {
            onClickNext(tabNames.label, tabNames.connected);
          }}
        />
      );
    }

    return null;
  }, [isActiveTab(tabNames.label), hasPreview]);

  const Connections = useMemo(() => {
    if (connections) {
      const { Component } = connections;
      const nextTab = review ? tabNames.review : tabNames.importance;
      return (
        <Component
          key="connections"
          disabled={!hasPreview}
          isOpen={isActiveTab(tabNames.connected)}
          onClick={toggleConnected}
          onClickNext={() => {
            onClickNext(tabNames.connected, nextTab);
          }}
        />
      );
    }

    return null;
  }, [isActiveTab(tabNames.connected), hasPreview]);

  const Review = useMemo(() => {
    if (review) {
      const { Component } = review;
      return (
        <Component
          key="review"
          disabled={!hasPreview}
          isOpen={isActiveTab(tabNames.review)}
          onClick={toggleVideoReview}
          onClickNext={() => {
            onClickNext(tabNames.review, tabNames.importance);
          }}
        />
      );
    }

    return null;
  }, [isActiveTab(tabNames.review), hasPreview]);

  const Importance = useMemo(() => {
    if (importance) {
      const { Component } = importance;
      return (
        <Component
          key="importance"
          disabled={!hasPreview}
          isOpen={isActiveTab(tabNames.importance)}
          onClick={toggleImportance}
        />
      );
    }

    return null;
  }, [isActiveTab(tabNames.importance), hasPreview]);

  useEffect(() => {
    if (!hasPreview && activeTab.length > 1) {
      setActiveTab(initialActiveTabs);
    }
  }, [hasPreview]);

  return {
    Preview,
    Label,
    Connections,
    Review,
    Importance,
  };
};
