import { isKeyHotkey } from 'is-hotkey';
import { KeyboardEvent } from 'react';
import { EditorElementTypeEnum, TEditor } from 'shared/components/Editor/types';
import { editorBulletedListController } from 'shared/components/Editor/utils/bulletedList/editorBulletedListController';
import { editorController } from 'shared/components/Editor/utils/editor/editorController';
import { editorParagraphController } from 'shared/components/Editor/utils/paragraph/editorParagraphController';
import { TLangType } from 'shared/types/common';
import { Transforms } from 'slate';

export const editorEnterHandler = (
  editor: TEditor,
  event: KeyboardEvent<HTMLInputElement>,
  defaultLocale: TLangType,
  disableStructureChange: boolean,
) => {
  const { nativeEvent } = event;
  if (isKeyHotkey('shift+enter', nativeEvent)) {
    event.preventDefault();
    Transforms.insertText(editor, '\n');
    return;
  }

  if (isKeyHotkey('enter', nativeEvent) && !disableStructureChange) {
    const listItemElement = editorController.getElement(
      editor,
      EditorElementTypeEnum.ListItem,
    );

    if (listItemElement && listItemElement.children[0].text === '') {
      event.preventDefault();
      editorBulletedListController.toggleBulletedList(editor);
      return;
    }

    const headingTwoElement = editorController.getElement(
      editor,
      EditorElementTypeEnum.HeadingTwo,
    );

    if (headingTwoElement) {
      const headingTwoLastText =
        headingTwoElement.children[headingTwoElement.children.length - 1].text;
      if (headingTwoLastText.length === editor.selection!.anchor.offset) {
        event.preventDefault();
        editorParagraphController.insertParagraph(editor, '', defaultLocale);
        return;
      }
    }
  }
};
