import React from 'react';
import { ECardType, TCardInformation } from 'shared/components/Card/types';
import { Text } from 'shared/components/Text/Text';

import styles from './Information.module.css';

export const OpponentInformation = ({ type, entity }: TCardInformation) => {
  if (type !== ECardType.Opponent) {
    return null;
  }
  const { number, lastName, name } = entity;

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Text
          className={styles.text}
          color="var(--gray-500)"
          size={16}
          tag="p"
          weight="Bold"
        >
          {name}
        </Text>
        <Text
          className={styles.text}
          color="var(--gray-500)"
          size={16}
          tag="p"
          weight="Bold"
        >
          {lastName}
        </Text>
      </div>
      <div className={styles.numberBlock}>
        <Text
          className={styles.text}
          color="var(--gray-500)"
          size={30}
          weight="Bold"
        >
          {number}
        </Text>
      </div>
    </div>
  );
};
