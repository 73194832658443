import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { routes } from 'shared/routes';

import styles from './VideoFormBackButton.module.css';

export const VideoFormBackButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);

  const handleBackButtonClick = () => {
    setIsClosing(true);
  };

  const handleBackButtonClose = () => {
    setIsClosing(false);
  };

  const handleBack = () => {
    navigate(routes.video);
  };

  return (
    <>
      <IconButton
        className={styles.backButton}
        icon="chevron-left"
        onClick={handleBackButtonClick}
      />
      <ConfirmationModal
        description={<>{t('content.video.comeBackPageWarning', { mark: '.' })}</>}
        isOpened={isClosing}
        title={t('shared.backToRoot.confirmationModal.titleWithMark', { mark: '?' })}
        onClose={handleBackButtonClose}
        onConfirm={handleBack}
      />
    </>
  );
};
