import { useFetchPlayerCareersQuery } from 'entities/playerCareers/queries';
import { PlayerCareerModal } from 'feature/player-career-modal/PlayerCareerModal';
import { PlayerLayout } from 'feature/player-layout/ui/PlayerLayout/PlayerLayout';
import { useCreatePlayerCareer } from 'pages/player/careers/PlayerCareersPage/hooks/useCreatePlayerCareer';
import { PlayerCareersTable } from 'pages/player/careers/PlayerCareersPage/PlayerCareersTable/PlayerCareersTable';
import styles from 'pages/player/careers/PlayerCareersPage/PlayerCareersTable/PlayerCareersTable.module.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'shared/routes';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

export const PlayerCareersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamId = '', playerId = '' } = useParams();
  const onClick = () => {
    navigate(routes.playerTeamAchievements.as(teamId, playerId));
  };

  const {
    data: { data: careers = [], page = 1, total = 0 } = {},
    isLoading,
    error,
    changePage,
  } = useFetchPlayerCareersQuery({
    filter: playerId ? { 'filter[player_id]': playerId } : {},
  });

  const { openCreateModal, createCareerModalProps } = useCreatePlayerCareer();

  return (
    <PlayerLayout
      confirmButtonProps={{
        onClick,
      }}
    >
      <CommonListWrapper
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.career')}
        createButtonProps={{
          content: t('content.createCareer'),
          onClick: openCreateModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: page,
          totalItems: total,
          onPageChange: changePage,
        }}
      >
        <PlayerCareersTable careers={careers} />
      </CommonListWrapper>
      <PlayerCareerModal {...createCareerModalProps} />
    </PlayerLayout>
  );
};
