import { TPhotoGalleryCategory } from 'entities/photoGalleryCategories/types';
import { PhotoGalleryCategoriesActionWrapper } from 'pages/settings/categories/PhotoGalleryCategoriesPage/PhotoGalleryCategoriesActionWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from './PhotoGalleryCategoriesPage.module.css';

export const getPhotoGalleryCategoriesColumnsConfig: TConfigCreator<
  TPhotoGalleryCategory
> = (defaultLocale, activeItemId, setActiveItemId) => {
  const { t } = useTranslation();

  return [
    {
      title: t('content.category'),
      cellClassName: styles.publicationNameColumn,
      render: (item) => (
        <Text className={styles.text}>
          {item?.translations[defaultLocale]?.name}
        </Text>
      ),
    },
    {
      title: '',
      render: (item) => (
        <PhotoGalleryCategoriesActionWrapper
          activeItemId={activeItemId}
          photoGalleryCategory={item}
          setActiveItemId={setActiveItemId}
        />
      ),
    },
  ];
};
