import { useLocales } from 'entities/config/lib/useLocales';
import { useState } from 'react';
import { TEntityId } from 'shared/types/common';
import {
  TConfigCreator,
  TDataItem,
  TFilter,
} from 'widgets/elements-list-table/types';

type TUseCommonListTable<
  T extends TDataItem,
  SortBy extends string,
  FilterType extends TFilter,
> = {
  configCreator: TConfigCreator<T, SortBy, FilterType>;
};

export const useCommonListTable = <
  T extends TDataItem,
  SortBy extends string,
  FilterType extends TFilter,
>({
  configCreator,
}: TUseCommonListTable<T, SortBy, FilterType>) => {
  const { defaultLocale } = useLocales();
  const [activeItemId, setActiveItemId] = useState<TEntityId | null>(null);

  const columnsConfig = configCreator(defaultLocale, activeItemId, setActiveItemId);

  return {
    activeItemId,
    columnsConfig,
    setActiveItemId,
  };
};
