import { TNewsPost } from 'entities/news/types';
import { getNewsColumnsConfig } from 'pages/news/NewsPage/columnsConfig';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './NewsTable.module.css';

type TProps = {
  news: TNewsPost[];
};

export const NewsPageTable = ({ news }: TProps) => {
  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getNewsColumnsConfig,
  });

  return (
    <CommonListTable
      activeItemId={activeItemId}
      columns={columnsConfig}
      data={news}
      rowClassName={styles.rowGridTemplate}
    />
  );
};
