import cn from 'classnames';
import React from 'react';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';

import styles from './CardButton.module.css';

type TProps = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
};

export const CardButton = ({ title, onClick, disabled }: TProps) => {
  return (
    <button
      className={cn(styles.wrapper, { [styles.disable]: disabled })}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={styles.content}>
        <Icon className={styles.icon} kind={'plus'} />
        <Text size={14} weight="SemiBold">
          {title}
        </Text>
      </div>
    </button>
  );
};
