// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TCell-module__cell--N0Xv7 {\n  display: flex;\n  align-items: center;\n\n  padding: 5px 12px;\n}\n\n  .TCell-module__cell--N0Xv7:last-child {\n    padding-right: 7px;\n  }\n\n  .TCell-module__cell--N0Xv7:first-child {\n    padding-left: 7px;\n  }\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/SimpleTable/components/TCell/TCell.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;;EAEnB,iBAAiB;AASnB;;EAPE;IACE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".cell {\n  display: flex;\n  align-items: center;\n\n  padding: 5px 12px;\n\n  &:last-child {\n    padding-right: 7px;\n  }\n\n  &:first-child {\n    padding-left: 7px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": "TCell-module__cell--N0Xv7"
};
export default ___CSS_LOADER_EXPORT___;
