import { newsApiKeys } from 'entities/news/consts';
import { mapNewsPostsResponse } from 'entities/news/mapper/mapNewsPostsResponse';
import { del, get, patch, post } from 'shared/api/axios';
import { mapPaginatedResponse } from 'shared/api/pagination';
import { TFetchParams } from 'shared/api/types';
import { TEntityId, TLangType } from 'shared/types/common';

import {
  TAPINewsPost,
  TCreateNewsPostDTO,
  TDeleteNewsPostParams,
  TEditNewsPostDTO,
} from './types';

export const fetchNews = ({ params }: TFetchParams, defaultLocale: TLangType) =>
  get<TAPINewsPost[], true>(newsApiKeys.getNews, {
    params,
  }).then((response) =>
    mapNewsPostsResponse(mapPaginatedResponse(response.data), defaultLocale),
  );

export const fetchNewsPost = (newsPostId: TEntityId) =>
  get<TAPINewsPost>(newsApiKeys.getNewsPost(newsPostId)).then(
    (response) => response.data.data,
  );

export const createNewsPost = (newsPostData: TCreateNewsPostDTO) =>
  post(newsApiKeys.createNewsPost, newsPostData);

export const editNewsPost = ({ id, ...newsPostData }: TEditNewsPostDTO) =>
  patch(newsApiKeys.editNewsPost(id), newsPostData);

export const deleteNewsPost = ({ newsPostId }: TDeleteNewsPostParams) =>
  del(newsApiKeys.deleteNewsPost(newsPostId));
