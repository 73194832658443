// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OverlayModal-module__root--tZZBH {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow-y: auto;\n\n  background-color: rgba(31, 31, 31, 0.8);\n  z-index: var(--modal-index);\n  cursor: pointer;\n  padding: 36px;\n}\n\n.OverlayModal-module__inner--WM_LZ {\n  max-width: -moz-max-content;\n  max-width: max-content;\n  width: 100%;\n  margin: auto;\n  cursor: default;\n}\n\n.OverlayModal-module__inner--WM_LZ:focus-visible {\n    outline: none;\n  }\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/OverlayModal/OverlayModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;;EAET,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;;EAEhB,uCAAuC;EACvC,2BAA2B;EAC3B,eAAe;EACf,aAAa;AACf;;AAEA;EACE,2BAAsB;EAAtB,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,eAAe;AAKjB;;AAHE;IACE,aAAa;EACf","sourcesContent":[".root {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow-y: auto;\n\n  background-color: rgba(31, 31, 31, 0.8);\n  z-index: var(--modal-index);\n  cursor: pointer;\n  padding: 36px;\n}\n\n.inner {\n  max-width: max-content;\n  width: 100%;\n  margin: auto;\n  cursor: default;\n\n  &:focus-visible {\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OverlayModal-module__root--tZZBH",
	"inner": "OverlayModal-module__inner--WM_LZ"
};
export default ___CSS_LOADER_EXPORT___;
