// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LocalesInput-module__root--sl4eS {\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  gap: 8px;\n}\n\n.LocalesInput-module__field--eVjwv {\n  width: 100%;\n}\n\n.LocalesInput-module__input--uUX40 {\n  color: var(--gray-500);\n}\n\n.LocalesInput-module__input--uUX40::-moz-placeholder {\n    opacity: 1;\n  }\n\n.LocalesInput-module__input--uUX40::placeholder {\n    opacity: 1;\n  }\n\n.LocalesInput-module__button--lMnvD {\n  margin-top: 20px;\n  width: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/5-feature/locales-input/LocalesInput.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,QAAQ;AACV;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;AAKxB;;AAHE;IACE,UAAU;EACZ;;AAFA;IACE,UAAU;EACZ;;AAGF;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".root {\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  gap: 8px;\n}\n\n.field {\n  width: 100%;\n}\n\n.input {\n  color: var(--gray-500);\n\n  &::placeholder {\n    opacity: 1;\n  }\n}\n\n.button {\n  margin-top: 20px;\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LocalesInput-module__root--sl4eS",
	"field": "LocalesInput-module__field--eVjwv",
	"input": "LocalesInput-module__input--uUX40",
	"button": "LocalesInput-module__button--lMnvD"
};
export default ___CSS_LOADER_EXPORT___;
