import { TNewsCategory } from 'entities/newsCategories/types';
import { getNewsCategoriesColumnsConfig } from 'pages/settings/categories/NewsCategoriesPage/columnsConfig';
import React from 'react';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './NewsCategoriesPage.module.css';

type TProps = {
  categories: TNewsCategory[];
};

export const NewsCategoriesPageTable = ({ categories }: TProps) => {
  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getNewsCategoriesColumnsConfig,
  });

  return (
    <CommonListTable
      activeItemId={activeItemId}
      columns={columnsConfig}
      data={categories}
      rowClassName={styles.rowGridTemplate}
    />
  );
};
