import {
  useDeleteVideoCategoryMutation,
  useEditVideoCategoryMutation,
} from 'entities/videoCategories/mutations';
import { TVideoCategory } from 'entities/videoCategories/types';
import { CategoriesActions } from 'feature/categories/CategoriesActions/Actions';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { clearMetaDataFromBase64Url } from 'shared/lib/clearMetaDataFromBase64Url';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';
import { getFileFromUrl } from 'shared/lib/getFileFromUrl';
import { toString } from 'shared/lib/toString';
import { TEntityId, TGroupAction } from 'shared/types/common';
import { TVideoCategoriesFormFields } from 'widgets/videoCategoriesForm/types';
import { VideoCategoriesFormModal } from 'widgets/videoCategoriesForm/VideoCategoriesFormModal';

type TProps = {
  activeItemId: TEntityId | null;
  setActiveItemId: (id: TEntityId | null) => void;
  videoCategory: TVideoCategory;
};

export const VideoCategoriesActionWrapper = ({
  activeItemId,
  setActiveItemId,
  videoCategory,
}: TProps) => {
  const { t } = useTranslation();
  const { mutateAsync: deleteCategory, isLoading: isLoadingDelete } =
    useDeleteVideoCategoryMutation();
  const { mutateAsync: editCategory, isLoading: isLoadingEdit } =
    useEditVideoCategoryMutation();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentCategory, setCurrentCategory] =
    useState<TVideoCategoriesFormFields>();

  const openDeleteModalHandler = useCallback(() => {
    setShowConfirmModal(true);
  }, []);

  const closeDeleteModalHandler = useCallback(() => {
    setActiveItemId(null);
    setShowConfirmModal(false);
  }, []);

  const openEditModalHandler = () => {
    setShowEditModal(true);
  };
  const closeEditModalHandler = () => {
    setActiveItemId(null);
    setShowEditModal(false);
  };

  const actions: TGroupAction[] = [
    {
      title: t('content.edit'),
      icon: 'edit-02',
      action: openEditModalHandler,
    },
    {
      title: t('content.delete'),
      icon: 'trash',
      action: openDeleteModalHandler,
    },
  ];

  const isLoading = isLoadingDelete || isLoadingEdit;

  const fetchData = async () => {
    if (videoCategory) {
      const picture = await getFileFromUrl(videoCategory.picture.x3);
      setCurrentCategory({
        importance: toString(videoCategory.importance),
        picture,
        translations: videoCategory.translations,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeItemId, videoCategory]);

  const editVideoCategory = async (fields: TVideoCategoriesFormFields) => {
    setShowEditModal(false);
    if (activeItemId) {
      const base64 = await getBase64FromFile(fields.picture);

      editCategory({
        categoryId: activeItemId,
        data: {
          ...fields,
          picture: {
            data: clearMetaDataFromBase64Url(base64),
            filename: fields!.picture!.name,
          },
          importance: fields.importance ?? '0',
        },
      });
    }
    setActiveItemId(null);
  };

  const onConfirm = () => {
    if (activeItemId) {
      deleteCategory(activeItemId);
    }
    setShowConfirmModal(false);
    setActiveItemId(null);
  };

  return (
    <>
      <CategoriesActions
        actions={actions}
        activeItemId={activeItemId}
        id={videoCategory?.id}
        isLoading={isLoading}
        setActiveItemId={setActiveItemId}
      />
      <ConfirmationModal
        isOpened={showConfirmModal}
        title={t('content.settings.deleteCategory', { mark: '?' })}
        onClose={closeDeleteModalHandler}
        onConfirm={onConfirm}
      />
      {currentCategory && (
        <VideoCategoriesFormModal
          actionSubmit={editVideoCategory}
          closeModal={closeEditModalHandler}
          initialValues={currentCategory}
          isOpened={showEditModal}
          subtitle={t('content.settings.nameCategory')}
          title={t('content.settings.editCategory')}
        />
      )}
    </>
  );
};
