// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TranslationInputs-module__root--k_V2Q {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.TranslationInputs-module__inputWrapper--rehnF {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.TranslationInputs-module__input--DraNS::-moz-placeholder {\n    color: var(--gray-500);\n  }\n\n.TranslationInputs-module__input--DraNS::placeholder {\n    color: var(--gray-500);\n  }\n", "",{"version":3,"sources":["webpack://./src/5-feature/manager-career-modal/ui/TranslationInputs/TranslationInputs.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAGE;IACE,sBAAsB;EACxB;;AAFA;IACE,sBAAsB;EACxB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.inputWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.input {\n  &::placeholder {\n    color: var(--gray-500);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "TranslationInputs-module__root--k_V2Q",
	"inputWrapper": "TranslationInputs-module__inputWrapper--rehnF",
	"input": "TranslationInputs-module__input--DraNS"
};
export default ___CSS_LOADER_EXPORT___;
