import { TManagerTeamAchievement } from 'entities/managerTeamAchievements/types';
import { getManagerTeamAchievementsColumnsConfig } from 'pages/manager/team-achievements/ManagerTeamAchievementsPage/columnsConfig';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './ManagerTeamAchievementsTable.module.css';

type TProps = {
  managerTeamAchievements: TManagerTeamAchievement[];
};

export const ManagerTeamAchievementsTable = ({
  managerTeamAchievements,
}: TProps) => {
  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getManagerTeamAchievementsColumnsConfig,
  });

  return (
    <div className={styles.root}>
      <CommonListTable
        activeItemId={activeItemId}
        columns={columnsConfig}
        data={managerTeamAchievements}
        headRowClassName={styles.headRow}
        rowClassName={styles.rowGridTemplate}
      />
    </div>
  );
};
