import {
  TStoriesSortParams,
  useFetchStoryGroupsQuery,
} from 'entities/stories/queries';
import { useCreateStory } from 'pages/stories/StoriesPage/hooks/useCreateStory';
import { StoriesTable } from 'pages/stories/StoriesPage/StoriesTable';
import { createSortQuery } from 'pages/video/VideoPage/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImageUploadModal } from 'shared/components/ImageUploadModal/ImageUploadModal';
import { getImageSettings } from 'shared/constants';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';
import { useCommonListTableSort } from 'widgets/elements-list-table/hooks/useCommonListTableSort';

import styles from './StoriesPage.module.css';

const DEFAULT_STORIES_SORT_OPTIONS = {
  direction: 'desc',
  sortBy: 'created_at',
} as const;

export const StoriesPage = () => {
  const { t } = useTranslation();

  const {
    isImageUploadOpened,
    handleFileAdd,
    handleCloseUploadModal,
    handleOpenUploadModal,
  } = useCreateStory();
  const { sortOptions, handleSort } = useCommonListTableSort<TStoriesSortParams>(
    DEFAULT_STORIES_SORT_OPTIONS,
  );

  const {
    data: { data: storyGroups = [], total = 0, page = 1 } = {},
    isLoading,
    error,
    changePage,
  } = useFetchStoryGroupsQuery({
    sort: createSortQuery(sortOptions),
  });

  return (
    <div className={styles.root}>
      <CommonListWrapper
        emptyComponentDescription={t('content.story.createFirstStories')}
        error={error}
        isEmpty={storyGroups.length === 0 && !isLoading}
        isLoading={isLoading}
        title={t('content.story.stories')}
        createButtonProps={{
          icon: 'plus',
          iconPosition: 'before',
          iconSize: 'xs',
          onClick: handleOpenUploadModal,
          content: t('content.story.creatStories'),
        }}
        paginationProps={{
          totalItems: total,
          selectedPage: page,
          onPageChange: changePage,
        }}
      >
        <StoriesTable
          sortOptions={sortOptions}
          storyGroups={storyGroups}
          onSort={handleSort}
        />
      </CommonListWrapper>
      <ImageUploadModal
        aspectRatio={getImageSettings().stories.background.aspectRatio}
        hint={getImageSettings().stories.background.hint}
        opened={isImageUploadOpened}
        title={t('content.story.loadImage')}
        withCropping
        onClose={handleCloseUploadModal}
        onConfirm={handleFileAdd}
      />
    </div>
  );
};
