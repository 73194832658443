import { useQueryClient } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { playersQueryKeys } from 'entities/players/api/consts';
import { useEditPlayerMutation } from 'entities/players/api/mutations';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { notify } from 'shared/components/Notification';
import { toString } from 'shared/lib/toString';
import { routes } from 'shared/routes';
import { mapPlayerFormFieldsToDTO } from 'widgets/player-form/mapper/mapPlayerFormFieldsToDTO';
import { TPlayerFormFields } from 'widgets/player-form/types/types';

export const useEditPlayer = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { locales } = useLocales();
  const navigate = useNavigate();
  const { teamId, playerId } = useParams<{
    teamId: string;
    playerId: string;
  }>();

  const { mutate, isLoading } = useEditPlayerMutation({
    onSuccess: (player) => {
      queryClient.setQueryData(
        [playersQueryKeys.player, toString(player.id)],
        player,
      );
      if (teamId) {
        navigate(routes.playerSocialNetworks.as(teamId, player.id));
      }
    },
    onError: () => {
      notify(t('content.player.notifyNotEditPlayer'), { type: 'error' });
    },
  });

  const editPlayer = async (playerFormFields: TPlayerFormFields) => {
    if (playerId) {
      const playerDTO = await mapPlayerFormFieldsToDTO(playerFormFields, locales);

      mutate({ ...playerDTO, playerId });
    }
  };

  return {
    isLoading,
    editPlayer,
  };
};
