import { TSocialNetworkModalValue } from 'feature/social-network-modal/types';
import { useDeletePlayerSocialNetwork } from 'pages/player/social-networks/PlayerSocialNetworksPage/hooks/useDeletePlayerSocialNetwork';
import { useEditPlayerSocialNetwork } from 'pages/player/social-networks/PlayerSocialNetworksPage/hooks/useEditPlayerSocialNetwork';
import { TUsePlayerSocialNetworksActions } from 'pages/player/social-networks/PlayerSocialNetworksPage/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import { toString } from 'shared/lib/toString';
import { TGroupAction } from 'shared/types/common';

export const usePlayerSocialNetworksActions = ({
  playerSocialNetwork,
  activeSocialLinkId,
  setActiveSocialLinkId,
}: TUsePlayerSocialNetworksActions) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const [isEditModalOpen, setIsEditModalOpen] = useBoolean(false);
  const { id, playerId, socialNetwork, url } = playerSocialNetwork;

  const { isDeletingPlayerSocialNetwork, deletePlayerSocialNetwork } =
    useDeletePlayerSocialNetwork();
  const { isEditingPlayerSocialNetwork, editPlayerSocialNetwork } =
    useEditPlayerSocialNetwork();

  const isActive = id === activeSocialLinkId;

  const actions: TGroupAction[] = useMemo(
    () => [
      {
        title: t('content.edit'),
        icon: 'edit-02',
        action: () => setIsEditModalOpen(true),
      },
      {
        title: t('content.delete'),
        icon: 'trash',
        action: () => setIsDeleteModalOpen(true),
      },
    ],
    [setIsEditModalOpen, setIsDeleteModalOpen],
  );

  const handleActionsButtonClick = () => {
    setActiveSocialLinkId(isActive ? null : id);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setActiveSocialLinkId(null);
  };

  const handleConfirmDeletion = () => {
    handleCloseDeleteModal();
    deletePlayerSocialNetwork(id);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setActiveSocialLinkId(null);
  };

  const handleConfirmEditing = ({
    url: newUrl,
    socialNetworkId: newSocialNetworkId,
  }: TSocialNetworkModalValue) => {
    editPlayerSocialNetwork(
      {
        id,
        playerId,
        url: newUrl,
        socialNetworkId: newSocialNetworkId,
      },
      handleCloseEditModal,
    );
  };

  return {
    actionsProps: {
      actions,
      activeItemId: activeSocialLinkId,
      isLoading: isDeletingPlayerSocialNetwork,
      isOpen: isActive,
      onIconClick: handleActionsButtonClick,
    },
    deleteModalProps: {
      onClose: handleCloseDeleteModal,
      onConfirm: handleConfirmDeletion,
      isOpened: isDeleteModalOpen,
      description: t('content.deleteWarning'),
      title: t('content.deleteSocial'),
    },
    editModalProps: {
      initialValue: {
        url,
        socialNetworkId: toString(socialNetwork.id),
      },
      title: t('content.editLink'),
      closeModal: handleCloseEditModal,
      actionSubmit: handleConfirmEditing,
      isOpened: isEditModalOpen,
      isLoading: isEditingPlayerSocialNetwork,
    },
  };
};
