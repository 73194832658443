import { BackButton } from 'pages/stories/edit/EditStory/components/forms/components/BackButton/BackButton';
import { FontSettings } from 'pages/stories/edit/EditStory/components/forms/components/FontSettings/FontSettings';
import {
  alignmentOptions,
  fontSizeOptions,
} from 'pages/stories/edit/EditStory/components/forms/constants';
import {
  TCommonElementFormProps,
  TOption,
  TTextElementFormValues,
} from 'pages/stories/edit/EditStory/components/forms/types';
import { useStoryEditorContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorDispatchContext';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Divider } from 'shared/components/Divider';
import { Form, ModeSelectField, SimpleSelect } from 'shared/components/Form';
import { ColorInput } from 'shared/components/Form/ColorInput/ColorInput';

import styles from '../ElementForm.module.css';

const labelResolver = (option: TOption) => option.name;
const valueResolver = (option: TOption) => option.value;

export const TextElementForm = ({
  selectedElement,
}: TCommonElementFormProps<'text'>) => {
  const { t } = useTranslation();
  const { selectedLocale } = useStoryEditorContext();
  const dispatch = useStoryEditorDispatchContext();
  const { handleSubmit, control, watch } = useForm<TTextElementFormValues>({
    values: selectedElement?.translations[selectedLocale].settings,
  });

  const submitForm = (data: TTextElementFormValues) => {
    dispatch({ type: 'updateSelectedStoryElementSettings', payload: data });
  };

  const submit = useMemo(() => handleSubmit(submitForm), [submitForm]);

  useEffect(() => {
    const subscription = watch(() => handleSubmit(submitForm)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, selectedElement]);

  return (
    <div className={styles.root}>
      <Form action={submit} className={styles.formComponent}>
        <FontSettings
          control={control}
          fontFamilyName="fontFamily"
          fontStyleName="fontStyle"
          labelResolver={labelResolver}
          valueResolver={valueResolver}
        />
        <SimpleSelect
          control={control}
          fieldName="fontSize"
          iconSize="xs"
          inline
          inlineInputClassName={styles.fontSize}
          label={t('content.story.size')}
          labelResolver={labelResolver}
          menuPlacement="bottom-end"
          menuWidth={76}
          options={fontSizeOptions}
          placeholder="Select"
          valueResolver={valueResolver}
        />
        <ModeSelectField
          control={control}
          fieldName="textAlign"
          inline
          inlineInputClassName={styles.formField}
          label={t('content.story.alignment')}
          options={alignmentOptions}
        />
        <ColorInput
          control={control}
          fieldName="fill"
          inline
          inlineInputClassName={styles.formField}
          label={t('content.story.textColor')}
          placeholder=""
        />
        <Divider />
        <ColorInput
          control={control}
          fieldName="background"
          inputClassName={styles.input}
          label={t('content.story.backgroundText')}
          placeholder=""
        />
      </Form>
      <BackButton />
    </div>
  );
};
