// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CountrySelect-module__optionsContainer--LA5BM {\n  margin-right: 8px;\n}\n\n  .CountrySelect-module__optionsContainer--LA5BM::-webkit-scrollbar-track {\n    margin: 8px;\n  }\n", "",{"version":3,"sources":["webpack://./src/5-feature/country-select/CountrySelect.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AAKnB;;EAHE;IACE,WAAW;EACb","sourcesContent":[".optionsContainer {\n  margin-right: 8px;\n\n  &::-webkit-scrollbar-track {\n    margin: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionsContainer": "CountrySelect-module__optionsContainer--LA5BM"
};
export default ___CSS_LOADER_EXPORT___;
