import { useFetchNewsQuery } from 'entities/news/queries';
import { NewsPageTable } from 'pages/news/NewsPage/NewsPageTable';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'shared/routes';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

import styles from './NewsPage.module.css';

export const NewsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    data: { data: news = [], page = 1, total = 0 } = {},
    isLoading,
    error,
    changePage,
  } = useFetchNewsQuery();

  return (
    <div className={styles.root}>
      <CommonListWrapper
        emptyComponentDescription={t('content.news.createFirstNews')}
        error={error}
        isEmpty={!news || (total === 0 && !isLoading)}
        isLoading={isLoading}
        title={t('content.news')}
        createButtonProps={{
          content: t('content.news.createNews'),
          onClick: () => navigate(routes.createNewsPost),
        }}
        paginationProps={{
          selectedPage: page,
          totalItems: total,
          onPageChange: changePage,
        }}
      >
        <NewsPageTable news={news} />
      </CommonListWrapper>
    </div>
  );
};
