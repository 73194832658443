import cn from 'classnames';
import i18n from 'i18next';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Field } from 'shared/components/Form';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { InputDatePicker } from 'shared/components/InputDatePicker/InputDatePicker';
import { TODAY } from 'shared/constants';
import { TPlayerFormFields } from 'widgets/player-form/types/types';
import { PLAYER_FORM_FROM_DATE } from 'widgets/player-form/ui/PlayerForm/PlayerForm';
import styles from 'widgets/player-form/ui/PlayerForm/PlayerForm.module.css';

type TProps = {
  control: Control<TPlayerFormFields>;
};

const getDiscardedStatusLabels = (): Record<string, string> => ({
  false: i18n.t('content.inTeam'),
  true: i18n.t('content.leaveFromTeam'),
});

export const PlayerFormDiscarded = ({ control }: TProps) => {
  const { t } = useTranslation();
  const { field } = useController({
    control,
    name: 'discarded',
  });

  return (
    <>
      <Field label={t('content.fromTeam')}>
        <div className={styles.status}>
          {Object.keys(getDiscardedStatusLabels()).map((discardedStatus) => (
            <RadioButton
              key={discardedStatus}
              checked={field.value === discardedStatus}
              label={getDiscardedStatusLabels()[discardedStatus]}
              name={field.name}
              size="s"
              value={discardedStatus}
              onChange={() => {
                field.onChange(discardedStatus);
              }}
            />
          ))}
        </div>
      </Field>
      <InputDatePicker
        control={control}
        disabled={field.value === 'false'}
        fieldName="discardedAt"
        fromDate={PLAYER_FORM_FROM_DATE}
        inputClassName={cn(styles.input, styles.baseWidth)}
        label={t('content.player.quitTeam')}
        placeholder={t('content.choseDate')}
        required
        toDate={TODAY}
      />
    </>
  );
};
