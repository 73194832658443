// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsCategoriesPage-module__root--dd3Sc {\n  min-height: calc(100vh - var(--nav-header-height));\n  width: 100%;\n}\n\n.NewsCategoriesPage-module__publicationNameColumn--qdLYH {\n  line-clamp: 1;\n  overflow: hidden;\n  width: 640px;\n}\n\n.NewsCategoriesPage-module__text--_0BNB {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.NewsCategoriesPage-module__rowGridTemplate--ZeyL2 {\n  grid-template-columns: auto 36px;\n  min-height: 42px;\n  padding-left: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/settings/categories/NewsCategoriesPage/NewsCategoriesPage.module.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;EAClD,WAAW;AACb;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,gCAAgC;EAChC,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".root {\n  min-height: calc(100vh - var(--nav-header-height));\n  width: 100%;\n}\n\n.publicationNameColumn {\n  line-clamp: 1;\n  overflow: hidden;\n  width: 640px;\n}\n\n.text {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.rowGridTemplate {\n  grid-template-columns: auto 36px;\n  min-height: 42px;\n  padding-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "NewsCategoriesPage-module__root--dd3Sc",
	"publicationNameColumn": "NewsCategoriesPage-module__publicationNameColumn--qdLYH",
	"text": "NewsCategoriesPage-module__text--_0BNB",
	"rowGridTemplate": "NewsCategoriesPage-module__rowGridTemplate--ZeyL2"
};
export default ___CSS_LOADER_EXPORT___;
