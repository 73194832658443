import { getManagerSocialNetworksColumnsConfig } from 'pages/manager/social-networks/ManagerSocialNetworksPage/columnsConfig';
import { TManagerSocialNetworkItem } from 'pages/manager/social-networks/ManagerSocialNetworksPage/types';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './ManagerSocialNetworksTable.module.css';

type TProps = {
  socialLinks: TManagerSocialNetworkItem[];
};

export const ManagerSocialNetworksTable = ({ socialLinks }: TProps) => {
  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getManagerSocialNetworksColumnsConfig,
  });

  return (
    <div className={styles.root}>
      <CommonListTable
        activeItemId={activeItemId}
        columns={columnsConfig}
        data={socialLinks}
        headRowClassName={styles.headRow}
        rowClassName={styles.rowGridTemplate}
      />
    </div>
  );
};
