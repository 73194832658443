// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WorkspaceEditor-module__editor--JLPmS {\n  margin-top: 4px;\n  margin-bottom: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Workspace/components/WorkspaceEditor/WorkspaceEditor.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".editor {\n  margin-top: 4px;\n  margin-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": "WorkspaceEditor-module__editor--JLPmS"
};
export default ___CSS_LOADER_EXPORT___;
