import React, { useState } from 'react';
import { Badge } from 'shared/components/Badge/Badge';
import { TTableSubComponents, TTableWithRef } from 'shared/components/SimpleTable';
import { Text } from 'shared/components/Text/Text';
import {
  TColumnConfig,
  TDataItem,
  TFilter,
} from 'widgets/elements-list-table/types';

import styles from './CommonListTable.module.css';

type TProps<SortBy extends string, Filter extends TFilter> = {
  tableComponent: TTableWithRef & TTableSubComponents<SortBy>;
  onFilter?: (args: Filter) => void;
  filter?: Filter | null;
};

export const CommonListTableHeadCellFilterable = <
  T extends TDataItem,
  SortBy extends string,
  Filter extends TFilter,
>({
  title,
  onFilter,
  filter,
  tableComponent: Table,
  headCellProps = {},
}: TColumnConfig<T, SortBy, Filter> & TProps<SortBy, Filter>) => {
  const {
    filterComponent: Filter,
    getIsFiltered,
    filterWidth,
    filterName,
    getFilterBadge,
  } = headCellProps;
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const initialFilter = {} as Filter;

  const handleFilterSet = (args: Filter) => {
    onFilter?.(args);
    setIsFilterOpen(false);
  };

  const handleClearFilter = () => {
    if (filterName && onFilter) {
      const newFilter = { ...filter } as Filter;

      delete newFilter[filterName];

      onFilter(newFilter);
    }
  };

  const filterCount = filter && getFilterBadge ? getFilterBadge(filter) : null;

  return (
    <Table.HeadCell
      filterWidth={filterWidth}
      filterable
      isFilterOpen={isFilterOpen}
      isFiltered={getIsFiltered?.(filter || initialFilter)}
      setFilterOpen={setIsFilterOpen}
      filterComponent={
        Filter && (
          <Filter filter={filter || initialFilter} setFilter={handleFilterSet} />
        )
      }
    >
      <Text className={styles.filterTitle}>
        {title}
        {filterCount && (
          <Badge
            className={styles.filterCount}
            color="green"
            shape="pilled"
            size="s"
            text={filterCount}
            variant="solid"
            onClose={handleClearFilter}
          />
        )}
      </Text>
    </Table.HeadCell>
  );
};
