import * as Sentry from '@sentry/react';
import { AxiosError, AxiosResponse } from 'axios';
// TODO: Нарушение FSD. Нужно разобраться
import { JSONAPIDocument } from 'json-api-serializer';
import { Serializer } from 'shared/api/serializer';
import { useTokenStore } from 'shared/api/token';
import {
  registerJsonApiRelationshipTypes,
  registerJsonApiType,
} from 'shared/api/utils';

export const onResponse = (response: AxiosResponse<JSONAPIDocument>) => {
  const { data: jsonApiDocument, ...rest } = response.data;
  let type;

  if (jsonApiDocument && Array.isArray(jsonApiDocument) && jsonApiDocument.length) {
    type = jsonApiDocument[0].type;
    jsonApiDocument.forEach((doc) => {
      registerJsonApiRelationshipTypes(doc.relationships);
    });
  } else if (jsonApiDocument && !Array.isArray(jsonApiDocument)) {
    type = jsonApiDocument.type;
    registerJsonApiRelationshipTypes(jsonApiDocument.relationships);
  }
  if (type && jsonApiDocument) {
    registerJsonApiType(type);
    response.data = {
      data: Serializer.deserialize(type, response.data),
      ...rest,
    };
  } else if (Array.isArray(jsonApiDocument) && jsonApiDocument.length === 0) {
    response.data = {
      data: [],
      ...rest,
    };
  }

  return response;
};

export const onRejection = (error: AxiosError) => {
  const resetToken = useTokenStore.getState().resetToken;
  Sentry.captureException(error);
  if (error.response && error.response.status === 401) {
    resetToken();
  }

  return Promise.reject(error);
};
