import {
  LocalesInput,
  TLocalesInputProps,
} from 'feature/locales-input/LocalesInput';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

// используйте только локализованные поля, так как LocalesInput ожидает тип поля TTranslations<string>
type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
};

export const FormLocalesInput = <T extends FieldValues>({
  control,
  fieldName,
  ...localesInputProps
}: TProps<T> & Omit<TLocalesInputProps, 'onChange' | 'value'>) => {
  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => (
        <LocalesInput
          {...localesInputProps}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  );
};
