// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AchievementModal-module__section--NlnQx + .AchievementModal-module__section--NlnQx {\n    margin-top: 12px;\n  }\n\n.AchievementModal-module__sectionTitle--XdP_b {\n  margin-bottom: 8px;\n}\n\n.AchievementModal-module__inputWrapper--DHaZg {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/5-feature/achievement-modal/AchievementModal.module.css"],"names":[],"mappings":"AACE;IACE,gBAAgB;EAClB;;AAGF;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".section {\n  & + & {\n    margin-top: 12px;\n  }\n}\n\n.sectionTitle {\n  margin-bottom: 8px;\n}\n\n.inputWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "AchievementModal-module__section--NlnQx",
	"sectionTitle": "AchievementModal-module__sectionTitle--XdP_b",
	"inputWrapper": "AchievementModal-module__inputWrapper--DHaZg"
};
export default ___CSS_LOADER_EXPORT___;
