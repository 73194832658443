// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsNavigationItem-module__wrapper--ZBgCm {\n  margin-top: 16px;\n}\n\n.SettingsNavigationItem-module__link--H8aH0 {\n  margin-left: 4px;\n  color: var(--dark-blue-100);\n}\n\n.SettingsNavigationItem-module__link--H8aH0:hover {\n    color: var(--dark-blue-100);\n  }\n\n.SettingsNavigationItem-module__categoryTitle--_B7z_ {\n  margin-bottom: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/5-feature/settings-navigation/ui/SettingsNavigationItem/SettingsNavigationItem.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,2BAA2B;AAK7B;;AAHE;IACE,2BAA2B;EAC7B;;AAGF;EACE,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  margin-top: 16px;\n}\n\n.link {\n  margin-left: 4px;\n  color: var(--dark-blue-100);\n\n  &:hover {\n    color: var(--dark-blue-100);\n  }\n}\n\n.categoryTitle {\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SettingsNavigationItem-module__wrapper--ZBgCm",
	"link": "SettingsNavigationItem-module__link--H8aH0",
	"categoryTitle": "SettingsNavigationItem-module__categoryTitle--_B7z_"
};
export default ___CSS_LOADER_EXPORT___;
