import { useController, useFormContext } from 'react-hook-form';
import { toBoolean } from 'shared/lib/toBoolean';
import { TVideoPostFields } from 'widgets/video-form/types';

export const useIsEditVideo = () => {
  const { control } = useFormContext<TVideoPostFields>();

  const { field } = useController({
    control,
    name: 'id',
  });

  return toBoolean(field.value);
};
