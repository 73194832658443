import cn from 'classnames';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'shared/components/Collapse/Collapse';
import { Divider } from 'shared/components/Divider';
import { NewSelect } from 'shared/components/Form/NewSelect/NewSelect';
import { Text } from 'shared/components/Text/Text';
import { toNumber } from 'shared/lib/toNumber';

import selectStyles from '../UIKit.module.css';

const options = [
  { value: 'chocolate', label: 'Chocolate', className: 'option' },
  { value: 'strawberry', label: 'Strawberry', className: 'option' },
  { value: 'vanilla', label: 'Vanilla', className: 'option' },
];

const oneOption = [{ value: 'chocolate', label: 'Chocolate' }];

type TProps = {
  children: ReactNode;
  wrap?: boolean;
};
const Row = ({ children, wrap }: TProps) => (
  <div
    style={{ gap: 35 }}
    className={cn(selectStyles.row, {
      [selectStyles.wrap]: wrap,
    })}
  >
    {children}
  </div>
);

export const NewSelectExample = () => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const [isSearchable, setIsSearchable] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectWidth, setSelectWidth] = useState<number | undefined>();
  const styles = selectWidth
    ? {
        container: () => ({
          width: selectWidth,
        }),
      }
    : undefined;

  return (
    <Collapse
      isOpen={collapsed}
      label={
        <Text size={24} tag="h2">
          Select
        </Text>
      }
      onClick={() => setCollapsed((c) => !c)}
    >
      <div className={selectStyles.container}>
        <Text size={24} tag="h2">
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            isSearchable: {isSearchable}
            <input
              checked={isSearchable}
              type="checkbox"
              onClick={() => {
                setIsSearchable((s) => !s);
              }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            selectWidth:
            <input
              type="number"
              value={selectWidth}
              onChange={(event) => {
                setSelectWidth(toNumber(event.target.value));
              }}
            />
          </div>
        </Text>
        <Row wrap>
          <Text size={16} tag="h3">
            Types:
          </Text>
          <div>
            <Text size={12} tag="h5">
              Basic
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Underline
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Ghost
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="ghost"
            />
          </div>
        </Row>

        <Divider />

        <Row wrap>
          <Text size={16} tag="h3">
            Sizes:
          </Text>
          <div>
            <Text size={12} tag="h5">
              Large
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Medium
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="medium"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Small
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="small"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Large
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Medium
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="medium"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Small
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="small"
              styles={styles}
              type="underline"
            />
          </div>
        </Row>

        <Divider />

        <Row wrap>
          <Text size={16} tag="h3">
            Overview
          </Text>
        </Row>

        <Row wrap>
          <Text size={16} tag="h3">
            Default:
          </Text>
          <div>
            <Text size={12} tag="h5">
              State=Default, Type=Basic, Size=Large
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Medium Basic
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="medium"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Small Basic
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="small"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Large Underline
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Medium Underline
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="medium"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Small Underline
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="small"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Large Inline
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="ghost"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Medium Inline
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="medium"
              styles={styles}
              type="ghost"
            />
          </div>
          <div>
            <Text size={12} tag="h5">
              Small Inline
            </Text>
            <NewSelect
              isSearchable={isSearchable}
              options={options}
              size="small"
              styles={styles}
              type="ghost"
            />
          </div>
        </Row>

        <Row wrap>
          <Text size={16} tag="h3">
            Disabled:
          </Text>
          <div>
            <NewSelect
              isDisabled
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <NewSelect
              isDisabled
              isSearchable={isSearchable}
              options={options}
              size="medium"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <NewSelect
              isDisabled
              isSearchable={isSearchable}
              options={options}
              size="small"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <NewSelect
              isDisabled
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <NewSelect
              isDisabled
              isSearchable={isSearchable}
              options={options}
              size="medium"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <NewSelect
              isDisabled
              isSearchable={isSearchable}
              options={options}
              size="small"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <NewSelect
              isDisabled
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="ghost"
            />
          </div>
          <div>
            <NewSelect
              isDisabled
              isSearchable={isSearchable}
              options={options}
              size="medium"
              styles={styles}
              type="ghost"
            />
          </div>
          <div>
            <NewSelect
              isDisabled
              isSearchable={isSearchable}
              options={options}
              size="small"
              styles={styles}
              type="ghost"
            />
          </div>
        </Row>

        <Row wrap>
          <Text size={16} tag="h3">
            multi select:
          </Text>
          <div>
            <NewSelect
              closeMenuOnSelect={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isMulti
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="basic"
              getMultiValueDescription={(values) =>
                t('content.chooseCountElements', { length: values.length })
              }
            />
          </div>
          <div>
            <NewSelect
              closeMenuOnSelect={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isMulti
              isSearchable={isSearchable}
              options={options}
              size="medium"
              styles={styles}
              type="basic"
              getMultiValueDescription={(values) =>
                t('content.chooseCountElements', { length: values.length })
              }
            />
          </div>
          <div>
            <NewSelect
              closeMenuOnSelect={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isMulti
              isSearchable={isSearchable}
              options={options}
              size="small"
              styles={styles}
              type="basic"
              getMultiValueDescription={(values) =>
                t('content.chooseCountElements', { length: values.length })
              }
            />
          </div>
          <div>
            <NewSelect
              closeMenuOnSelect={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isMulti
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="underline"
              getMultiValueDescription={(values) =>
                t('content.chooseCountElements', { length: values.length })
              }
            />
          </div>
          <div>
            <NewSelect
              closeMenuOnSelect={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isMulti
              isSearchable={isSearchable}
              options={options}
              size="medium"
              styles={styles}
              type="underline"
              getMultiValueDescription={(values) =>
                t('content.chooseCountElements', { length: values.length })
              }
            />
          </div>
          <div>
            <NewSelect
              closeMenuOnSelect={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isMulti
              isSearchable={isSearchable}
              options={options}
              size="small"
              type="underline"
              getMultiValueDescription={(values) =>
                t('content.chooseCountElements', { length: values.length })
              }
            />
          </div>
          <div>
            <NewSelect
              closeMenuOnSelect={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isMulti
              isSearchable={isSearchable}
              options={options}
              size="large"
              styles={styles}
              type="ghost"
              getMultiValueDescription={(values) =>
                t('content.chooseCountElements', { length: values.length })
              }
            />
          </div>
          <div>
            <NewSelect
              closeMenuOnSelect={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isMulti
              isSearchable={isSearchable}
              options={options}
              size="medium"
              styles={styles}
              type="ghost"
              getMultiValueDescription={(values) =>
                t('content.chooseCountElements', { length: values.length })
              }
            />
          </div>
          <div>
            <NewSelect
              closeMenuOnSelect={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isMulti
              isSearchable={isSearchable}
              options={options}
              size="small"
              styles={styles}
              type="ghost"
              getMultiValueDescription={(values) =>
                t('content.chooseCountElements', { length: values.length })
              }
            />
          </div>
        </Row>

        <Row wrap>
          <Text size={16} tag="h3">
            Menu is open:
          </Text>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              flexBasis: '100%',
            }}
          >
            menuIsOpen: {menuIsOpen}
            <input
              checked={menuIsOpen}
              type="checkbox"
              onClick={() => {
                setMenuIsOpen((prev) => !prev);
              }}
            />
          </div>
          <div>
            <NewSelect
              isSearchable={isSearchable}
              menuIsOpen={menuIsOpen}
              options={oneOption}
              size="large"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <NewSelect
              isSearchable={isSearchable}
              menuIsOpen={menuIsOpen}
              options={oneOption}
              size="medium"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <NewSelect
              isSearchable={isSearchable}
              menuIsOpen={menuIsOpen}
              options={oneOption}
              size="small"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <NewSelect
              isSearchable={isSearchable}
              menuIsOpen={menuIsOpen}
              options={oneOption}
              size="large"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <NewSelect
              isSearchable={isSearchable}
              menuIsOpen={menuIsOpen}
              options={oneOption}
              size="medium"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <NewSelect
              isSearchable={isSearchable}
              menuIsOpen={menuIsOpen}
              options={oneOption}
              size="small"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <NewSelect
              isSearchable={isSearchable}
              menuIsOpen={menuIsOpen}
              options={oneOption}
              size="large"
              styles={styles}
              type="ghost"
            />
          </div>
          <div>
            <NewSelect
              isSearchable={isSearchable}
              menuIsOpen={menuIsOpen}
              options={oneOption}
              size="medium"
              styles={styles}
              type="ghost"
            />
          </div>
          <div>
            <NewSelect
              isSearchable={isSearchable}
              menuIsOpen={menuIsOpen}
              options={oneOption}
              size="small"
              styles={styles}
              type="ghost"
            />
          </div>
        </Row>

        <Row wrap>&nbsp;</Row>
        <Row wrap>&nbsp;</Row>

        <Row wrap>
          <Text size={16} tag="h3">
            Loading:
          </Text>
          <div>
            <NewSelect
              isLoading
              isSearchable={isSearchable}
              options={oneOption}
              size="large"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <NewSelect
              isLoading
              isSearchable={isSearchable}
              options={oneOption}
              size="medium"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <NewSelect
              isLoading
              isSearchable={isSearchable}
              options={oneOption}
              size="small"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <NewSelect
              isLoading
              isSearchable={isSearchable}
              options={oneOption}
              size="large"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <NewSelect
              isLoading
              isSearchable={isSearchable}
              options={oneOption}
              size="medium"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <NewSelect
              isLoading
              isSearchable={isSearchable}
              options={oneOption}
              size="small"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <NewSelect
              isLoading
              isSearchable={isSearchable}
              options={oneOption}
              size="large"
              styles={styles}
              type="ghost"
            />
          </div>
          <div>
            <NewSelect
              isLoading
              isSearchable={isSearchable}
              options={oneOption}
              size="medium"
              styles={styles}
              type="ghost"
            />
          </div>
          <div>
            <NewSelect
              isLoading
              isSearchable={isSearchable}
              options={oneOption}
              size="small"
              styles={styles}
              type="ghost"
            />
          </div>
        </Row>

        <Row wrap>
          <Text size={16} tag="h3">
            Error:
          </Text>
          <div>
            <NewSelect
              error="error"
              isSearchable={isSearchable}
              options={oneOption}
              size="large"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <NewSelect
              error="error"
              isSearchable={isSearchable}
              options={oneOption}
              size="medium"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <NewSelect
              error="error"
              isSearchable={isSearchable}
              options={oneOption}
              size="small"
              styles={styles}
              type="basic"
            />
          </div>
          <div>
            <NewSelect
              error="error"
              isSearchable={isSearchable}
              options={oneOption}
              size="large"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <NewSelect
              error="error"
              isSearchable={isSearchable}
              options={oneOption}
              size="medium"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <NewSelect
              error="error"
              isSearchable={isSearchable}
              options={oneOption}
              size="small"
              styles={styles}
              type="underline"
            />
          </div>
          <div>
            <NewSelect
              error="error"
              isSearchable={isSearchable}
              options={oneOption}
              size="large"
              styles={styles}
              type="ghost"
            />
          </div>
          <div>
            <NewSelect
              error="error"
              isSearchable={isSearchable}
              options={oneOption}
              size="medium"
              styles={styles}
              type="ghost"
            />
          </div>
          <div>
            <NewSelect
              error="error"
              isSearchable={isSearchable}
              options={oneOption}
              size="small"
              styles={styles}
              type="ghost"
            />
          </div>
        </Row>
      </div>
    </Collapse>
  );
};
