import { ManagerTeamAchievementsPage } from 'pages/manager/team-achievements/ManagerTeamAchievementsPage/ManagerTeamAchievementsPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const TeamAchievementsRoute: TProtectedRoute = {
  path: routes.managerTeamAchievements.url,
  element: <ManagerTeamAchievementsPage />,
  roles: DEFAULT_ROUTER_ROLES,
};
