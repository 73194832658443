import { parseISO } from 'date-fns';
import { TPlayer } from 'entities/players/model/types';
import { getFileFromUrl } from 'shared/lib/getFileFromUrl';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toString } from 'shared/lib/toString';
import { TLangType } from 'shared/types/common';
import { TPlayerFormFields } from 'widgets/player-form/types/types';

export const mapPlayerToPlayerFormFields = async (
  player: TPlayer,
  locales: TLangType[],
  defaultLocale: TLangType,
): Promise<TPlayerFormFields> => ({
  id: toString(player.id),
  photo: player.photo?.x3 ? await getFileFromUrl(player.photo.x3) : undefined,
  firstName: reduceTranslationObject(
    locales,
    (locale) => player.translations[locale].firstName,
  ),
  lastName: reduceTranslationObject(
    locales,
    (locale) => player.translations[locale].lastName,
  ),
  nickname: player?.nickname ?? undefined,
  birthDate: player.birthDate ? parseISO(player.birthDate) : null,
  biography: reduceTranslationObject(
    locales,
    (locale) => player.translations[locale].biography ?? '',
  ),
  countryId: player.country,
  weight: player.weight ? toString(player.weight) : '',
  height: player.weight ? toString(player.height) : '',
  status: player.status,
  teamId: player.team,
  playerPositionId: player.playerPosition,
  number: toString(player.number),
  pupil: reduceTranslationObject(
    locales,
    (locale) => player.translations[locale].pupil ?? '',
  ),
  debutDate: player.debutDate ? parseISO(player.debutDate) : null,
  picture: player.picture?.x3 ? await getFileFromUrl(player.picture.x3) : undefined,
  discardedAt: player.discardedAt ? parseISO(player.discardedAt) : null,
  discarded: player.discardedAt ? 'true' : 'false',
  shopLink: player.translations[defaultLocale]?.shopLink ?? undefined,
  sharingLink: player.translations[defaultLocale]?.sharingLink ?? undefined,
});
