import cn from 'classnames';
import { TStoryElementData } from 'entities/stories/types';
import { debounce } from 'lodash';
import { FORM_DEBOUNCE_DELAY } from 'pages/stories/edit/EditStory/components/forms/constants';
import { TransformTrigger } from 'pages/stories/edit/EditStory/components/TransformTrigger';
import { useStoryEditorDispatchContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorDispatchContext';
import {
  TElementProps,
  TStoryElementUpdater,
} from 'pages/stories/edit/EditStory/types';
import { renderElement } from 'pages/stories/edit/EditStory/utils';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { DraggableEventHandler } from 'react-draggable';
import { Rnd } from 'react-rnd';
import { useDebounce } from 'react-use';
import { TLangType } from 'shared/types/common';

import styles from './Box.module.css';

const RESIZE_DELAY = 200;

type TProps = {
  element: TStoryElementData;
  isSelected: boolean;
  isHighlighted: boolean;
  updater: TStoryElementUpdater;
  onClick?: (element: TStoryElementData) => void;
  selectedLocale: TLangType;
};

export const Box = memo(
  ({
    element,
    onClick,
    isSelected,
    isHighlighted,
    updater,
    selectedLocale,
  }: TProps) => {
    const dispatch = useStoryEditorDispatchContext();
    const width = useMemo(() => {
      if (element.kind === 'text') {
        return (element as TStoryElementData<'text'>).translations[selectedLocale]
          .settings.width;
      }
      return element.translations[selectedLocale].width;
    }, [element, selectedLocale]);
    const ref = useRef<Rnd | null>(null);

    useDebounce(
      () => {
        if (isSelected && ref.current) {
          const position = ref.current.getDraggablePosition();
          const resizableElement = ref.current.resizableElement.current;
          if (resizableElement) {
            updater(element, resizableElement, position);
          }
        }
      },
      FORM_DEBOUNCE_DELAY,
      [isSelected, element],
    );

    const handleDrop = useCallback<DraggableEventHandler>(
      (event, data) => {
        // https://github.com/react-grid-layout/react-draggable/issues/531
        const { x, y } = element.translations[selectedLocale].editorPosition;
        const { x: newX, y: newY, node } = data;
        const deltaX = Math.abs(newX - x);
        const deltaY = Math.abs(newY - y);
        if (deltaX < 1 && deltaY < 1 && onClick) {
          event.stopPropagation();
          // Click handlers here
          return onClick(element);
        }
        return updater(element, node, { x: newX, y: newY });
      },
      [isSelected, selectedLocale, element],
    );

    useEffect(() => {
      if (!isSelected) {
        return;
      }
      const resizeObserver = new ResizeObserver(
        debounce((entries) => {
          const element = entries[0];
          dispatch({
            type: 'updateSelectedStoryElementSettings',
            payload: { width: element.offsetWidth },
          });
        }, RESIZE_DELAY),
      );

      resizeObserver.observe(ref.current?.resizableElement.current as HTMLElement);

      return () => resizeObserver.disconnect();
    }, [isSelected]);

    useEffect(() => {
      if (ref.current) {
        const { x, y } = element.translations[selectedLocale].editorPosition;
        ref.current?.updatePosition({ x, y });
      }
    }, [selectedLocale]);

    useEffect(() => {
      if (ref.current) {
        ref.current?.updateSize({ width, height: 'auto' });
      }
    }, [selectedLocale]);

    const canResize = element.kind === 'text';
    const elementProps: TElementProps<typeof element.kind> = {
      id: element.id,
      isSelected: isSelected,
      settings: element.translations[selectedLocale].settings,
    };

    const elementComponent = renderElement(element.kind, elementProps);

    return (
      <Rnd
        bounds="parent"
        disableDragging={isSelected}
        ref={(rndElement) => (ref.current = rndElement)}
        style={{ zIndex: element.layer }}
        default={{
          ...element.translations[selectedLocale].editorPosition,
          width,
          height: 'auto',
        }}
        enableResizing={
          canResize && {
            top: false,
            topRight: false,
            right: isSelected,
            bottomRight: false,
            bottom: false,
            bottomLeft: false,
            left: isSelected,
            topLeft: false,
          }
        }
        resizeHandleComponent={{
          right: <TransformTrigger />,
          left: <TransformTrigger />,
        }}
        onDragStop={handleDrop}
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
        }}
      >
        {elementComponent}
        <div
          className={cn(styles.inner, {
            [styles.isHighlighted]: isHighlighted || isSelected,
          })}
        />
      </Rnd>
    );
  },
);
