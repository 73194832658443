import { TDefaultRightPanelProps } from 'feature/right-panel/types';
import { RightPanelCollapseWrapper } from 'feature/right-panel/ui/RightPanelCollapseWrapper/RightPanelCollapseWrapper';
import { UploadImage } from 'feature/right-panel/ui/UploadImage/UploadImage';
import { useEffect } from 'react';
import { Control, ControllerFieldState, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SwitchField } from 'shared/components/Form';
import styles from 'shared/components/Form/Field/Field.module.css';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { Tooltip } from 'shared/components/Tooltip/Tooltip';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';
import { toBoolean } from 'shared/lib/toBoolean';

import formStyles from '../RightPanel.module.css';

type TProps<T extends FieldValues> = {
  sliderImage?: File;
  onChange: (base64: string) => void;
  control: Control<T>;
  fieldState: ControllerFieldState;
  title: string;
  description: string;
  switchTip?: string;
  switchField: {
    fieldName: Path<T>;
    description: string;
  };
  cover: {
    fieldName: Path<T>;
    important: boolean;
    imageUrl: string;
    tip: string;
  };
  aspectRatio: number;
};

export const CommonImportance = <T extends FieldValues>({
  sliderImage,
  description,
  onChange,
  control,
  switchField,
  cover,
  fieldState,
  aspectRatio,
  switchTip,
  ...restProps
}: TDefaultRightPanelProps & TProps<T>) => {
  const { t } = useTranslation();

  useEffect(() => {
    getBase64FromFile(sliderImage).then((data) => {
      onChange(data);
    });
  }, [sliderImage]);

  return (
    <RightPanelCollapseWrapper
      description={description}
      isError={fieldState.invalid}
      label={t('content.displayOrder')}
      {...restProps}
    >
      <div className={formStyles.switchWrapper}>
        <SwitchField control={control} fieldName={switchField.fieldName} />
        <Text size={12}>{switchField.description}</Text>
        {toBoolean(switchTip) && (
          <Tooltip placement="top-start" tip={switchTip}>
            <Icon className={styles.tooltip} kind="info" size="xs" />
          </Tooltip>
        )}
      </div>
      <UploadImage
        aspectRatio={aspectRatio}
        control={control}
        fieldName={cover.fieldName}
        imageUrl={cover.imageUrl}
        label={t('content.sliderCover')}
        modalTitle={t('content.settingSliderCover')}
        required
        tip={cover.tip}
        wrapperClassName={!cover.important ? formStyles.sliderWrapper : ''}
      />
    </RightPanelCollapseWrapper>
  );
};
