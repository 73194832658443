import { TPlayerSocialNetwork } from 'entities/playerSocialNetworks/types';
import { TSocialNetwork } from 'entities/socialNetworks/types';
import { TPlayerSocialNetworkItem } from 'pages/player/social-networks/PlayerSocialNetworksPage/types';
import { toString } from 'shared/lib/toString';

export const combinePlayerSocialNetworks = (
  playerSocialLinks: TPlayerSocialNetwork[],
  socialNetworks: TSocialNetwork[],
): TPlayerSocialNetworkItem[] => {
  const serializedSocialNetworks = socialNetworks.reduce((acc, network) => {
    acc.set(toString(network.id), network);

    return acc;
  }, new Map<string, TSocialNetwork>());

  return playerSocialLinks.map<TPlayerSocialNetworkItem>(
    ({ socialNetworkId, ...playerSocialNetwork }) => {
      const socialNetwork = serializedSocialNetworks.get(
        toString(socialNetworkId),
      ) as TSocialNetwork;

      return {
        ...playerSocialNetwork,
        socialNetwork,
      };
    },
  );
};
