// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SocialNetworkModal-module__content--ynRNo {\n  gap: 8px;\n}\n\n.SocialNetworkModal-module__inputForm--S1x05 {\n  width: 100%;\n}\n\n.SocialNetworkModal-module__icon--cRbxv {\n  width: 24px;\n  height: 24px;\n  margin-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/5-feature/social-network-modal/ui/SocialNetworkModal/SocialNetworkModal.module.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;AACV;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".content {\n  gap: 8px;\n}\n\n.inputForm {\n  width: 100%;\n}\n\n.icon {\n  width: 24px;\n  height: 24px;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "SocialNetworkModal-module__content--ynRNo",
	"inputForm": "SocialNetworkModal-module__inputForm--S1x05",
	"icon": "SocialNetworkModal-module__icon--cRbxv"
};
export default ___CSS_LOADER_EXPORT___;
