// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SocialNetworkSelect-module__optionsContainer--ndAMf {\n  margin: 7px;\n  padding: 0 calc(8px + var(--scroll-border-width) * 2) 0 0;\n}\n", "",{"version":3,"sources":["webpack://./src/5-feature/social-network-modal/ui/SocialNetworkSelect/SocialNetworkSelect.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yDAAyD;AAC3D","sourcesContent":[".optionsContainer {\n  margin: 7px;\n  padding: 0 calc(8px + var(--scroll-border-width) * 2) 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionsContainer": "SocialNetworkSelect-module__optionsContainer--ndAMf"
};
export default ___CSS_LOADER_EXPORT___;
