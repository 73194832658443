// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommonListTable-module__tableWrapper--bGJLs {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.CommonListTable-module__filterTitle--vDxsS {\n  position: relative;\n}\n\n.CommonListTable-module__filterCount--RkfFs {\n  --offset: calc(100% + 4px);\n  position: absolute;\n  top: 0;\n  right: 0;\n  transform: translateX(var(--offset));\n}\n", "",{"version":3,"sources":["webpack://./src/4-widgets/elements-list-table/ui/CommonListTable/CommonListTable.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,oCAAoC;AACtC","sourcesContent":[".tableWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.filterTitle {\n  position: relative;\n}\n\n.filterCount {\n  --offset: calc(100% + 4px);\n  position: absolute;\n  top: 0;\n  right: 0;\n  transform: translateX(var(--offset));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrapper": "CommonListTable-module__tableWrapper--bGJLs",
	"filterTitle": "CommonListTable-module__filterTitle--vDxsS",
	"filterCount": "CommonListTable-module__filterCount--RkfFs"
};
export default ___CSS_LOADER_EXPORT___;
