// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VideoCategoriesFormModal-module__form--zNSxT {\n  margin-top: 8px;\n}\n\n.VideoCategoriesFormModal-module__horizontalLine--rDQN8 {\n  margin: 0;\n}\n\n.VideoCategoriesFormModal-module__inputForm--HicKr::-moz-placeholder {\n    color: var(--gray-500);\n  }\n\n.VideoCategoriesFormModal-module__inputForm--HicKr::placeholder {\n    color: var(--gray-500);\n  }\n\n.VideoCategoriesFormModal-module__text--PGqlt {\n  margin-top: 4px;\n}\n\n.VideoCategoriesFormModal-module__buttons--xpaw4 {\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n  padding: 16px 24px;\n  height: 68px;\n  border-top: 1px solid var(--gray-250);\n}\n\n.VideoCategoriesFormModal-module__buttons--xpaw4 > button {\n    width: auto;\n  }\n", "",{"version":3,"sources":["webpack://./src/4-widgets/videoCategoriesForm/VideoCategoriesFormModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,SAAS;AACX;;AAGE;IACE,sBAAsB;EACxB;;AAFA;IACE,sBAAsB;EACxB;;AAGF;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,QAAQ;EACR,kBAAkB;EAClB,YAAY;EACZ,qCAAqC;AAKvC;;AAHE;IACE,WAAW;EACb","sourcesContent":[".form {\n  margin-top: 8px;\n}\n\n.horizontalLine {\n  margin: 0;\n}\n\n.inputForm {\n  &::placeholder {\n    color: var(--gray-500);\n  }\n}\n\n.text {\n  margin-top: 4px;\n}\n\n.buttons {\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n  padding: 16px 24px;\n  height: 68px;\n  border-top: 1px solid var(--gray-250);\n\n  > button {\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "VideoCategoriesFormModal-module__form--zNSxT",
	"horizontalLine": "VideoCategoriesFormModal-module__horizontalLine--rDQN8",
	"inputForm": "VideoCategoriesFormModal-module__inputForm--HicKr",
	"text": "VideoCategoriesFormModal-module__text--PGqlt",
	"buttons": "VideoCategoriesFormModal-module__buttons--xpaw4"
};
export default ___CSS_LOADER_EXPORT___;
