import { SocialNetworkModal } from 'feature/social-network-modal/ui/SocialNetworkModal/SocialNetworkModal';
import { usePlayerSocialNetworksActions } from 'pages/player/social-networks/PlayerSocialNetworksPage/hooks/usePlayerSocialNetworksActions';
import { TUsePlayerSocialNetworksActions } from 'pages/player/social-networks/PlayerSocialNetworksPage/types';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';

export const PlayerSocialNetworksActions = (
  props: TUsePlayerSocialNetworksActions,
) => {
  const { actionsProps, deleteModalProps, editModalProps } =
    usePlayerSocialNetworksActions(props);

  return (
    <>
      <Actions {...actionsProps} />
      {deleteModalProps.isOpened && <ConfirmationModal {...deleteModalProps} />}
      {editModalProps.isOpened && <SocialNetworkModal {...editModalProps} />}
    </>
  );
};
