import { AchievementsRoute } from 'pages/player/achievements';
import { CareersRoute } from 'pages/player/careers';
import { CreateRoute } from 'pages/player/create';
import { EditRoute } from 'pages/player/edit';
import { SocialNetworksRoute } from 'pages/player/social-networks';
import { TeamAchievementsRoute } from 'pages/player/team-achievements';
import { TProtectedRoute } from 'shared/types/router';

export const PlayerRoutes: Record<string, TProtectedRoute> = {
  Create: CreateRoute,
  Edit: EditRoute,
  Careers: CareersRoute,
  SocialNetworks: SocialNetworksRoute,
  Achievements: AchievementsRoute,
  TeamAchievements: TeamAchievementsRoute,
};
