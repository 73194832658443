// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManagerCareersTable-module__root--tjlyQ {\n  width: 100%;\n}\n\n.ManagerCareersTable-module__rowGridTemplate--XRnb6 {\n  grid-template-columns: 22.5% 22.5% 7.61% auto 36px;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/manager/careers/ManagerCareersPage/ManagerCareersTable/ManagerCareersTable.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,kDAAkD;AACpD","sourcesContent":[".root {\n  width: 100%;\n}\n\n.rowGridTemplate {\n  grid-template-columns: 22.5% 22.5% 7.61% auto 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ManagerCareersTable-module__root--tjlyQ",
	"rowGridTemplate": "ManagerCareersTable-module__rowGridTemplate--XRnb6"
};
export default ___CSS_LOADER_EXPORT___;
