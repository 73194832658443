import { useLocales } from 'entities/config/lib/useLocales';
import { useCallback, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { deleteSelectionOperations } from 'shared/components/Editor/helpers';
import { TEditor, TEditorElement } from 'shared/components/Editor/types';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import {
  prepareEditorElements,
  prepareWorkspaceElements,
} from 'shared/components/Workspace/mapper';
import { TLangType, TWithChildren } from 'shared/types/common';
import { Descendant } from 'slate';
import { ReactEditor, Slate } from 'slate-react';

type TProps = {
  editor: TEditor;
  currentLocale: TLangType;
  control: Control<TEditNewsPostFields>;
};

export const WorkspaceSlate = ({
  currentLocale,
  control,
  children,
  editor,
}: TProps & TWithChildren) => {
  const { defaultLocale } = useLocales();

  return (
    <Controller
      key={`content.body.${currentLocale}`}
      control={control}
      name={'content.body'}
      render={({ field }) => {
        const initialValue = useMemo(() => {
          if (editor.selection) {
            ReactEditor.deselect(editor);
          }
          return prepareEditorElements(field.value, currentLocale, defaultLocale);
        }, [currentLocale]);

        const onChangeHandler = useCallback(
          (value: Descendant[]) => {
            // Данные операции необходимо удалять, так как функция onChange срабатывает при фокусе
            const filteredOperations = deleteSelectionOperations(editor.operations);

            if (filteredOperations.length > 0) {
              const workspaceElements = prepareWorkspaceElements(
                value as TEditorElement[],
                currentLocale,
              );

              field.onChange(workspaceElements);
            }
          },
          [currentLocale],
        );

        return (
          <Slate
            editor={editor}
            initialValue={initialValue}
            onChange={onChangeHandler}
          >
            {children}
          </Slate>
        );
      }}
    />
  );
};
