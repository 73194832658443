// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ColorInput-module__selectedColor--Zuid2 {\n  width: 20px;\n  height: 20px;\n  border-radius: 4px;\n  border: 1px solid var(--gray-450);\n  margin-right: 4px;\n}\n\n.ColorInput-module__openedColorPicker--wIMAb {\n  border: 1px solid var(--green-400);\n}\n\n.ColorInput-module__innerWrapper--J06D7 {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 5px;\n  border-radius: var(--border-radius-default);\n  border: 1px solid var(--gray-150);\n  font-size: 12px;\n  line-height: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Form/ColorInput/ColorInput.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iCAAiC;EACjC,iBAAiB;AACnB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,YAAY;EACZ,2CAA2C;EAC3C,iCAAiC;EACjC,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".selectedColor {\n  width: 20px;\n  height: 20px;\n  border-radius: 4px;\n  border: 1px solid var(--gray-450);\n  margin-right: 4px;\n}\n\n.openedColorPicker {\n  border: 1px solid var(--green-400);\n}\n\n.innerWrapper {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 5px;\n  border-radius: var(--border-radius-default);\n  border: 1px solid var(--gray-150);\n  font-size: 12px;\n  line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedColor": "ColorInput-module__selectedColor--Zuid2",
	"openedColorPicker": "ColorInput-module__openedColorPicker--wIMAb",
	"innerWrapper": "ColorInput-module__innerWrapper--J06D7"
};
export default ___CSS_LOADER_EXPORT___;
