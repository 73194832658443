import React, { useLayoutEffect, useState } from 'react';
import { Cropper } from 'shared/components/cropper/Cropper/Cropper';
import { TCropper } from 'shared/components/cropper/types';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';

type TProps = {
  file: File;
  initCropper: (cropper: TCropper) => void;
  aspectRatio: number;
};

export const ImageUploadModalCropper = ({
  file,
  initCropper,
  aspectRatio,
}: TProps) => {
  const [base64Url, setBase64Url] = useState('');

  useLayoutEffect(() => {
    getBase64FromFile(file).then((url) => setBase64Url(url));
  }, [file]);

  if (!base64Url) {
    return null;
  }

  return (
    <Cropper
      defaultAspectRatio={aspectRatio}
      imageBase64Url={base64Url}
      initCropper={initCropper}
    />
  );
};
