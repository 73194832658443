import { useMemo } from 'react';
import { createTable } from 'shared/components/SimpleTable';
import {
  TCommonCellProps,
  TSortableCellProps,
} from 'shared/components/SimpleTable/components/THeadCell/THeadCell';
import { TEntityId } from 'shared/types/common';
import { compareIds } from 'shared/utils/entityIds';
import {
  TColumnConfig,
  TDataItem,
  TFilter,
  TSortOptions,
} from 'widgets/elements-list-table/types';
import { CommonListTableHeadCell } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTableHeadCell';
import { CommonListTableHeadCellFilterable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTableHeadCellFilterable';
import { CommonListTableHeadCellSortable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTableHeadCellSortable';

import styles from './CommonListTable.module.css';

type TCommonListTableProps<
  T extends TDataItem,
  SortBy extends string,
  FilterType extends TFilter,
> = {
  data: T[];
  columns: TColumnConfig<T, SortBy, FilterType>[];
  activeItemId: TEntityId | null;
  rowClassName: string;
  headRowClassName?: string;
  onFilter?: (args: FilterType) => void;
  filter?: FilterType | null;
  sortOptions?: TSortOptions<SortBy>;
  onSort?: (sortOptions: TSortOptions<SortBy>) => void;
};

export const CommonListTable = <
  T extends TDataItem,
  SortBy extends string,
  FilterType extends TFilter,
>({
  data,
  columns,
  rowClassName,
  headRowClassName,
  activeItemId,
  sortOptions,
  filter,
  onSort,
  onFilter,
}: TCommonListTableProps<T, SortBy, FilterType>) => {
  const Table = useMemo(() => createTable<SortBy>(), []);

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <Table.Head>
          <Table.Row
            className={headRowClassName || rowClassName}
            hoverable={false}
            padding="s"
          >
            {columns.map((column, index) => {
              const { headCellProps, ...restProps } = column;

              if (column.headCellProps?.filterable) {
                return (
                  <CommonListTableHeadCellFilterable
                    key={index}
                    {...restProps}
                    filter={filter}
                    headCellProps={headCellProps}
                    tableComponent={Table}
                    onFilter={onFilter}
                  />
                );
              }

              if (column.headCellProps?.sortable && sortOptions && onSort) {
                return (
                  <CommonListTableHeadCellSortable
                    key={index}
                    {...restProps}
                    headCellProps={headCellProps as TSortableCellProps<SortBy>}
                    sortOptions={sortOptions}
                    tableComponent={Table}
                    onSort={onSort}
                  />
                );
              }

              return (
                <CommonListTableHeadCell
                  key={index}
                  {...column}
                  headCellProps={headCellProps as TCommonCellProps}
                  tableComponent={Table}
                />
              );
            })}
            <Table.HeadCell />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data.map((item) => (
            <Table.Row
              key={item.id}
              className={rowClassName}
              isActive={compareIds(activeItemId, item.id)}
              padding="s"
            >
              {columns.map((column, index) => (
                <Table.Cell key={index} className={column?.cellClassName}>
                  {column.render(item)}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};
