import { useLocales } from 'entities/config/lib/useLocales';
import { useCreateNewsCategoryMutation } from 'entities/newsCategories/mutations';
import { useFetchNewsCategoriesQuery } from 'entities/newsCategories/queries';
import { NewsCategoriesPageTable } from 'pages/settings/categories/NewsCategoriesPage/NewsCategoriesPageTable';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TTranslations } from 'shared/types/common';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

import styles from './NewsCategoriesPage.module.css';

export const NewsCategoriesPage = () => {
  const { t } = useTranslation();

  const { locales } = useLocales();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const createNewsCategories = useCreateNewsCategoryMutation();

  const {
    data: { data: categories = [], page = 1, total = 0 } = {},
    isLoading,
    error,
    changePage,
  } = useFetchNewsCategoriesQuery();

  const closeModal = useCallback(() => {
    setShowCreateModal(false);
  }, []);

  const openModal = useCallback(() => {
    setShowCreateModal(true);
  }, []);

  const createNewsCategory = (translations: TTranslations<string>) => {
    createNewsCategories.mutate({
      translations: reduceTranslationObject(locales, (locale) => ({
        name: translations[locale],
      })),
    });
    closeModal();
  };

  return (
    <div className={styles.root}>
      <CommonListWrapper
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.settings.categoryNews')}
        createButtonProps={{
          content: t('content.settings.createCategory'),
          onClick: openModal,
        }}
        paginationProps={{
          selectedPage: page,
          totalItems: total,
          onPageChange: changePage,
        }}
      >
        <NewsCategoriesPageTable categories={categories} />
      </CommonListWrapper>
      <LocalizationModal
        actionSubmit={createNewsCategory}
        closeModal={closeModal}
        isOpened={showCreateModal}
        subtitle={t('content.settings.nameCategory')}
        title={t('content.settings.creatingCategory')}
        inputProps={{
          maxLength: 255,
          placeholder: t('content.inputHeading'),
          required: true,
          size: 's',
        }}
      />
    </div>
  );
};
