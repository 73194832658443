import { useFetchTeamsQuery } from 'entities/teams/queries';
import { TeamCard } from 'entities/teams/ui/TeamCard/TeamCard';
import { TeamsPageCreateButton } from 'pages/team/TeamsPage/TeamsPageCreateButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { Text } from 'shared/components/Text/Text';
import { routes } from 'shared/routes';

import styles from './TeamsPage.module.css';

const DEFAULT_PAGINATION_PAGE_SIZE = 7;

export const TeamsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: { data: teams = [], page = 1, total = 0 } = {},
    error,
    isLoading,
    changePage,
  } = useFetchTeamsQuery(
    {
      pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
    },
    { cacheTime: 0 },
  );

  if (isLoading) {
    return (
      <div className={styles.root}>
        <Centered>
          <Loader showLabel />
        </Centered>
      </div>
    );
  }

  if (error) {
    throw error;
  }

  const isShowPagination =
    total > 0 && total > DEFAULT_PAGINATION_PAGE_SIZE && !isLoading;

  return (
    <div className={styles.root}>
      <Text size={24} tag="h1" weight="Bold">
        {t('content.team')}
      </Text>
      <div className={styles.listWrapper}>
        <TeamsPageCreateButton />
        {teams.map((item) => {
          return (
            <TeamCard
              key={item.id}
              team={item}
              onClick={() => navigate(routes.editTeam.as(item.id))}
            />
          );
        })}
      </div>
      {isShowPagination && (
        <Pagination
          className={styles.pagination}
          pageSize={DEFAULT_PAGINATION_PAGE_SIZE}
          selectedPage={page}
          totalItems={total}
          onPageChange={changePage}
        />
      )}
    </div>
  );
};
