// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OpponnentPlayerModal-module__root--G8kAj {\n  gap: 8px;\n}\n\n.OpponnentPlayerModal-module__field--W1MoR {\n  gap: 8px;\n}\n\n.OpponnentPlayerModal-module__localesInput--k7Smv {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.OpponnentPlayerModal-module__label--UyOcg {\n  font-size: 14px;\n  line-height: 20px;\n  font-weight: 600;\n  color: var(--gray-500);\n}\n\n.OpponnentPlayerModal-module__status--Ex_TR {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  padding: 8px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/5-feature/opponent-player-modal/OpponnentPlayerModal.module.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;AACV;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,cAAc;AAChB","sourcesContent":[".root {\n  gap: 8px;\n}\n\n.field {\n  gap: 8px;\n}\n\n.localesInput {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.label {\n  font-size: 14px;\n  line-height: 20px;\n  font-weight: 600;\n  color: var(--gray-500);\n}\n\n.status {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  padding: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OpponnentPlayerModal-module__root--G8kAj",
	"field": "OpponnentPlayerModal-module__field--W1MoR",
	"localesInput": "OpponnentPlayerModal-module__localesInput--k7Smv",
	"label": "OpponnentPlayerModal-module__label--UyOcg",
	"status": "OpponnentPlayerModal-module__status--Ex_TR"
};
export default ___CSS_LOADER_EXPORT___;
