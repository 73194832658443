// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddNewsPostDraftButton-module__draftButton--vL4xK {\n  width: auto;\n}\n\n.AddNewsPostDraftButton-module__disabled--Rdbx4 {\n  opacity: var(--disabled-opacity);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/NewsPostForm/components/AddNewsPostDraftButton/AddNewsPostDraftButton.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".draftButton {\n  width: auto;\n}\n\n.disabled {\n  opacity: var(--disabled-opacity);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draftButton": "AddNewsPostDraftButton-module__draftButton--vL4xK",
	"disabled": "AddNewsPostDraftButton-module__disabled--Rdbx4"
};
export default ___CSS_LOADER_EXPORT___;
