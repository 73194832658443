import React from 'react';
import {
  ActionItemsWrapper,
  TActionItemsWrapperProps,
} from 'shared/components/Actions/ActionItemsWrapper';
import {
  ActionsWrapper,
  TActionsWrapperProps,
} from 'shared/components/Actions/ActionsWrapper';

type TProps = Omit<TActionsWrapperProps, 'children'> & TActionItemsWrapperProps;

export const Actions = ({
  isOpen,
  isLoading,
  onIconClick,
  actions,
  activeItemId,
  navigate,
}: TProps) => {
  return (
    <ActionsWrapper isLoading={isLoading} isOpen={isOpen} onIconClick={onIconClick}>
      <ActionItemsWrapper
        actions={actions}
        activeItemId={activeItemId}
        navigate={navigate}
      />
    </ActionsWrapper>
  );
};
