import { TPlayerAchievement } from 'entities/playerAchievements/types';
import { PlayerAchievementsActions } from 'pages/player/achievements/PlayerAchievementsPage/PlayerAchievementsActions/PlayerAchievementsActions';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from './PlayerAchievementsPage.module.css';

export const getPlayerAchievementsColumnsConfig: TConfigCreator<
  TPlayerAchievement
> = (defaultLocale, activeSocialLinkId, setActiveSocialLinkId) => {
  const { t } = useTranslation();

  return [
    {
      title: t('content.title'),
      cellClassName: styles.nameColumn,
      render: (item) => (
        <Text className={styles.text} color="var(--gray-500)">
          {item.translations?.[defaultLocale]?.title}
        </Text>
      ),
    },
    {
      title: t('content.year'),
      render: (item) => <Text color="var(--gray-500)">{item.year}</Text>,
    },
    {
      title: 'ID',
      render: (item) => <Text color="var(--gray-500)">{item.id}</Text>,
    },
    {
      title: '',
      render: (playerAchievement) => (
        <PlayerAchievementsActions
          activeAchievementId={activeSocialLinkId}
          playerAchievement={playerAchievement}
          setActiveAchievementId={setActiveSocialLinkId}
        />
      ),
    },
  ];
};
