import { TAPIName } from 'shared/api/types';
import { TUserRoles } from 'shared/types/user';

export const APIS = {
  sandbox1: 'https://sandbox.matchplatform.org/manager_api/v1/',
  sandbox2: 'https://sandbox2.matchplatform.org/manager_api/v1/',
  sandbox3: 'https://staging.matchplatform.org/manager_api/v1/',
  sandbox4: 'https://sandbox4.matchplatform.org/manager_api/v1/',
  sandbox5: 'https://sandbox5.matchplatform.org/manager_api/v1/',
};

export const API_LIST: {
  value: TAPIName;
  label: string;
}[] = [
  { value: 'sandbox1', label: 'Sandbox 1' },
  {
    value: 'sandbox2',
    label: 'Sandbox 2',
  },
  {
    value: 'sandbox4',
    label: 'Sandbox 4 (mobile apps)',
  },
  {
    value: 'sandbox5',
    label: 'Sandbox 5 (pre-release)',
  },
];

export const API_VERSION = '1.14';

export const DEFAULT_NO_PAGINATION_PAGE_SIZE = 100;

export const DEFAULT_ROUTER_ROLES: TUserRoles = ['admin', 'core'];
