import { parseISO } from 'date-fns';
import { ETableElementStatus } from 'entities/common/types';
import { TStoryGroupData } from 'entities/stories/types';
import { getStatusByPublicationDate } from 'feature/story/getStatusByPublicationDate';
import { useStoryGroupActions } from 'pages/stories/StoriesPage/hooks/useStoryGroupActions';
import { TStoriesPageModal } from 'pages/stories/StoriesPage/types';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { TDefaultActions, TEntityId, TGroupAction } from 'shared/types/common';

type TProps = {
  activeItemId: TEntityId | null;
  setActiveItemId: Dispatch<SetStateAction<TEntityId | null>>;
  storyGroup: TStoryGroupData;
};

export const StoriesActions = ({
  activeItemId,
  setActiveItemId,
  storyGroup,
}: TProps) => {
  const { t } = useTranslation();
  const { id, published } = storyGroup;
  const isActive = id === activeItemId;
  const status = getStatusByPublicationDate(published);

  const {
    editStoryGroup,
    changePublicationDate,
    publishNow,
    unpublish,
    deleteStoryGroup,
  } = useStoryGroupActions();
  const [activeModal, setActiveModal] = useState<TStoriesPageModal | null>(null);

  const defaultStoryGroupActions: Record<TDefaultActions, TGroupAction> = {
    edit: {
      title: t('content.edit'),
      icon: 'edit-02',
      action: editStoryGroup,
    },
    delete: {
      title: t('content.delete'),
      icon: 'trash',
      action: () => setActiveModal('confirmDelete'),
    },
  };

  const actions = useMemo<TGroupAction[]>(() => {
    if (status === ETableElementStatus.Draft) {
      return [defaultStoryGroupActions.edit, defaultStoryGroupActions.delete];
    }

    if (status === ETableElementStatus.Published) {
      return [
        defaultStoryGroupActions.edit,
        {
          title: t('content.deleteFromPublication'),
          icon: 'no-eyes',
          action: () => handleUnpublishStory(),
        },
        defaultStoryGroupActions.delete,
      ];
    }

    if (status === ETableElementStatus.Postponed) {
      return [
        {
          title: t('content.publicNow'),
          icon: 'plus',
          action: () => setActiveModal('confirmPublishNow'),
        },
        defaultStoryGroupActions.edit,
        {
          title: t('content.changeTimePublic'),
          icon: 'clock',
          action: () => setActiveModal('changePublishDate'),
        },
        defaultStoryGroupActions.delete,
      ];
    }

    return [];
  }, [status]);

  const closeModal = () => {
    setActiveModal(null);
  };

  const handleDateChange = (date: Date | undefined) => {
    changePublicationDate(storyGroup, date);
    closeModal();
  };

  const handleConfirm = () => {
    if (activeModal === 'confirmPublishNow') {
      publishNow(storyGroup);
    }
    if (activeModal === 'confirmDelete') {
      deleteStoryGroup(storyGroup);
    }
    closeModal();
  };

  const handleUnpublishStory = () => {
    unpublish(storyGroup);
  };

  const onClick = useCallback(() => {
    if (activeItemId === id) {
      return setActiveItemId(null);
    }

    setActiveItemId(id);
  }, [id, activeItemId]);

  return (
    <>
      <Actions
        actions={actions}
        activeItemId={activeItemId}
        isOpen={isActive}
        onIconClick={onClick}
      />
      <DatePicker
        isOpened={activeModal === 'changePublishDate'}
        date={
          (isActive && storyGroup.published && parseISO(storyGroup.published)) ||
          undefined
        }
        onClose={closeModal}
        onDateChange={handleDateChange}
      />
      <ConfirmationModal
        description={t('content.story.publicStories', { mark: '?' })}
        isOpened={activeModal === 'confirmPublishNow'}
        title={t('content.confirm')}
        onClose={closeModal}
        onConfirm={handleConfirm}
      />
      <ConfirmationModal
        description={t('content.story.warningDelete')}
        isOpened={activeModal === 'confirmDelete'}
        title={t('content.story.deletePublic')}
        onClose={closeModal}
        onConfirm={handleConfirm}
      />
    </>
  );
};
