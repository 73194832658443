import { findLastIndex } from 'lodash';
import { NavigateFunction } from 'react-router-dom';
import { ActionItem } from 'shared/components/Actions/ActionItem';
import { TActionByStatus, TEntityId } from 'shared/types/common';

export type TActionItemsWrapperProps = {
  actions: TActionByStatus[];
  activeItemId: TEntityId | null;
  navigate?: NavigateFunction;
};

export const ActionItemsWrapper = ({
  actions,
  activeItemId,
  navigate,
}: TActionItemsWrapperProps) => {
  return (
    <>
      {actions.map(({ title, icon, action }, index) => (
        <ActionItem
          key={title}
          isLast={index === findLastIndex(actions)}
          title={title}
          buttonProps={{
            icon,
            onClick: () => activeItemId && action(activeItemId, navigate),
          }}
        />
      ))}
    </>
  );
};
