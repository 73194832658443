// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Notification-module__toastContainer--nJ4N_ {\n  padding: 0;\n}\n\n.Notification-module__toast--ragUO {\n  padding: 0;\n  min-height: unset;\n  border-radius: var(--alert-border-radius);\n}\n\n.Notification-module__toastBody--t5k9k {\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Notification/Notification.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,yCAAyC;AAC3C;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".toastContainer {\n  padding: 0;\n}\n\n.toast {\n  padding: 0;\n  min-height: unset;\n  border-radius: var(--alert-border-radius);\n}\n\n.toastBody {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastContainer": "Notification-module__toastContainer--nJ4N_",
	"toast": "Notification-module__toast--ragUO",
	"toastBody": "Notification-module__toastBody--t5k9k"
};
export default ___CSS_LOADER_EXPORT___;
