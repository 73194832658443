// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MatchDatePickerCaption-module__header--NOa7l {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 16px 11px;\n  border-bottom: 1px solid var(--gray-250);\n}\n\n.MatchDatePickerCaption-module__selectControl--RplFX {\n  font-size: 14px;\n  line-height: 20px;\n  padding: 7px 12px;\n  gap: 10px;\n}\n\n.MatchDatePickerCaption-module__monthChange--m5U8s {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 16px 24px 24px;\n}\n\n.MatchDatePickerCaption-module__hideArrow--YSUsI {\n  visibility: hidden;\n  pointer-events: none;\n}\n\n.MatchDatePickerCaption-module__month--iAkH8 {\n  text-transform: capitalize;\n}\n", "",{"version":3,"sources":["webpack://./src/5-feature/right-panel/ui/MatchDatePicker/MatchDatePickerCaption/MatchDatePickerCaption.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,uBAAuB;EACvB,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 16px 11px;\n  border-bottom: 1px solid var(--gray-250);\n}\n\n.selectControl {\n  font-size: 14px;\n  line-height: 20px;\n  padding: 7px 12px;\n  gap: 10px;\n}\n\n.monthChange {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 16px 24px 24px;\n}\n\n.hideArrow {\n  visibility: hidden;\n  pointer-events: none;\n}\n\n.month {\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "MatchDatePickerCaption-module__header--NOa7l",
	"selectControl": "MatchDatePickerCaption-module__selectControl--RplFX",
	"monthChange": "MatchDatePickerCaption-module__monthChange--m5U8s",
	"hideArrow": "MatchDatePickerCaption-module__hideArrow--YSUsI",
	"month": "MatchDatePickerCaption-module__month--iAkH8"
};
export default ___CSS_LOADER_EXPORT___;
