import { countriesApiKeys } from 'entities/countries/consts';
import { TCountry } from 'entities/countries/types';
import { get } from 'shared/api/axios';
import { mapPaginatedResponse } from 'shared/api/pagination';
import { TFetchParams } from 'shared/api/types';

export const fetchCountries = ({ params }: TFetchParams) =>
  get<TCountry[], true>(countriesApiKeys.getCountries, {
    params,
  }).then((response) => mapPaginatedResponse(response.data));
