import { useLocales } from 'entities/config/lib/useLocales';
import { useCreateLabelsMutation } from 'entities/labels/mutations';
import { useFetchLabelsQuery } from 'entities/labels/queries';
import { LabelsPageTable } from 'pages/settings/labels/LabelsPage/LabelsPageTable';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TTranslations } from 'shared/types/common';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

import styles from './LabelsPage.module.css';

export const LabelsPage = () => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const {
    data: { data: labels = [], page = 1, total = 0 } = {},
    isLoading,
    error,
    changePage,
  } = useFetchLabelsQuery();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const createLabels = useCreateLabelsMutation();

  const openModal = useCallback(() => {
    setShowCreateModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowCreateModal(false);
  }, []);

  const createLabel = (data: TTranslations<string>) => {
    createLabels.mutate({
      translations: reduceTranslationObject(locales, (locale) => ({
        name: data[locale],
      })),
    });
    closeModal();
  };

  return (
    <div className={styles.root}>
      <CommonListWrapper
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.settings.labelList')}
        createButtonProps={{
          content: t('content.settings.createLabel'),
          onClick: openModal,
        }}
        paginationProps={{
          selectedPage: page,
          totalItems: total,
          onPageChange: changePage,
        }}
      >
        <LabelsPageTable data={labels} />
        <LocalizationModal
          actionSubmit={createLabel}
          closeModal={closeModal}
          isOpened={showCreateModal}
          subtitle={t('content.settings.labelName')}
          title={t('content.settings.creatingLabel')}
          inputProps={{
            maxLength: 255,
            placeholder: t('content.inputHeading'),
            required: true,
            size: 's',
          }}
        />
      </CommonListWrapper>
    </div>
  );
};
