// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AttachVideoLinkModal-module__preview--yrqye {\n  padding-bottom: 8px;\n}\n\n.AttachVideoLinkModal-module__videoWrapper--wSj0z {\n  padding-top: 12px;\n  aspect-ratio: 18 / 10;\n}\n\n.AttachVideoLinkModal-module__hintText--LRcGH {\n  color: var(--dark-blue-250);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/AttachVideoLinkModal/AttachVideoLinkModal.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".preview {\n  padding-bottom: 8px;\n}\n\n.videoWrapper {\n  padding-top: 12px;\n  aspect-ratio: 18 / 10;\n}\n\n.hintText {\n  color: var(--dark-blue-250);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview": "AttachVideoLinkModal-module__preview--yrqye",
	"videoWrapper": "AttachVideoLinkModal-module__videoWrapper--wSj0z",
	"hintText": "AttachVideoLinkModal-module__hintText--LRcGH"
};
export default ___CSS_LOADER_EXPORT___;
