import {
  ParagraphTypeEnum,
  TCreateImageParagraphParams,
  TCreateMainImageParagraphParams,
  TCreateNewsPostParagraphDTO,
  TCreateSliderParagraphParams,
  TCreateTextParagraphParams,
  TCreateTitleParagraphParams,
} from 'entities/news/types';
import i18n from 'i18next';
import {
  EditorElementTypeEnum,
  TEditorImage,
  TEditorVideo,
} from 'shared/components/Editor/types';
import { MAX_PARAGRAPH_LENGTH } from 'shared/components/Editor/utils/paragraph/consts';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { notify } from 'shared/components/Notification';
import { clearMetaDataFromBase64Url } from 'shared/lib/clearMetaDataFromBase64Url';
import { getFileType } from 'shared/lib/getFileType';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TLangType } from 'shared/types/common';

import { TCreateVideoParagraphParams } from '../types';

const FIRST_PARAGRAPH_POSITION = 1;
const SECOND_PARAGRAPH_POSITION = 2;
const THIRD_PARAGRAPH_POSITION = 3;

export const prepareCreateNewsPostParagraphsDTO = (
  data: TEditNewsPostFields,
  defaultLocale: TLangType,
): TCreateNewsPostParagraphDTO[] => {
  const title = data.content.title;
  const mainImage = data.content.preview;
  const locales = data.locale.created;

  return [
    {
      id: title.id,
      type: ParagraphTypeEnum.Title,
      position: FIRST_PARAGRAPH_POSITION,
      translations: reduceTranslationObject(locales, (locale) => ({
        title: title.translations[locale].title,
      })),
    } satisfies TCreateTitleParagraphParams,
    {
      id: mainImage.id,
      type: ParagraphTypeEnum.MainImage,
      position: SECOND_PARAGRAPH_POSITION,
      translations: reduceTranslationObject(locales, (locale) => ({
        file: {
          filename:
            `main_image_${locale}.` +
            getFileType(mainImage.translations[locale].file!.name),
          data: clearMetaDataFromBase64Url(mainImage.translations?.[locale]?.data),
        },
      })),
    } satisfies TCreateMainImageParagraphParams,
    ...data.content.body.map((paragraph, index) => {
      const id = paragraph.id;
      const position = index + THIRD_PARAGRAPH_POSITION;

      if (paragraph.type === EditorElementTypeEnum.Image) {
        return {
          id,
          type: ParagraphTypeEnum.Image,
          position,
          translations: reduceTranslationObject(locales, (locale) => {
            const translation =
              paragraph.translations[locale] ??
              (paragraph.translations[defaultLocale] as TEditorImage);

            return {
              file: {
                filename:
                  `image_${locale}_${index}.` + getFileType(translation.filename),
                data: clearMetaDataFromBase64Url(translation.url),
              },
            };
          }),
        } satisfies TCreateImageParagraphParams;
      }

      if (paragraph.type === EditorElementTypeEnum.Slider) {
        return {
          id,
          type: ParagraphTypeEnum.Slider,
          position,
          translations: reduceTranslationObject(locales, (locale) => {
            const translation =
              paragraph.translations[locale] ??
              (paragraph.translations[defaultLocale] as TEditorImage[]);

            return {
              files: translation.map((file, fileIndex) => ({
                filename:
                  `slider_image_${locale}_${fileIndex}.` +
                  getFileType(file.filename),
                data: clearMetaDataFromBase64Url(file.url),
              })),
            };
          }),
        } satisfies TCreateSliderParagraphParams;
      }

      if (paragraph.type === EditorElementTypeEnum.Video) {
        return {
          id,
          type: ParagraphTypeEnum.Video,
          position,
          translations: reduceTranslationObject(locales, (locale) => {
            const translation =
              paragraph.translations[locale] ??
              (paragraph.translations[defaultLocale] as TEditorVideo);

            return {
              videoLink: translation.src,
            };
          }),
        } satisfies TCreateVideoParagraphParams;
      }

      return {
        id,
        type: ParagraphTypeEnum.Text,
        position,
        translations: reduceTranslationObject(locales, (locale) => {
          const content =
            paragraph.translations[locale]?.text ||
            (paragraph.translations[defaultLocale]?.text ?? '');

          if (content.length > MAX_PARAGRAPH_LENGTH) {
            notify(i18n.t('content.validation.notifyParagraph', { mark: '.' }), {
              type: 'error',
            });
            throw new Error(
              `Paragraph length is ${content.length} characters. Maximum length is ${MAX_PARAGRAPH_LENGTH} characters.`,
            );
          }

          return {
            content,
          };
        }),
      } satisfies TCreateTextParagraphParams;
    }),
  ];
};
