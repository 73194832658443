import { TMetaType } from 'shared/types/common';

export type TPaginatedResponse<T> = {
  total: number;
  page: number;
  pageSize: number;
  data: T[];
};

export const mapPaginatedResponse = <T>({
  data,
  meta,
}: {
  data: T[];
  meta?: TMetaType<true>;
}): TPaginatedResponse<T> => {
  const page = meta!.page;

  return {
    total: page.total_entries,
    pageSize: page.size,
    page: page.number,
    data,
  };
};
