// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BaseButton-module__container--Ukzx8 {\n  display: inline-block;\n  font-weight: 400;\n  padding: 0;\n  text-align: center;\n  border: none;\n  border-radius: var(--border-radius-m);\n  background-clip: border-box;\n  background-origin: border-box;\n  cursor: pointer;\n  width: 100%;\n  outline: none;\n  transition: all var(--duration-interaction) ease;\n}\n\n  .BaseButton-module__container--Ukzx8.BaseButton-module__singleLineTitle--EBk41 {\n    white-space: nowrap;\n  }\n\n  .BaseButton-module__container--Ukzx8[disabled] {\n    opacity: var(--disabled-opacity);\n    cursor: not-allowed;\n  }\n\n  .BaseButton-module__container--Ukzx8.BaseButton-module__isLoading--rudLw {\n    cursor: progress;\n    display: flex;\n    justify-content: center;\n    gap: 4px;\n  }\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/BaseButton/BaseButton.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,qCAAqC;EACrC,2BAA2B;EAC3B,6BAA6B;EAC7B,eAAe;EACf,WAAW;EACX,aAAa;EACb,gDAAgD;AAiBlD;;EAfE;IACE,mBAAmB;EACrB;;EAEA;IACE,gCAAgC;IAChC,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,QAAQ;EACV","sourcesContent":[".container {\n  display: inline-block;\n  font-weight: 400;\n  padding: 0;\n  text-align: center;\n  border: none;\n  border-radius: var(--border-radius-m);\n  background-clip: border-box;\n  background-origin: border-box;\n  cursor: pointer;\n  width: 100%;\n  outline: none;\n  transition: all var(--duration-interaction) ease;\n\n  &.singleLineTitle {\n    white-space: nowrap;\n  }\n\n  &[disabled] {\n    opacity: var(--disabled-opacity);\n    cursor: not-allowed;\n  }\n\n  &.isLoading {\n    cursor: progress;\n    display: flex;\n    justify-content: center;\n    gap: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BaseButton-module__container--Ukzx8",
	"singleLineTitle": "BaseButton-module__singleLineTitle--EBk41",
	"isLoading": "BaseButton-module__isLoading--rudLw"
};
export default ___CSS_LOADER_EXPORT___;
