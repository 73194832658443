import ImageCropper from 'cropperjs';
import { RefObject, useLayoutEffect, useState } from 'react';
import { DEFAULT_CROPPER_ASPECT_RATIO } from 'shared/components/cropper/consts';
import { TCropper } from 'shared/components/cropper/types';

const MAX_ZOOM_IN_RATIO = 2;
const VIEW_MODE = 1;

export type TInitCropperProps = {
  initCropper: (cropper: TCropper) => void;
};

type TProps = {
  aspectRatio?: number;
  imgRef: RefObject<HTMLImageElement>;
} & TInitCropperProps;

export const useCropper = ({ imgRef, aspectRatio, initCropper }: TProps) => {
  const [cropper, setCropper] = useState<TCropper | null>(null);

  useLayoutEffect(() => {
    const newCropper = new ImageCropper(imgRef.current as HTMLImageElement, {
      viewMode: VIEW_MODE,
      dragMode: 'move',
      guides: false,
      toggleDragModeOnDblclick: false,
      center: false,
      zoom(event) {
        if (event.detail.ratio >= MAX_ZOOM_IN_RATIO) {
          event.preventDefault();
        }
      },
      aspectRatio: aspectRatio ?? DEFAULT_CROPPER_ASPECT_RATIO,
    });

    setCropper(newCropper);
    initCropper(newCropper);
  }, []);

  return {
    cropper,
  };
};
