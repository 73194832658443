import { endSession } from 'entities/session/model/api';
import { useTokenStore } from 'shared/api/token';

export const useLogout = () => {
  const resetToken = useTokenStore((state) => state.resetToken);

  const logout = async () => {
    await endSession();
    resetToken();
  };
  return logout;
};
